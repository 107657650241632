// LIBS
import immutable from 'immutable'

// REDUX
import initialState from '../../initial-states/jobs'
import {
  GET_JOBS_AJAX_SUCCESS, GET_JOBS_FROM_COURSES_BY__ID_AJAX_SUCCESS
} from 'redux/action-types/ajax'
import { CLEAR_LIST, SET_ASCENDANT, SET_PAGE, SET_SORT_BY } from 'redux/action-types'
import { HASH_ROUTING_SWITCH_ROUTE } from 'react-redux-hash-router'

export default (state = initialState, action) => {
  let newAscendant

  switch (action.type) {
    case HASH_ROUTING_SWITCH_ROUTE:
    case CLEAR_LIST:
      state = state.set('page', 0)
      state = state.set('list', immutable.fromJS([]))
      return state
    case GET_JOBS_FROM_COURSES_BY__ID_AJAX_SUCCESS:
    case GET_JOBS_AJAX_SUCCESS:
      state = state.update('list', list => list.concat(immutable.fromJS(action.data.jobs).filter(item => list.indexOf(item) < 0)))
      if (action.requestQueries.sort){
        state = state.set('list', state.get('list').sort((i1, i2) => action.requestQueries.asc ?
          i1.get(action.requestQueries.sort) < i2.get(action.requestQueries.sort)
          : i1.get(action.requestQueries.sort) > i2.get(action.requestQueries.sort)))
      }
      state = state.set('count', action.data.count)
      return state
    case SET_ASCENDANT:
      /*
      newAscendant = state.get('ascendant').filter((value, key) => {
        if (key === action.field && (action.ascendant === null || value === null)) {
          return false
        } else {
          return true
        }
      })
      state = state.set('ascendant', newAscendant)
      state = state.setIn(['ascendant', action.field], action.ascendant)
       */
      state = state.set('ascendant', state.get('ascendant').map(() => null))
      state = state.setIn(['ascendant', action.field], action.ascendant)
      return state
    case SET_PAGE:
      state = state.set('page', action.page)
      return state
    case SET_SORT_BY:
      state = state.set('sortBy', action.sortBy)
      return state
    default:
      return state
  }
}
