// REDUX
import {
  CLEAR_LIST,
  UPDATE_AJAX_LOADING,
  SET_PAGE,
  SET_SORT_BY, SET_ASCENDANT
} from '../../action-types'

export const updateAjaxLoading = (api, isLoading, uid = 1) => ({
  type: UPDATE_AJAX_LOADING,
  api,
  isLoading,
  uid
})

export const clearList = () => ({
  type: CLEAR_LIST
})

export const setAscendant = (field, ascendant) => ({
  type: SET_ASCENDANT,
  field,
  ascendant
})

export const setPage = (page) => ({
  type: SET_PAGE,
  page
})

export const setSortBy = (sortBy) => ({
  type: SET_SORT_BY,
  sortBy
})
