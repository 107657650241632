// REACT
import React, { useEffect, useState } from "react";
import { array, bool, func, number, string, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Chip,
  MenuItem,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import Downshift from "downshift";
// LIBS
import deburr from "lodash/deburr";
import cn from "classnames";
// STYLE
import s from "./style.module.scss";

function Autocomplete(props) {
  const [selectedItem, setSelectedItem] = useState(
    props.initialValue ? [props.initialValue] : [],
  );
  const [inputValue, setInputValue] = useState(null);
  useEffect(() => {
    const { onChange, suggestions } = props;
    let selected = multiple ? [] : null;
    if (multiple) {
      selectedItem.map((i) => {
        selected.push(suggestions.find((el) => el.label === i).value);
      });
    } else {
      selected = suggestions.find((el) => el.label === selectedItem[0]);
    }
    onChange &&
      onChange(multiple ? selected : selected ? selected.value : null);
  }, [selectedItem]);

  function getSuggestions(value) {
    const { suggestions } = props;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue ? inputValue.length : 0;
    let count = 0;

    if (inputLength === 0) {
      return [];
    }

    let arr = suggestions.filter((suggestion) => {
      const keep =
        count < 10 &&
        suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });

    arr = arr.concat(
      suggestions.filter((suggestion) => {
        const keep =
          count < 10 &&
          arr.findIndex((a) => a.value === suggestion.value) === -1 &&
          suggestion.label.indexOf(inputValue) !== -1;

        if (keep) {
          count += 1;
        }

        return keep;
      }),
    );

    return arr;
  }

  function handleChange(item) {
    if (selectedItem.indexOf(item) === -1) {
      setSelectedItem([...selectedItem, item]);
    }
    setInputValue("");
  }

  function handleDelete(currentItem) {
    setSelectedItem(() => {
      const item = [...selectedItem];
      item.splice(item.indexOf(currentItem), 1);
      return item;
    });
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleKeyDown(event) {
    if (selectedItem.length && !inputValue && event.key === "Backspace") {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
    const { limit } = props;

    return (
      <TextField
        disabled={limit && selectedItem.length >= limit}
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        variant="outlined"
        className={s.TextField}
        {...other}
      />
    );
  }

  function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.value}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  }

  const {
    className,
    t,
    placeholder,
    multiple,
    label,
    classes,
    onKeyPress,
  } = props;
  return (
    <div className={cn(s.Container, className)}>
      <Downshift
        inputValue={multiple && inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
        className={s.Downshift}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue: inputValue2,
          isOpen,
          selectedItem: selectedItem2,
        }) => (
          <div>
            {renderInput({
              fullWidth: true,
              s,
              InputProps: getInputProps({
                placeholder: placeholder,
                onChange: multiple && handleInputChange,
                onKeyDown: handleKeyDown,
                onKeyPress: onKeyPress,
              }),
              label: label,
              classes,
            })}
            <div {...getMenuProps}>
              {isOpen && (multiple || selectedItem.length === 0) ? (
                <Paper className={s.Suggestions} square>
                  {getSuggestions(inputValue2).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
            {multiple && selectedItem.length !== 0 && (
              <div className={s.ChipsContainer}>
                {selectedItem.map((item) => (
                  <Chip
                    className={s.Chip}
                    color="primary"
                    key={item}
                    tabIndex={-1}
                    label={item}
                    onDelete={() => handleDelete(item)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </Downshift>
    </div>
  );
}

Autocomplete.propTypes = {
  className: string,
  classes: shape({}),
  initialValue: string,
  inputValue: string,
  label: string,
  limit: number,
  multiple: bool,
  onChange: func,
  onKeyPress: func,
  placeholder: string,
  suggestions: array,
  t: func,
};

export default withStyles()(translate()(Autocomplete));
