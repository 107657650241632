// REACT
import React, { Fragment } from 'react'
import { func } from 'prop-types'
import { translate } from 'react-i18next'
import HashRouter, { Route } from 'react-redux-hash-router'
import SkillsSearch from './scenes/skills-search'
import SkillsJobGroups from './scenes/skills-job-groups'
import Header from 'components/header'
import { MuiThemeProvider } from '@material-ui/core'

// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// STYLE
import sg from 'css/style.module.scss'
import { second } from 'styles/mui-themes'

function Skills(props) {
  const {
    t,
  } = props

  return (
    <div style={{
      backgroundColor: '#edb52d',
      backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' fill-rule=\'evenodd\'%3E%3Cg fill=\'%23f0be43\' fill-opacity=\'1\'%3E%3Cpath d=\'M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
      minHeight: '100vh'
    }}>
      <Header/>
      <MuiThemeProvider theme={second}>
        <div className={sg.Wrapper}>
          <HashRouter
            routerStatePath='routing.skillsRouter'
            loaderComponent={<div>Loading...</div>}
          >
            <Route hash='#/competenze/aree-professionali/{competenza1}/{competenza2}/{competenza3}' initAccess={true} routeKey='skillsJobGroups'>
              <SkillsJobGroups/>
            </Route>
          </HashRouter>
        </div>
      </MuiThemeProvider>
    </div>
  )
}

Skills.propTypes = {
  t: func
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)((translate()(Skills)))
