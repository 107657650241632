// CONSTANTS
import * as keys from "constants/translations/keys";

// LIBS
import cn from "classnames";

// REACT
import React, { useState } from "react";
import { func } from "prop-types";
import { translate, Trans } from "react-i18next";
import Header from "components/header";
import {
  Grid,
  Link,
  MuiThemeProvider,
  Paper,
  Typography,
} from "@material-ui/core";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// STYLE
import s from "./style.module.scss";
import sg from "css/style.module.scss";
import { common } from "styles/mui-themes";
import alma from "assets/images/logo_alma_laurea.png";
import inapp from "assets/images/logo_inapp.png";
import union from "assets/images/unioncamere.png";
import oecd from "assets/images/oecd_logo_english.png";

function InfoAlmalaurea(props) {
  return (
    <MuiThemeProvider theme={common}>
      <div className={s.Container}>
        <Header />
        <div className={cn(sg.Wrapper, s.Content)}>
          <Grid container>
            <Grid item sm={12} md={9}>
              <Paper className={s.Paper}>
                <img className={s.Logo} alt={"AlmaLaurea"} src={alma} />
                <Typography variant="h5" className={s.Title}>
                  Consorzio Interuniversitario AlmaLaurea
                </Typography>
                <Typography className={s.Text} variant="h6">
                  AlmaLaurea è un Consorzio Interuniversitario fondato nel 1994
                  che a oggi rappresenta 78 Atenei e circa il 90% di coloro che
                  ogni anno si laureano in Italia. Il Consorzio è sostenuto dal
                  contributo del Ministero dell’Università e della Ricerca e
                  dagli Atenei aderenti. Il suo Ufficio di Statistica è dal 2015
                  membro del Sistan, il Sistema Statistico Nazionale.
                  <br />
                  Il Consorzio realizza ogni anno due Indagini censuarie sul
                  Profilo e sulla Condizione occupazionale dei Laureati a 1, 3 e
                  5 anni dal conseguimento del titolo, restituendo agli Atenei
                  aderenti, al Ministero, all’Agenzia Nazionale di Valutazione
                  del Sistema Universitario e della Ricerca (ANVUR) basi
                  documentarie attendibili per favorire i processi di
                  programmazione, monitoraggio e valutazione delle decisioni
                  assunte dalle Università. Il Consorzio vuole essere anche un
                  punto di riferimento per i diplomati e per i laureati di ogni
                  grado, ai quali AlmaLaurea offre strumenti di orientamento,
                  servizi, informazioni e occasioni di confronto tra pari, per
                  valorizzare il loro percorso formativo e facilitare
                  l’inserimento nel mondo del lavoro. Il Consorzio raccoglie e
                  rende disponibili online i CV dei laureati (oggi oltre
                  3.300.000) e affianca gli Atenei consorziati nelle attività di
                  job placement attraverso una piattaforma web per
                  l’intermediazione.
                  <br />
                  Favorisce, inoltre, l’incontro tra offerta e domanda di lavoro
                  qualificato tramite la società interamente controllata
                  AlmaLaurea srl, Agenzia Per il Lavoro (APL) che opera
                  principalmente nell’intermediazione e nella ricerca e
                  selezione del personale, progettando ed erogando servizi -
                  rivolti a imprese, enti e professionisti - concepiti e offerti
                  nell’interesse primario dei laureati e in sinergia con gli
                  Atenei e con le Istituzioni pubbliche competenti. Il Consorzio
                  internazionalizza i propri servizi, le competenze, le attività
                  di ricerca in prospettiva globale, collaborando con Paesi
                  europei – in linea con la Strategia di Lisbona – ed extra
                  europei.
                  <br />
                  Dall’esperienza di AlmaLaurea è nata l’associazione di scuole{" "}
                  <Link href="https://www.almadiploma.it/" target="_blank">
                    <Typography
                      variant={"h6"}
                      className={cn(s.LinkText, s.inline)}
                    >
                      AlmaDiploma
                    </Typography>
                  </Link>{" "}
                  , per creare un collegamento tra la scuola secondaria
                  superiore, l’università e il mondo del lavoro.
                  <br />
                  <br />
                  AlmaLaurea contribuisce alla Piattaforma Competenze e Lavoro
                  fornendo informazioni sulle caratteristiche e le performance
                  formative e occupazionali dei laureati, tratte dalle due
                  Indagini censuarie sul Profilo e sulla Condizione
                  Occupazionale dei Laureati. Per approfondimenti sulle
                  indagini, è possibile consultare il link:
                  <br />
                  <Link href="http://www.almalaurea.it/universita/indagini/laureati">
                    <Typography variant={"h6"} className={s.LinkText}>
                      www.almalaurea.it/universita/indagini/laureati
                    </Typography>
                  </Link>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Paper className={s.RightPaper}>
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-inapp")}
                  alt={"INAPP"}
                  src={inapp}
                />
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-unioncamere")}
                  alt={"Unioncamere"}
                  src={union}
                />
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-oecd")}
                  alt={"OECD"}
                  src={oecd}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

InfoAlmalaurea.propTypes = {
  t: func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(InfoAlmalaurea));
