export const getCoursesAjax = (restUri, others) => ({
  type: 'GET_COURSES_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/courses`,
  ...others
})
    
export const getCoursesBy_idAjax = (restUri, _id, others) => ({
  type: 'GET_COURSES_BY__ID_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/courses/${_id}`,
  ...others
})
    
export const getJobsFromCoursesBy_idAjax = (restUri, _id, others) => ({
  type: 'GET_JOBS_FROM_COURSES_BY__ID_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/courses/${_id}/jobs`,
  ...others
})
    
export const getGroupedSkillsAjax = (restUri, others) => ({
  type: 'GET_GROUPED_SKILLS_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/grouped-skills`,
  ...others
})
    
export const getItalyCoursesByCodalma_classeconfrontoAjax = (restUri, codalma_classeconfronto, others) => ({
  type: 'GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/italy-courses/${codalma_classeconfronto}`,
  ...others
})
    
export const getJobsAjax = (restUri, others) => ({
  type: 'GET_JOBS_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/jobs`,
  ...others
})
    
export const getJobsBy_idAjax = (restUri, _id, others) => ({
  type: 'GET_JOBS_BY__ID_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/jobs/${_id}`,
  ...others
})
    
export const getSkillsByJobIdAjax = (restUri, jobId, others) => ({
  type: 'GET_SKILLS_BY_JOBID_AJAX_REQUEST',   
  method: 'GET',
  url: `${restUri}api/skills/${jobId}`,
  ...others
})
    
