// CONSTANTS
import * as keys from 'constants/translations/keys'

// REACT
import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { Trans, translate } from 'react-i18next'

// LIBS
import cn from 'classnames'

// STYLE
import s from './style.module.scss'

function SkillsSvg({
 className
}) {
    return (
  <svg className={className} x="0px" y="0px" viewBox="0 0 1100 1100">
<g>
<path d="M922.12,294.55c-5.2-12.17-4.52-24.85,1.81-33.94c10.48-15.04,21-30.06,31.05-44.41l9.4-13.42l-68.17-68.2
l-10.18,7.32c-16.12,11.6-32.73,23.55-49.43,35.37c-5.1,3.61-10.55,6.71-15.82,5l-3.86-1.23c-9.7-3.06-19.73-6.22-27.68-12.75
c-5.49-4.5-6.36-12.49-7.14-19.54c-0.23-2.09-0.45-4.15-0.8-6.02c-3-16.06-5.88-32.14-8.74-48.13l-2.4-13.39h-95.58
c-0.84,4.61-1.73,9.19-2.62,13.75c-2.8,14.42-5.45,28.03-6.36,41.86c-1.06,16.2-5.74,35.69-32.7,42.58
c-8.77,2.24-16.21,3.41-25.37-3.46c-15.05-11.3-30.57-22.23-45.58-32.79c-4.29-3.03-8.56-6.03-12.79-9.02l-68.2,67.6
c2.47,3.61,4.92,7.27,7.39,10.95c7.94,11.85,16.16,24.11,25.27,35.29c10.52,12.93,19.47,29.22,6.16,52.06
c-4.6,7.9-9.77,15.13-22.24,16.86c-22.69,3.15-45.35,6.86-67.34,11.02c-0.77,0.25-2.54,1.77-3.18,2.74
c-0.46,25.43-0.43,50.47-0.39,76.97l0.02,16.29c4.51,0.84,8.98,1.72,13.43,2.58c14.13,2.76,27.48,5.37,41.08,6.72
c18.34,1.81,37.96,7.34,44.38,34.52c1.97,8.34,3.06,15.98-3.65,25.02c-11.13,15.02-21.95,30.33-32.42,45.13
c-2.88,4.08-5.74,8.12-8.58,12.11l68.21,68.15c3.35-2.3,6.75-4.6,10.2-6.92c11.51-7.76,23.42-15.79,34.32-24.77
c12.6-10.39,30.09-20.49,53.6-6.28c6.75,4.09,13.73,8.31,15.41,20.38c2.56,18.44,5.75,36.43,9.12,55.47
c0.99,5.6,1.99,11.22,2.97,16.86c5.64,0,11.25,0.01,16.83,0.02c26.02,0.04,50.62,0.08,75.56-0.41c1.14-0.49,3.18-2.48,3.57-3.5
c5.69-27.12,9.48-48.19,12.24-68.17c1.74-12.58,9.18-16.97,16.36-21.21c19-11.21,36.21-8.98,52.6,6.81c1.76,1.7,4.05,3.2,6.46,4.78
c1.08,0.71,2.15,1.41,3.21,2.15c9.73,6.78,19.48,13.54,28.94,20.11l7.82,5.43l67.9-67.73l-40.03-55.44
c-7.71-10.68-9.31-24.35-4.28-36.57c5.06-12.31,15.89-20.96,28.97-23.13c17.05-2.84,34.39-5.98,51.15-9.01
c5.65-1.03,11.31-2.05,16.97-3.07v-95.47c-5.86-0.97-11.7-1.95-17.55-2.92c-17.52-2.92-35.64-5.94-53.44-8.71
C937.2,315.15,927.27,306.61,922.12,294.55z M983.14,352.99c3.18,0.52,5.51,3.27,5.51,6.48v33.6c0,3.17-2.26,5.88-5.38,6.46
c-5.99,1.11-11.98,2.19-17.95,3.27c-17.04,3.07-34.66,6.26-51.77,9.94c-1.74,0.37-5.05,3.19-6.18,5.67
c-4.09,9.04-7.97,18.43-11.72,27.52c-4.32,10.45-8.78,21.26-13.65,31.74c-2.1,4.51-1.72,5.99,0.87,9.47
c10.09,13.55,20.07,27.5,29.72,40.99c3.73,5.21,7.46,10.43,11.21,15.63c1.91,2.65,1.58,6.3-0.78,8.57l-24.46,23.53
c-2.26,2.17-5.74,2.44-8.3,0.66l-59.16-41.14c-5.14,1.97-10.21,3.9-15.23,5.81c-18.62,7.09-36.21,13.79-53.71,20.98
c-1.04,0.45-3.18,3.23-3.63,5.37c-3.66,17.54-7.81,37.4-10.07,56.8c-1.52,13.03-7.52,19.21-18.79,19.21
c-1.24,0-2.54-0.07-3.91-0.22c-2.91-0.32-5.92-0.34-9.21-0.29c-1.1,0.02-2.23,0.05-3.39,0.08c-2.32,0.06-4.72,0.12-7.26,0.12
c-3.2,0-5.93-2.31-6.47-5.46c-1.12-6.53-2.28-12.98-3.43-19.4c-2.92-16.25-5.69-31.61-7.7-47.47c-0.79-6.25-2.39-8.1-8.97-10.4
c-2.56-0.89-5.09-1.79-7.58-2.7c-17.44-6.33-33.17-12.93-47.89-20.11c-1.65-0.8-2.99-1.41-4.15-1.81c-1.16-0.4-2.13-0.61-3.02-0.61
c-1.79-0.01-3.28,0.78-5.39,2.35c-13.21,9.84-26.79,19.21-39.93,28.28c-5.17,3.57-10.3,7.11-15.34,10.63
c-2.52,1.76-5.95,1.53-8.22-0.56l-25.04-23.12c-1.82-1.69-2.49-4.2-1.9-6.48c0.2-0.76,0.54-1.5,1.02-2.17
c3.91-5.45,7.74-10.86,11.54-16.22c9.66-13.64,18.8-26.52,28.54-39.35c3.21-4.23,3.23-6.11,0.11-12.57
c-5.25-10.86-9.79-22.16-14.19-33.1c-3.19-7.94-6.49-16.15-10-24.03c-1.34-3.01-4.47-5.89-5.8-6.18
c-16.8-3.64-33.44-6.6-51.05-9.73c-5.98-1.07-11.99-2.14-18.03-3.24c-3.12-0.57-5.39-3.29-5.39-6.46v-34.01
c0-3.22,2.33-5.96,5.51-6.48c6.36-1.04,12.68-2.09,18.97-3.14c15.36-2.55,31.25-5.19,46.83-7.54c5.68-0.86,7.81-2.25,10.11-9.28
c3.94-12.05,9.1-23.73,14.09-35.03c3.25-7.35,6.6-14.94,9.54-22.47c0.14-0.36,0.26-0.75,0.36-1.15c0.3-1.21,0.41-2.54,0.38-3.75
c-0.03-1.62-0.31-3.03-0.71-3.64c-9.41-14.45-19.52-28.78-29.3-42.65c-3.41-4.83-6.81-9.65-10.18-14.48
c-1.28-1.83-1.54-4.19-0.69-6.26c0.12-0.28,0.2-0.54,0.27-0.77c0.34-1.01,0.85-2.53,2.15-3.94c23.43-25.31,27.02-25.7,54.95-5.77
l34.56,24.66c5.37-1.99,10.66-3.94,15.88-5.85c18.65-6.86,36.27-13.34,53.7-20.38c1.02-0.54,3.16-3.77,3.68-6.52
c3.15-16.58,5.87-33.5,8.51-49.86c0.92-5.71,1.84-11.4,2.77-17.07c0.36-2.17,1.77-4.01,3.77-4.92c0.36-0.16,0.66-0.33,0.92-0.47
c1.02-0.54,2.42-1.29,4.35-1.4c34.25-2.13,37.04,0.05,42.71,33.43c0.52,3.05,1,5.99,1.48,8.92c1.79,10.84,3.63,22.06,6.21,32.78
c0.41,1.7,2.99,4.78,5.31,5.82c20.65,9.26,41.34,17.89,61.47,25.63c1.76,0.69,5.72,0.21,7.31-0.86
c14.27-9.67,27.96-19.55,42.45-30.01c4.87-3.51,9.75-7.04,14.67-10.57c2.75-1.96,6.55-1.53,8.77,1.02l22.71,26.02
c1.99,2.27,2.17,5.61,0.44,8.08c-3.14,4.48-6.38,9.17-9.72,14c-9.12,13.18-18.55,26.81-28.61,40.44c-2.99,4.05-3.04,6-0.29,11.61
c8.62,17.57,16.82,34.87,22.35,53.27c2.57,8.54,5.19,10.88,13.33,11.94c15.25,1.99,30.68,4.61,45.61,7.14
C970.53,350.89,976.81,351.96,983.14,352.99z"/>
<path d="M562.71,266.83c0.4,0.61,0.67,2.02,0.71,3.64C563.38,268.85,563.11,267.45,562.71,266.83z"/>
<path d="M520.87,546.37c-0.59,2.28,0.08,4.79,1.9,6.48l25.04,23.12l-25.04-23.12
C520.95,551.17,520.28,548.66,520.87,546.37z"/>
<path d="M619.7,535.89c1.16,0.4,2.5,1.01,4.15,1.81c14.71,7.17,30.44,13.78,47.89,20.11
c-17.44-6.33-33.17-12.93-47.88-20.11C622.2,536.89,620.86,536.29,619.7,535.89z"/>
<path d="M611.29,537.62c2.11-1.57,3.6-2.36,5.39-2.35C614.89,535.27,613.4,536.05,611.29,537.62z"/>
<path d="M716.55,643.03c3.29-0.06,6.3-0.03,9.21,0.29c1.37,0.15,2.67,0.22,3.91,0.22c-1.24,0-2.54-0.07-3.91-0.22
C722.85,643,719.85,642.98,716.55,643.03z"/>
<path d="M310.23,902.53c-46.98-0.88-78.57-30.09-84.49-78.13c-5.89-47.78-12.97-99.8-21.65-159.04
c-0.41-2.78-4.05-7.35-7.22-9.05c-15.64-8.42-31.9-16.46-47.61-24.23c-7.16-3.54-14.32-7.08-21.46-10.68
c-8.71-4.39-14.5-8.4-16.44-14.87c-1.95-6.5,0.71-13.09,5.62-21.61c11.13-19.3,22.3-38.57,33.48-57.84
c15.63-26.94,31.79-54.8,47.53-82.27c3.26-5.69,5.68-12.02,6.99-18.32c2.51-12.04,4.7-24.44,6.81-36.44
c4.03-22.86,8.19-46.49,14.87-69.18c25.93-88.11,76.84-148.39,151.33-179.17c20.24-8.36,41.88-13.64,62.81-18.75
c7.78-1.89,15.83-3.86,23.63-5.94c5.94-1.59,15.64-6.02,13.75-17.9c-1.3-8.18-8.07-13.43-15.75-12.24
c-85.47,13.26-150.29,46.26-198.16,100.89c-41.75,47.64-67.2,106.77-82.52,191.71c-0.64,3.53-1.22,7.11-1.81,10.71
c-2.61,16-5.32,32.55-12.83,46.87c-17.31,32.96-36.5,65.55-55.06,97.05c-7.68,13.04-15.35,26.07-22.93,39.16
c-7.89,13.65-10.11,28.39-6.25,41.52c3.92,13.32,13.53,24.23,27.79,31.55c6.71,3.44,13.49,6.76,20.26,10.09
c10.9,5.35,22.17,10.88,33.04,16.87c6.31,3.49,11.83,11.13,12.83,17.79c6.16,40.91,11.9,82.56,17.44,122.83l3.24,23.48
c6.33,45.79,41.15,83.57,84.68,91.86c14.81,2.82,30.73,2.87,46.13,2.91c6.55,0.02,13.33,0.04,20,0.26
c6.16,0.21,12.44,0.16,19.09,0.1c3.25-0.03,6.6-0.05,10.1-0.05c3.63,0,6.57,2.94,6.57,6.57v79.75h28.69V902.67
c-9.29,0.01-18.5,0.06-27.65,0.1C359.34,902.89,335.05,902.99,310.23,902.53z"/>
<path d="M861.56,681.18c-3.58-2.48-7.18-3.4-10.68-2.75c-4.37,0.81-8.6,4.08-12.23,9.43
c-43.93,64.79-65.18,129.69-64.96,198.39c0.07,23.23-0.14,46.82-0.35,69.64c-0.11,11.78-0.22,23.56-0.29,35.35
c-0.04,7.49-0.03,14.99-0.02,22.74l0,4.81h30.28c0.03-10.28,0.11-20.46,0.2-30.58c0.23-27.29,0.44-53.07-0.43-79.27
c-2.45-73.47,17.69-142.04,59.85-203.8C866.14,700.43,872.42,688.7,861.56,681.18z"/>
<path d="M723.19,275.28c-0.04,0-0.08,0-0.11,0c-55.87,0-101.5,45.46-101.73,101.4c-0.11,26.66,10.37,51.95,29.53,71.22
c19.34,19.46,44.92,30.21,72.02,30.27c0.07,0,0.14,0,0.21,0c18.51,0,36.33-5.01,51.94-14.42c7.1-4.27,13.74-9.45,19.78-15.48
c19.28-19.23,29.91-44.55,29.92-71.31C824.78,321.91,778.27,275.34,723.19,275.28z M723.3,448.42c-0.12,0-0.24,0-0.36,0
c-7.12,0-14.09-1.05-20.73-3.07c-11.07-3.37-21.23-9.46-29.69-17.92c-10.21-10.21-16.94-22.9-19.62-36.65
c-0.89-4.58-1.34-9.29-1.31-14.06c0.23-39.87,31.62-70.99,71.53-70.99c0.12,0,0.23,0,0.35,0c39.96,0.18,71.17,31.75,71.05,71.86
C794.4,416.44,762.45,448.22,723.3,448.42z"/>
</g>
</svg>
)
}

SkillsSvg.propTypes = {
  className: string,
  t: func
}

export default (translate()(SkillsSvg))
