// LIBS
import cn from 'classnames'

// REACT
import React, { useState } from 'react'
import { func, number, string, object, any } from 'prop-types'
import { translate } from 'react-i18next'

// STYLE
import s from './style.module.scss'

function Spot(props) {
  const {
    className,
    color,
    size,
    style,
    children
  } = props
    return (
      <div className={cn(className, style)} style={{
        backgroundColor: color,
        minWidth: size,
        minHeight: size,
        borderRadius: size / 2
      }}>{children}</div>
    )
}

Spot.propTypes = {
  children: any,
  className: string,
  color: string,
  size: number,
  style: object,
  t: func
}

export default (translate()(Spot))
