// LIBS
import immutable from 'immutable'

export default immutable.fromJS({
  list: [],
  current: null,
  italy_current: null,
  ascendant: {
    regionId: true,
    nomecorso: true
  }
})
