// CONSTANTS
import { SearchOutlined as SearchIcon } from "@material-ui/icons";
import { chartColors, common } from "styles/mui-themes";

// REACT
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { bool, func, number, object, string, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  FormControl,
  Icon,
  LinearProgress,
  Link,
  MuiThemeProvider,
  OutlinedInput,
  Paper,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Zoom,
} from "@material-ui/core";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import ItalyMap from "components/italy-map";
import Spot from "components/spot";
import PageToolbar from "components/page-toolbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "components/autocomplete";
import ArrowBack from "components/arrow-back";
import SkillsSvg from "components/path-icon/components/skills-svg";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getJobsAjax, getSkillsByJobIdAjax } from "redux/actions/ajax";
import { getAjaxLoading, getRestUri } from "redux/getters";
import { getJobsParams } from "redux/getters/routing";
import {
  getAscendant,
  getJobsList,
  getCount,
  getPage,
} from "redux/getters/jobs";
import { clearList, setAscendant, setPage } from "redux/actions";
import { getSkillsList } from "redux/getters/skills";

// STYLE
import s from "./style.module.scss";
import sg from "css/style.module.scss";
import sa from "css/atomic.module.scss";
import cn from "classnames";
import ProfessioniIcon from "assets/images/professioni_neg.svg";
import JobsSvg from "components/path-icon/components/jobs-svg";
import PathIcon from "components/path-icon";
import SkillsTooltip from "components/skills-tooltip";

const jobs = require("constants/jobs-labels");
const jobsLabelsExtended = require("constants/jobs-labels-extended");
const regions = require("constants/regions-labels");
const strings = require("constants/strings");
import ArrowForward from "components/arrow-forward";
import { formatDigit, formatNumber } from "services/formatter";

HC_more(Highcharts);
require("./rounded-corner")(Highcharts);

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = (theme) => ({
  tableRoot: {
    "& td, th": {
      padding: "15px 8px",
    },
  },
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
  bootstrapTooltip: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    maxWidth: 800,
  },
  arrowPopper: arrowGenerator(theme.palette.primary.main),
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
});

function JobsResults({
  ascendant,
  classes,
  clearList,
  count,
  getSkillsByJobIdAjax,
  getJobsAjax,
  jobsList,
  jobsParams,
  jobsLoading,
  page,
  setPage,
  setAscendant,
  skillsList,
  restUri,
}) {
  const LIMIT = 4;

  const [selectedRegion, setSelectedRegion] = useState(
    jobsParams.get("regionId") === "999"
      ? null
      : parseInt(jobsParams.get("regionId"), 10),
  );
  const [selectedJob, setSelectedJob] = useState(null);

  const jobsLabels = useMemo(() => {
    const jobsLabels2 = [];
    Object.keys(jobsLabelsExtended).map((key) => {
      jobsLabelsExtended[key].values.map((value, index) => {
        jobsLabels2.push({
          value: `${key}:${index}`,
          label: value.label,
        });
      });
    });
    return jobsLabels2;
  }, []);

  const selectedJobParts = (selectedJob && selectedJob.split(":")) || [];

  useEffect(() => {
    setSelectedRegion(
      jobsParams.get("regionId") === "999"
        ? null
        : jobsParams.get("regionId") === "tutte"
        ? "tutte"
        : parseInt(jobsParams.get("regionId"), 10),
    );
    jobsParams.get("jobId") !== "tutte" &&
      getSkillsByJobIdAjax(restUri, jobsParams.get("jobId"));
    jobsList.size <= (page + 1) * LIMIT &&
      getJobsAjax(restUri, {
        query: {
          regionId:
            jobsParams.get("regionId") === "tutte"
              ? undefined
              : jobsParams.get("regionId"),
          jobId:
            jobsParams.get("jobId") === "tutte"
              ? undefined
              : jobsParams.get("jobId"),
          skip: jobsParams.get("jobId") === "tutte" ? page * LIMIT : 0,
          limit: jobsParams.get("regionId") === "tutte" ? undefined : LIMIT,
          sort: ascendant
            .map((item) => (item !== null ? (item ? "ASC" : "DESC") : null))
            .toJS(),
        },
      });
  }, [jobsParams, page, ascendant]);

  useEffect(() => {
    setPage(0);
    window.location.href = `#/professioni/${
      selectedRegion || "999"
    }/${jobsParams.get("jobId")}/${jobsParams.get("index") || 0}`;
  }, [selectedRegion]);

  const isMobile = useMediaQuery("(max-width:768px)");

  function onSort(sortBy) {
    setPage(0);
    clearList();
    setAscendant(
      sortBy,
      ascendant.get(sortBy)
        ? false
        : ascendant.get(sortBy) !== null
        ? null
        : true,
    );
  }

  function renderStackedChart(params) {
    const { height } = params;
    const series = params.series.filter((serie) => serie.data[0] > 0);
    let tot = 0;

    series.forEach((serie) => {
      tot = tot + serie.data[0];
    });

    series.forEach((serie, index) => {
      serie.descriptionValue = serie.data[0];
      if (serie.data[0]) {
        serie.data[0] = (serie.data[0] / tot) * 100;
      } else {
        serie.data[0] = 0;
      }
      series[index] = serie;
    });

    return (
      <div id={"ChartContainer"} className={s.ChartContainer}>
        {!jobsLoading ? (
          params.value ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "bar",
                  renderTo: "ChartContainer",
                  height,
                  margin: [0, 0, 0, 0],
                },
                title: {
                  text: "",
                },
                xAxis: {
                  categories: [""],
                  lineWidth: 0,
                  tickWidth: 0,
                },
                yAxis: {
                  min: 0,
                  max: 100,
                  gridLineWidth: 0,
                  labels: {
                    enabled: false,
                  },
                  title: {
                    text: "",
                  },
                },
                tooltip: {
                  formatter() {
                    return `<tspan style="fill:${
                      this.color.stops[0][1]
                    }" x="8" dy="15">●</tspan> ${
                      this.series.name +
                      ": " +
                      this.series.userOptions.descriptionValue +
                      "%"
                    }`;
                  },
                },
                legend: {
                  enabled: false,
                },
                credits: { enabled: false },
                plotOptions: {
                  series: {
                    stacking: "normal",
                    pointPadding: 0,
                    groupPadding: 0,
                  },
                },
                series,
              }}
            />
          ) : (
            <Typography className={s.NoData}>
              Nessun dato per questo parametro
            </Typography>
          )
        ) : (
          <LinearProgress className={s.Progress} />
        )}
      </div>
    );
  }

  function renderPieChart(params) {
    const { width, height, series, title } = params;

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          credits: {
            enabled: false,
          },
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            width,
            height,
          },
          title: {
            verticalAlign: "middle",
            floating: true,
            margin: 0,
            style: {
              "font-size": "11px",
              "font-family": '"Tofino-Bold", sans-serif',
            },
            text: title,
            y: -0.5,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
              startAngle: -90,
              endAngle: -90,
              size: "110%",
            },
            series: {
              innerSize: "80%",
            },
          },
          tooltip: {
            formatter() {
              return `<tspan style="fill:${
                this.color
              }" x="8" dy="15">●</tspan> ${this.key + ": " + this.y + "%"}`;
            },
          },
          series,
        }}
      />
    );
  }

  function renderRoseChart(params) {
    const { title, height, series, legend } = params;

    let categories = [];

    Object.keys(regions).map((regionId) => {
      if (regionId.toString() !== "999") {
        let index = series[0].data.findIndex((d) => {
          return d.regionId.toString() === regionId.toString();
        });
        if (index !== -1 && series[0].data[index]["ass_tot"] < 20) {
          const regionName =
            isMobile && regionId === "100"
              ? "PIEMONTE - VDA"
              : regions[regionId];
          categories.push(regionName + " n.d.");
        } else {
          categories.push(regions[regionId]);
        }
      }
    });
    series.map((s) => {
      let data = [];
      Object.keys(regions).map((regionId) => {
        if (regionId.toString() !== "999") {
          let index = s.data.findIndex((d) => {
            return d.regionId.toString() === regionId.toString();
          });
          if (index === -1) {
            data.push(0);
          } else {
            data.push(
              s.field === "ass_tot"
                ? s.data[index].ass_tot < 20
                  ? 0
                  : s.data[index].ass_tot
                : s.data[index].ass_tot < 20
                ? 0
                : parseFloat((s.data[index][s.field] * 100).toFixed(0)),
            );
          }
        }
      });
      s.data = data;
      return s;
    });

    return (
      <div className={s.RoseCell}>
        {title}
        {legend}
        <div className={s.RoseChart}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              credits: {
                enabled: false,
              },
              data: {
                startRow: 0,
                endRow: jobsList.size - 1,
                endColumn: 1,
              },
              chart: {
                polar: true,
                type: "column",
                height,
              },
              title: {
                text: "",
              },
              pane: {
                startAngle: 0,
                endAngle: 360,
              },
              legend: {
                enabled: false,
              },
              xAxis: {
                categories,
                tickmarkPlacement: "between",
                lineWidth: 0,
                labels: {
                  style: {
                    fontSize: isMobile ? 6 : 10,
                  },
                },
              },
              yAxis: {
                min: 0,
                endOnTick: false,
                showLastLabel: true,
                reversedStacks: false,
                visible: false,
              },
              plotOptions: {
                series: {
                  shadow: false,
                  groupPadding: 0,
                  pointPlacement: "on",
                },
              },
              series,
            }}
          />
        </div>
      </div>
    );
  }

  const allRegioniLessThan20 = useMemo(() => {
    return !jobsList.toJS().some((d) => d.ass_tot > 20);
  }, [jobsList]);

  return (
    <div className={s.Container}>
      <PageToolbar
        title={
          "Scopri come una professione si inserisce nel mercato del lavoro"
        }
        tooltipText={
          "I risultati di questa pagina provengono dal Sistema informativo Excelsior. Questo mette a disposizione dati, approfondimenti e altri strumenti informativi sulla domanda di professioni e competenze delle imprese italiane e sull’individuazione delle principali aree di mismatch tra domanda e offerta di lavoro e le possibili misure di intervento atte a colmarlo, nonché guide, manuali e altri strumenti informativi di sistema a supporto dell’orientamento scolastico, universitario e lavorativo. L’indagine valorizza in modo integrato le informazioni desunte dagli archivi amministrativi camerali su imprese e occupazione e coinvolge annualmente un campione di oltre 500.000 imprese di tutti i settori economici e di tutte le dimensioni. L’elevato numero di interviste e la metodologia adottata per l’elaborazione dei dati consentono di ottenere dati previsionali mensili e annuali sui fabbisogni professionali richiesti dalle imprese per tutte le province italiane. "
        }
      />
      <Paper className={s.Paper}>
        <div className={s.GlobalRow}>
          <MuiThemeProvider theme={common}>
            <Link
              color={"secondary"}
              href={"/#/professioni/ricerca"}
              underline={"none"}
            >
              <ArrowBack />
              <Typography variant="h4">{"Home Professioni"}</Typography>
            </Link>
          </MuiThemeProvider>
        </div>
        <div className={cn(s.GlobalRow, s.RowReverse)}>
          <div className={s.TitleContainer}>
            {jobsLabelsExtended[jobsParams.get("jobId")].values[
              jobsParams.get("index")
            ] &&
            jobsLabelsExtended[jobsParams.get("jobId")].values[
              jobsParams.get("index")
            ].show4Digit ? (
              <Typography className={s.Title} variant="h5">
                {jobs[jobsParams.get("jobId")] ||
                  strings[jobsParams.get("jobId")]}{" "}
                ({formatDigit(jobsParams.get("jobId"))})
              </Typography>
            ) : (
              (
                <Typography>
                  <Typography className={s.Title} variant="h5">
                    <span className={cn(classes.title, s.Title)}>
                      {jobsLabelsExtended[jobsParams.get("jobId")].values[
                        jobsParams.get("index")
                      ] &&
                        `${
                          jobsLabelsExtended[jobsParams.get("jobId")].values[
                            jobsParams.get("index")
                          ].label
                        } ➔ `}
                    </span>
                    {jobs[jobsParams.get("jobId")]} (
                    {formatDigit(jobsParams.get("jobId"))})
                  </Typography>
                </Typography>
              ) || (
                <Typography className={s.Title} variant="h5">
                  {strings[jobsParams.get("jobId")]}
                </Typography>
              )
            )}
          </div>

          <div className={s.SearchContainer}>
            <Autocomplete
              className={s.Input}
              suggestions={jobsLabels}
              placeholder={"Medici, Guide turistiche, Professori, ecc..."}
              onChange={setSelectedJob}
              label={"Cerca Professione"}
              onKeyPress={(e) => {
                if (e.key === "Enter" && selectedJob) {
                  window.location.href = `#/professioni/${
                    selectedRegion ||
                    Object.keys(regions).find(
                      (key) => regions[key] === "ITALIA",
                    )
                  }/${selectedJobParts[0]}/${selectedJobParts[1]}`;
                }
              }}
            />
            <Fab
              className={s.SearchFab}
              aria-label="Professioni"
              color="primary"
              disabled={!selectedJob}
              href={`#/professioni/${
                selectedRegion ||
                Object.keys(regions).find((key) => regions[key] === "ITALIA")
              }/${selectedJobParts[0]}/${selectedJobParts[1]}`}
            >
              <SearchIcon className={sa.CW} />
            </Fab>
          </div>
        </div>
        {jobsParams.get("jobId") !== "tutte" && (
          <Link
            underline={"none"}
            href={`#/percorsi-formativi/ricerca/1/${jobsParams.get(
              "regionId",
            )}/${jobsParams.get("jobId")}/null/null`}
          >
            <Typography
              color={"primary"}
              className={cn(s.Other)}
              variant={"h5"}
            >
              {
                "Vuoi scoprire i percorsi formativi collegati a questa professione? Esplora qui"
              }
            </Typography>
          </Link>
        )}
        {jobsParams.get("jobId") !== "tutte" && skillsList && (
          <SkillsTooltip
            className={s.SkillsContainer}
            selectedSkills={skillsList.map((skill) => skill.get("skillId"))}
            showTitle
          />
        )}

        <div className={cn(s.GlobalRow, sa.AIFS)}>
          <div
            className={
              jobsParams.get("regionId") === "tutte" ? s.LeftFull : s.Left
            }
          >
            {jobsLabelsExtended[jobsParams.get("jobId")] &&
              jobsLabelsExtended[jobsParams.get("jobId")].values[
                jobsParams.get("index")
              ] &&
              !jobsLabelsExtended[jobsParams.get("jobId")].values[
                jobsParams.get("index")
              ].show4Digit &&
              !(jobsLoading || jobsList.size === 0) && (
                <Typography variant="subtitle1" className={s.Info}>
                  La professione
                  {' "'}
                  {jobsLabelsExtended[jobsParams.get("jobId")] &&
                    jobsLabelsExtended[jobsParams.get("jobId")].values[
                      jobsParams.get("index")
                    ].label}
                  {'" '}
                  fa parte della categoria professionale &quot;
                  {jobs[jobsParams.get("jobId")]} (
                  {formatDigit(jobsParams.get("jobId"))}
                  )&quot;. I dati di seguito presentati fanno riferimento a
                  questa categoria professionale.
                </Typography>
              )}
            {jobsParams.get("regionId") !== "tutte" && (
              <Typography variant={"subtitle1"}>
                Seleziona una regione
              </Typography>
            )}
            {jobsParams.get("regionId") !== "tutte" && (
              <Typography className={s.CurrentView} variant={"h6"}>
                {"Stai visualizzando:"}
              </Typography>
            )}
            {selectedRegion ? (
              <div className={s.RegionTitle}>
                <MuiThemeProvider theme={common}>
                  <Link
                    color={"secondary"}
                    classes={{
                      root: classes.linkRoot,
                    }}
                    onClick={() => setSelectedRegion(null)}
                    underline={"none"}
                  >
                    <Typography color={"inherit"} variant={"h6"}>
                      {jobsParams.get("regionId") !== "tutte"
                        ? "Tutta Italia >"
                        : "Torna alla mappa"}
                    </Typography>
                  </Link>
                </MuiThemeProvider>
                &nbsp;
                <Typography
                  classes={{
                    root: classes.regionText,
                  }}
                >
                  {regions[selectedRegion]}
                </Typography>
              </div>
            ) : (
              <div className={s.RegionTitle}>
                <Typography color={"inherit"} variant={"h6"}>
                  {"Tutta Italia"}
                </Typography>
                &nbsp;
                {jobsParams.get("jobId") !== "tutte" &&
                  (!selectedRegion ||
                    selectedRegion === 999 ||
                    selectedRegion === "999") && (
                    <MuiThemeProvider theme={common}>
                      <Link
                        color={"secondary"}
                        classes={{
                          root: classes.linkRoot,
                        }}
                        onClick={() =>
                          jobsParams.get("regionId") === "999" &&
                          (window.location.href = `#/professioni/tutte/${jobsParams.get(
                            "jobId",
                          )}/${jobsParams.get("index")}`)
                        }
                        underline={"none"}
                      >
                        <Typography color={"inherit"} variant={"h6"}>
                          {"Confronta tutte le regioni"}
                        </Typography>
                      </Link>
                    </MuiThemeProvider>
                  )}
              </div>
            )}
            {jobsParams.get("regionId") !== "tutte" && (
              <Fragment>
                <MuiThemeProvider theme={common}>
                  <ItalyMap
                    className={s.Map}
                    selected={selectedRegion}
                    onRegionClick={setSelectedRegion}
                  />
                </MuiThemeProvider>
                <FormControl
                  className={s.RegionSelect}
                  fullWidth
                  variant="filled"
                >
                  <Select
                    native
                    value={selectedRegion || ""}
                    onChange={(e) => setSelectedRegion(e.target.value)}
                    input={
                      <OutlinedInput
                        color="primary"
                        fullWidth
                        name="region"
                        id="region-select"
                      />
                    }
                  >
                    <option value="999">{"Seleziona una Regione"}</option>
                    {Object.keys(regions).map((regionId) => {
                      if (regionId !== "999") {
                        return (
                          <option key={regionId} value={regionId}>
                            {regions[regionId]}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Fragment>
            )}

            {jobsParams.get("regionId") === "tutte" && (
              <Fragment>
                {(jobsParams.get("regionId") !== "999" &&
                  jobsList.getIn([1, "ass_tot"]) < 20) ||
                (jobsParams.get("regionId") === "999" &&
                  jobsList.getIn([0, "ass_tot"]) < 20) ||
                allRegioniLessThan20 ? (
                  jobsLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant={"h3"}>
                      Dati non disponibili per questa professione
                    </Typography>
                  )
                ) : (
                  <div className={s.Row}>
                    <div className={s.ResultRoseColumn}>
                      <div className={s.ResultRoseColumnCells}>
                        {renderRoseChart({
                          legend: <div className={s.LegendContainer}> </div>,
                          height: isMobile ? 240 : 450,
                          title: (
                            <Tooltip
                              title={
                                <Typography variant="h4" color="inherit">
                                  Attivazioni di contratti previsti dalle
                                  imprese per l’anno 2019.
                                </Typography>
                              }
                            >
                              <Typography
                                variant="h3"
                                className={s.RoseTypography}
                              >
                                Domanda per assunzioni
                              </Typography>
                            </Tooltip>
                          ),
                          series: [
                            {
                              stacking: "normal",
                              name: "Domanda per assunzioni",
                              data: jobsList.toJS(),
                              color: chartColors.chart_1,
                              field: "ass_tot",
                            },
                          ],
                        })}

                        {renderRoseChart({
                          legend: (
                            <div className={s.LegendContainer}>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_3}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Ridotto numero di candidati"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_2}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Inadeguatezza dei candidati"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_1}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Altro"}
                                </Typography>
                              </div>
                            </div>
                          ),
                          height: isMobile ? 240 : 450,
                          title: (
                            <Tooltip
                              title={
                                <Typography variant="h4" color="inherit">
                                  È una dichiarazione delle imprese sulla
                                  difficoltà nel reperire, nel territorio in cui
                                  operano, candidati idonei a ricoprire la
                                  figura professionale ricercata. Le difficoltà
                                  sono sintetizzate in due grandi motivazioni
                                  (ridotto numero di candidati o inadeguatezza
                                  dei candidati), cui si aggiunge una modalità
                                  &quot;altro&quot;, eventualmente da
                                  specificare.
                                </Typography>
                              }
                            >
                              <Typography
                                variant="h3"
                                className={s.RoseTypography}
                              >
                                Difficoltà di reperimento (%)
                              </Typography>
                            </Tooltip>
                          ),
                          series: [
                            {
                              stacking: "normal",
                              name: "Altro",
                              data: jobsList.toJS(),
                              color: chartColors.chart_1,
                              field: "diff_altro",
                            },
                            {
                              stacking: "normal",
                              name: "Inadeguatezza dei candidati",
                              data: jobsList.toJS(),
                              color: chartColors.chart_2,
                              field: "inadeg_candidati",
                            },
                            {
                              stacking: "normal",
                              name: "Ridotto numero di candidati",
                              data: jobsList.toJS(),
                              color: chartColors.chart_3,
                              field: "ridotti_candidati",
                            },
                          ],
                        })}
                      </div>
                      <div className={s.ResultRoseColumnCells}>
                        {renderRoseChart({
                          legend: (
                            <div className={s.LegendContainer}>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_1}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Scuola dell'obbligo"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_3}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Diploma professionale"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_2}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Diploma superiore"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_4}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Laurea"}
                                </Typography>
                              </div>
                            </div>
                          ),
                          height: isMobile ? 240 : 450,
                          title: (
                            <Typography
                              variant="h3"
                              className={s.RoseTypography}
                            >
                              Titolo di studio (%)
                            </Typography>
                          ),
                          series: [
                            {
                              name: "Scuola dell'obbligo",
                              data: jobsList.toJS(),
                              color: chartColors.chart_1,
                              field: "scuola_obbligo",
                            },
                            {
                              name: "Diploma professionale",
                              data: jobsList.toJS(),
                              color: chartColors.chart_3,
                              field: "diploma_prof",
                            },
                            {
                              name: "Diploma superiore",
                              data: jobsList.toJS(),
                              color: chartColors.chart_2,
                              field: "diploma_sup",
                            },
                            {
                              name: "Laurea",
                              data: jobsList.toJS(),
                              color: chartColors.chart_4,
                              field: "ed_univers",
                            },
                          ],
                        })}

                        {renderRoseChart({
                          legend: (
                            <div className={s.LegendContainer}>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_1}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Giovani, meno di 29 anni"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_2}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Minimo 30 anni"}
                                </Typography>
                              </div>
                              <div className={s.Legend}>
                                <Spot
                                  className={s.LegendSpot}
                                  color={chartColors.chart_3}
                                  size={10}
                                />
                                <Typography variant={"caption"}>
                                  {"Età indifferente"}
                                </Typography>
                              </div>
                            </div>
                          ),
                          height: isMobile ? 240 : 450,
                          title: (
                            <Typography
                              variant="h3"
                              className={s.RoseTypography}
                            >
                              Età (%)
                            </Typography>
                          ),
                          series: [
                            {
                              name: "Giovani, meno di 29 anni",
                              data: jobsList.toJS(),
                              color: chartColors.chart_1,
                              field: "fino29anni",
                            },
                            {
                              name: "Minimo 30 anni",
                              data: jobsList.toJS(),
                              color: chartColors.chart_2,
                              field: "da30oltre",
                            },
                            {
                              name: "Età indifferente",
                              data: jobsList.toJS(),
                              color: chartColors.chart_3,
                              field: "eta_nonrilev",
                            },
                          ],
                        })}
                      </div>
                      <Typography>
                        *n.d. – dati non disponibili per questa professione in
                        questa regione
                      </Typography>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {jobsParams.get("regionId") !== "tutte" && (
            <div
              className={
                jobsParams.get("jobId") === "tutte"
                  ? s.Right
                  : cn(s.Right, sa.PT10)
              }
            >
              {(jobsParams.get("regionId") !== "999" &&
                jobsList.getIn([1, "ass_tot"]) < 20) ||
              (jobsParams.get("regionId") === "999" &&
                jobsList.getIn([0, "ass_tot"]) < 20) ? (
                <Typography variant={"h3"}>
                  Dati non disponibili per questa professione
                </Typography>
              ) : (
                <Fragment>
                  {jobsParams.get("regionId") !== "999" &&
                  jobsList.getIn([0, "ass_tot"]) < 20 ? (
                    <Typography variant={"h3"}>
                      {selectedRegion !== null ? (
                        <Fragment>
                          Il dato non é disponibile per questa categoria
                          professionale in {regions[selectedRegion]}. Consulta i
                          risultati per
                          <Link
                            className={s.TableLink}
                            color={"secondary"}
                            onClick={() => setSelectedRegion(null)}
                            underline={"none"}
                          >
                            &nbsp;l’Italia
                          </Link>
                        </Fragment>
                      ) : (
                        "Dati non disponibili per questa professione\n"
                      )}
                    </Typography>
                  ) : (
                    <Fragment>
                      {jobsParams.get("jobId") === "tutte" ? (
                        <Fragment>
                          <Typography className={s.TableInfo} variant={"h6"}>
                            {
                              "Clicca sull’intestazione della tabella per riordinare i valori"
                            }
                          </Typography>
                          <Table
                            className={s.Table}
                            classes={{
                              root: classes.tableRoot,
                            }}
                            padding="dense"
                          >
                            <TableHead>
                              <TableCell className={s.TableFirstRow1}>
                                <TableSortLabel
                                  active={ascendant.get("jobName") !== null}
                                  className={s.Number}
                                  direction={
                                    ascendant.get("jobName") ? "asc" : "desc"
                                  }
                                  onClick={() =>
                                    onSort("jobName", ascendant.get("jobName"))
                                  }
                                >
                                  <div className={s.TableCellInfo}>
                                    <Typography
                                      variant={"h6"}
                                      className={s.TableCellTitle}
                                    >
                                      {"Nome professione"}
                                    </Typography>
                                  </div>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={s.TableFirstRow2}>
                                <TableSortLabel
                                  active={ascendant.get("ass_tot") !== null}
                                  className={s.Number}
                                  direction={
                                    ascendant.get("ass_tot") ? "asc" : "desc"
                                  }
                                  onClick={() =>
                                    onSort("ass_tot", ascendant.get("ass_tot"))
                                  }
                                >
                                  <div className={s.TableCellInfo}>
                                    <img
                                      src={
                                        require("assets/icons/jobs/ass_tot.png")
                                          ?.default
                                      }
                                      width={20}
                                      height={20}
                                    />
                                    <Typography
                                      variant={"h6"}
                                      className={s.TableCellTitle}
                                    >
                                      {"Domanda per assunzioni"}
                                    </Typography>
                                  </div>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={s.TableFirstRow3}>
                                <TableSortLabel
                                  active={ascendant.get("diff") !== null}
                                  className={s.Number}
                                  direction={
                                    ascendant.get("diff") ? "asc" : "desc"
                                  }
                                  onClick={() =>
                                    onSort("diff", ascendant.get("diff"))
                                  }
                                >
                                  <div className={s.TableCellInfo}>
                                    <img
                                      src={
                                        require("assets/icons/jobs/diff.png")
                                          ?.default
                                      }
                                      width={20}
                                      height={20}
                                    />
                                    <Typography
                                      variant={"h6"}
                                      className={s.TableCellTitle}
                                    >
                                      {"Difficoltà di reperimento (%)"}
                                    </Typography>
                                  </div>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={s.TableFirstRow4}>
                                <TableSortLabel
                                  active={
                                    ascendant.get("scuola_obbligo") !== null
                                  }
                                  className={s.Number}
                                  direction={
                                    ascendant.get("scuola_obbligo")
                                      ? "asc"
                                      : "desc"
                                  }
                                  onClick={() =>
                                    onSort(
                                      "scuola_obbligo",
                                      ascendant.get("scuola_obbligo"),
                                    )
                                  }
                                >
                                  <div className={s.TableCellInfo}>
                                    <img
                                      src={
                                        require("assets/icons/jobs/scuola.png")
                                          ?.default
                                      }
                                      width={20}
                                      height={20}
                                    />
                                    <Typography
                                      variant={"h6"}
                                      className={s.TableCellTitle}
                                    >
                                      {"Titolo di studio (%)"}
                                    </Typography>
                                  </div>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={s.TableFirstRow5}>
                                <TableSortLabel
                                  active={ascendant.get("fino29anni") !== null}
                                  className={s.Number}
                                  direction={
                                    ascendant.get("fino29anni") ? "asc" : "desc"
                                  }
                                  onClick={() =>
                                    onSort(
                                      "fino29anni",
                                      ascendant.get("fino29anni"),
                                    )
                                  }
                                >
                                  <div className={s.TableCellInfo}>
                                    <img
                                      src={
                                        require("assets/icons/jobs/age.png")
                                          ?.default
                                      }
                                      width={20}
                                      height={20}
                                    />
                                    <Typography
                                      variant={"h6"}
                                      className={s.TableCellTitle}
                                    >
                                      {"Età (%)"}
                                    </Typography>
                                  </div>
                                </TableSortLabel>
                              </TableCell>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Ridotto numero di candidati"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Inadeguatezza dei candidati"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Altro"}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Scuola dell'obbligo"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Diploma professionale"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Diploma superiore"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_4}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Laurea"}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Giovani, meno di 29 anni"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Minimo 30 anni"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.Spot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Età indifferente"}
                                    </Typography>
                                  </div>
                                </TableCell>
                              </TableRow>
                              {jobsList.map(
                                (job, index) =>
                                  index >= page * LIMIT &&
                                  index <= page * LIMIT + LIMIT - 1 && (
                                    <TableRow key={index}>
                                      <TableCell className={s.LabelCell}>
                                        <Link
                                          className={s.Link}
                                          href={`#/professioni/${jobsParams.get(
                                            "regionId",
                                          )}/${job.get("jobId")}`}
                                          underline={"none"}
                                        >
                                          <Typography
                                            color="primary"
                                            variant={"h4"}
                                          >
                                            {jobs[job.get("jobId")]} (
                                            {formatDigit(job.get("jobId"))})
                                          </Typography>
                                        </Link>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography
                                          variant={"h6"}
                                          className={s.Number}
                                        >
                                          {formatNumber(job.get("ass_tot"))}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <div className={sa.DF}>
                                          <Typography
                                            className={s.DiffTypography}
                                            variant={"h6"}
                                          >
                                            {100 -
                                              (job.get("diff") * 100).toFixed(
                                                0,
                                              )}{" "}
                                            %
                                          </Typography>
                                          <div className={s.DiffChart}>
                                            <HighchartsReact
                                              highcharts={Highcharts}
                                              options={{
                                                credits: {
                                                  enabled: false,
                                                },
                                                chart: {
                                                  type: "bar",
                                                  height: 50,
                                                  margin: [10, 0, 10, 0],
                                                },
                                                title:
                                                  "Difficoltà di reperimento (%)",
                                                xAxis: {
                                                  categories: [""],
                                                  lineColor: "transparent",
                                                },
                                                yAxis: {
                                                  min: 0,
                                                  max: 100,
                                                  visible: false,
                                                },
                                                plotOptions: {
                                                  series: {
                                                    stacking: "normal",
                                                  },
                                                },
                                                legend: {
                                                  enabled: false,
                                                },
                                                series: [
                                                  {
                                                    name: "Altro",
                                                    data: [
                                                      parseFloat(
                                                        (
                                                          (job.get(
                                                            "diff_altro",
                                                          ) /
                                                            job.get("diff")) *
                                                          100
                                                        ).toFixed(0),
                                                      ),
                                                    ],
                                                    color: chartColors.chart_3,
                                                  },
                                                  {
                                                    name: "Inad. candidati",
                                                    data: [
                                                      parseFloat(
                                                        (
                                                          (job.get(
                                                            "inadeg_candidati",
                                                          ) /
                                                            job.get("diff")) *
                                                          100
                                                        ).toFixed(0),
                                                      ),
                                                    ],
                                                    color: chartColors.chart_2,
                                                  },
                                                  {
                                                    name: "Rid. num. candidati",
                                                    data: [
                                                      parseFloat(
                                                        (
                                                          (job.get(
                                                            "ridotti_candidati",
                                                          ) /
                                                            job.get("diff")) *
                                                          100
                                                        ).toFixed(0),
                                                      ),
                                                    ],
                                                    color: chartColors.chart_1,
                                                  },
                                                ],
                                                tooltip: {
                                                  outside: true,
                                                  formatter() {
                                                    return `<tspan style="fill:${
                                                      this.color
                                                    }" x="8" dy="15">●</tspan> ${
                                                      this.series.name +
                                                      ": " +
                                                      parseFloat(
                                                        (
                                                          this.y *
                                                          job.get("diff")
                                                        ).toFixed(0),
                                                      ) +
                                                      "%"
                                                    }`;
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={{
                                            credits: {
                                              enabled: false,
                                            },
                                            chart: {
                                              type: "bar",
                                              height: 50,
                                              margin: [10, 0, 10, 0],
                                            },
                                            title: "Titolo di studio (%)",
                                            xAxis: {
                                              categories: [""],
                                              visible: false,
                                            },
                                            yAxis: {
                                              min: 0,
                                              max: 100,
                                              visible: false,
                                            },
                                            plotOptions: {
                                              series: {
                                                stacking: "normal",
                                              },
                                            },
                                            legend: {
                                              enabled: false,
                                            },
                                            series: [
                                              {
                                                name: "Ed. univers.",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("ed_univers") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_4,
                                              },
                                              {
                                                name: "Dipl. prof.",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("diploma_prof") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_3,
                                              },
                                              {
                                                name: "Dipl. sup.",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("diploma_sup") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_2,
                                              },
                                              {
                                                name: "Scuola obbligo",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get(
                                                        "scuola_obbligo",
                                                      ) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_1,
                                              },
                                            ],
                                            tooltip: {
                                              outside: true,
                                              style: {
                                                fontSize: 10,
                                              },
                                              formatter() {
                                                return `<tspan style="fill:${
                                                  this.color
                                                }" x="8" dy="15">●</tspan> ${
                                                  this.series.name +
                                                  ": " +
                                                  this.y +
                                                  "%"
                                                }`;
                                              },
                                            },
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={{
                                            credits: {
                                              enabled: false,
                                            },
                                            chart: {
                                              type: "bar",
                                              height: 50,
                                              margin: [10, 0, 10, 0],
                                            },
                                            title: "Età (%)",
                                            xAxis: {
                                              categories: [""],
                                              visible: false,
                                            },
                                            yAxis: {
                                              min: 0,
                                              max: 100,
                                              visible: false,
                                            },
                                            plotOptions: {
                                              series: {
                                                stacking: "normal",
                                              },
                                            },
                                            legend: {
                                              enabled: false,
                                            },
                                            series: [
                                              {
                                                name: "Età indifferente",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("eta_nonrilev") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_3,
                                              },
                                              {
                                                name: "> o = 30",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("da30oltre") * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_2,
                                              },
                                              {
                                                name:
                                                  "Giovani, meno di 29 anni",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("fino29anni") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_1,
                                              },
                                            ],
                                            tooltip: {
                                              outside: true,
                                              style: {
                                                fontSize: 10,
                                              },
                                              formatter() {
                                                return `<tspan style="fill:${
                                                  this.color
                                                }" x="8" dy="15">●</tspan> ${
                                                  this.series.name +
                                                  ": " +
                                                  this.y +
                                                  "%"
                                                }`;
                                              },
                                            },
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ),
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow className={s.AccordionTableRow}>
                                <TablePagination
                                  labelDisplayedRows={({ from, to }) =>
                                    `${from}-${to} di ${count}`
                                  }
                                  rowsPerPageOptions={[LIMIT]}
                                  count={count}
                                  rowsPerPage={LIMIT}
                                  page={page}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  onChangePage={(ev, page) => setPage(page)}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </Fragment>
                      ) : !jobsLoading && jobsList.size !== 0 ? (
                        jobsList.size > 1 ||
                        jobsParams.get("regionId") === "999" ? (
                          <Fragment>
                            <div className={s.Row}>
                              <div className={s.ResultColumnJob}>
                                {
                                  <div className={s.ResultRowTitleJob}>
                                    <Tooltip
                                      title={
                                        <Typography
                                          variant="h4"
                                          color="inherit"
                                        >
                                          Attivazioni di contratti previsti
                                          dalle imprese per l’anno 2019.
                                        </Typography>
                                      }
                                    >
                                      <Typography
                                        variant="h3"
                                        className={s.TypographyH3}
                                      >
                                        {"Domanda per assunzioni"}
                                      </Typography>
                                    </Tooltip>
                                  </div>
                                }
                                <div className={s.ResultRowJob}>
                                  <div className={s.Label}>
                                    <Typography
                                      variant="caption"
                                      className={s.LabelText}
                                    >
                                      {`${
                                        !selectedRegion
                                          ? `${regions[999]}`
                                          : jobsList.size > 1
                                          ? `${regions[selectedRegion || 999]}`
                                          : `${regions[999]}`
                                      }`}
                                    </Typography>
                                  </div>
                                  <div className={s.Value}>
                                    {!jobsLoading ? (
                                      jobsList.getIn([0, "ass_tot"]) ? (
                                        <Typography
                                          className={s.ValueTypographyH3}
                                          variant="h3"
                                        >
                                          {`${formatNumber(
                                            Math.floor(
                                              jobsList.getIn([0, "ass_tot"]) /
                                                10,
                                            ) * 10,
                                          )}`}
                                        </Typography>
                                      ) : (
                                        <Typography>Nessun dato</Typography>
                                      )
                                    ) : (
                                      <LinearProgress className={s.Progress} />
                                    )}
                                  </div>
                                </div>

                                {selectedRegion && jobsList.size > 1 && (
                                  <div className={s.ResultRowJob}>
                                    <div className={s.Label}>
                                      <Typography
                                        variant="caption"
                                        className={s.LabelText}
                                      >
                                        {`${regions[999]}`}
                                      </Typography>
                                    </div>
                                    <div className={s.Value}>
                                      {!jobsLoading ? (
                                        jobsList.getIn([1, "ass_tot"]) ? (
                                          <Typography
                                            className={s.ValueTypographyH3}
                                            variant="h3"
                                          >
                                            {`${formatNumber(
                                              Math.floor(
                                                jobsList.getIn([1, "ass_tot"]) /
                                                  10,
                                              ) * 10,
                                            )}`}
                                          </Typography>
                                        ) : (
                                          <Typography>Nessun dato</Typography>
                                        )
                                      ) : (
                                        <LinearProgress
                                          className={s.Progress}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className={s.ResultColumn}>
                                <div className={s.ResultColumnTitle}>
                                  <Tooltip
                                    title={
                                      <Typography variant="h4" color="inherit">
                                        È una dichiarazione delle imprese sulla
                                        difficoltà nel reperire, nel territorio
                                        in cui operano, candidati idonei a
                                        ricoprire la figura professionale
                                        ricercata. Le difficoltà sono
                                        sintetizzate in due grandi motivazioni
                                        (ridotto numero di candidati o
                                        inadeguatezza dei candidati), cui si
                                        aggiunge una modalità &quot;altro&quot;,
                                        eventualmente da specificare.
                                      </Typography>
                                    }
                                  >
                                    <Typography
                                      className={s.TypographyH3}
                                      variant="h3"
                                    >
                                      {"Difficoltà di reperimento (%)"}
                                    </Typography>
                                  </Tooltip>
                                  <div className={s.LegendContainer}>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_3}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Ridotto numero di candidati"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_2}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Inadeguatezza dei candidati"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_1}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Altro"}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className={s.ResultColumnCells}>
                                  <div className={s.Cell}>
                                    <div className={s.Chart}>
                                      <div className={s.ChartInfo}>
                                        <Typography
                                          className={s.ChartLabel}
                                          variant="caption"
                                        >
                                          {`${
                                            !selectedRegion
                                              ? `${regions[999]}`
                                              : jobsList.size > 1
                                              ? `${
                                                  regions[selectedRegion || 999]
                                                }`
                                              : `${regions[999]}`
                                          }`}
                                        </Typography>

                                        <Typography
                                          className={s.ChartTotal}
                                          variant="h3"
                                        >
                                          {!jobsLoading ? (
                                            `${(
                                              100 -
                                              (jobsList.getIn([0, "diff"]) ??
                                                0) *
                                                100
                                            ).toFixed(0)} %`
                                          ) : (
                                            <LinearProgress
                                              className={s.Progress}
                                            />
                                          )}
                                        </Typography>
                                      </div>
                                      {renderStackedChart({
                                        value:
                                          100 -
                                          (jobsList.getIn([0, "diff"]) ?? 0),
                                        height: 50,
                                        categories: [
                                          `${(
                                            100 -
                                            (jobsList.getIn([0, "diff"]) ?? 0) *
                                              100
                                          ).toFixed(0)} %`,
                                        ],
                                        series: [
                                          {
                                            _id: "main",
                                            name: "Altro",
                                            data: [
                                              parseFloat(
                                                (
                                                  jobsList.getIn([
                                                    0,
                                                    "diff_altro",
                                                  ]) * 100
                                                ).toFixed(0),
                                              ),
                                            ],
                                            color: {
                                              linearGradient: {
                                                x1: 0,
                                                y1: 1,
                                                x2: 0,
                                                y2: 0,
                                              },
                                              stops: [
                                                [0, chartColors.chart_1],
                                                [1, "rgba(69, 157, 219, 0.8)"],
                                              ],
                                            },
                                          },
                                          {
                                            _id: "main",
                                            name: "Inadeguatezza dei candidati",
                                            data: [
                                              parseFloat(
                                                (
                                                  jobsList.getIn([
                                                    0,
                                                    "inadeg_candidati",
                                                  ]) * 100
                                                ).toFixed(0),
                                              ),
                                            ],
                                            color: {
                                              linearGradient: {
                                                x1: 0,
                                                y1: 1,
                                                x2: 0,
                                                y2: 0,
                                              },
                                              stops: [
                                                [0, chartColors.chart_2],
                                                [1, "rgba(245, 185, 66, 0.8)"],
                                              ],
                                            },
                                          },
                                          {
                                            _id: "main",
                                            name: "Ridotto numero di candidati",
                                            data: [
                                              parseFloat(
                                                (
                                                  jobsList.getIn([
                                                    0,
                                                    "ridotti_candidati",
                                                  ]) * 100
                                                ).toFixed(0),
                                              ),
                                            ],
                                            color: {
                                              linearGradient: {
                                                x1: 0,
                                                y1: 1,
                                                x2: 0,
                                                y2: 0,
                                              },
                                              stops: [
                                                [0, chartColors.chart_3],
                                                [1, "rgba(187, 46, 61, 0.8)"],
                                              ],
                                            },
                                          },
                                        ],
                                      })}
                                    </div>
                                  </div>
                                </div>
                                {selectedRegion && jobsList.size > 1 && (
                                  <div className={s.ResultColumnCells}>
                                    <div className={s.Cell}>
                                      <div className={s.Chart}>
                                        <div className={s.ChartInfo}>
                                          <Typography
                                            className={s.ChartLabel}
                                            variant="caption"
                                          >
                                            {regions[999]}
                                          </Typography>

                                          <Typography
                                            className={s.ChartTotal}
                                            variant="h3"
                                          >
                                            {!jobsLoading ? (
                                              jobsList.getIn([1, "diff"]) ? (
                                                `${(
                                                  100 -
                                                  jobsList.getIn([1, "diff"]) *
                                                    100
                                                ).toFixed(0)} %`
                                              ) : (
                                                "0 %"
                                              )
                                            ) : (
                                              <LinearProgress
                                                className={s.Progress}
                                              />
                                            )}
                                          </Typography>
                                        </div>
                                        {renderStackedChart({
                                          value: jobsList.getIn([1, "diff"]),
                                          height: 50,
                                          categories: [
                                            `${(
                                              jobsList.getIn([1, "diff"]) * 100
                                            ).toFixed(0)} %`,
                                          ],
                                          series: [
                                            {
                                              _id: "main",
                                              name: "Altro",
                                              data: [
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      1,
                                                      "diff_altro",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              color: {
                                                linearGradient: {
                                                  x1: 0,
                                                  y1: 1,
                                                  x2: 0,
                                                  y2: 0,
                                                },
                                                stops: [
                                                  [0, chartColors.chart_1],
                                                  [
                                                    1,
                                                    "rgba(69, 157, 219, 0.8)",
                                                  ],
                                                ],
                                              },
                                            },
                                            {
                                              _id: "main",
                                              name:
                                                "Inadeguatezza dei candidati",
                                              data: [
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      1,
                                                      "inadeg_candidati",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              color: {
                                                linearGradient: {
                                                  x1: 0,
                                                  y1: 1,
                                                  x2: 0,
                                                  y2: 0,
                                                },
                                                stops: [
                                                  [0, chartColors.chart_2],
                                                  [
                                                    1,
                                                    "rgba(245, 185, 66, 0.8)",
                                                  ],
                                                ],
                                              },
                                            },
                                            {
                                              _id: "main",
                                              name:
                                                "Ridotto numero di candidati",
                                              data: [
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      1,
                                                      "ridotti_candidati",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              color: {
                                                linearGradient: {
                                                  x1: 0,
                                                  y1: 1,
                                                  x2: 0,
                                                  y2: 0,
                                                },
                                                stops: [
                                                  [0, chartColors.chart_3],
                                                  [1, "rgba(187, 46, 61, 0.8)"],
                                                ],
                                              },
                                            },
                                          ],
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={s.Row}>
                              <div className={s.ResultColumn}>
                                <div className={s.ResultColumnTitle}>
                                  <Typography
                                    className={s.TypographyH3}
                                    variant="h3"
                                  >
                                    {"Titolo di studio (%)"}
                                  </Typography>
                                  <div className={s.LegendContainer}>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_1}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Scuola dell'obbligo"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_3}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Diploma professionale"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_2}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Diploma superiore"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_4}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Laurea"}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className={cn(s.ResultColumnCells, s.Pie)}>
                                  <div className={s.Cell}>
                                    <div className={s.Chart}>
                                      {renderPieChart({
                                        width: 200,
                                        height: 200,
                                        title:
                                          selectedRegion !==
                                          jobsList.getIn([0, "regionId"])
                                            ? `${regions[999]}`
                                            : `${
                                                regions[selectedRegion || 999]
                                              }`,
                                        series: [
                                          {
                                            type: "pie",
                                            name: "Titolo di studio (%)",
                                            colors: [
                                              chartColors.chart_1,
                                              chartColors.chart_2,
                                              chartColors.chart_3,
                                              chartColors.chart_4,
                                            ],
                                            data: [
                                              jobsList.getIn([
                                                0,
                                                "scuola_obbligo",
                                              ]) && [
                                                "Scuola dell'obbligo",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "scuola_obbligo",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              jobsList.getIn([
                                                0,
                                                "diploma_sup",
                                              ]) && [
                                                "Diploma superiore",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "diploma_sup",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              jobsList.getIn([
                                                0,
                                                "diploma_prof",
                                              ]) && [
                                                "Diploma professionale",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "diploma_prof",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              jobsList.getIn([
                                                0,
                                                "ed_univers",
                                              ]) && [
                                                "Laurea",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "ed_univers",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                            ],
                                          },
                                        ],
                                      })}
                                    </div>
                                  </div>
                                  {jobsList.size > 1 && (
                                    <div className={s.Cell}>
                                      <div className={s.Chart}>
                                        {renderPieChart({
                                          width: 200,
                                          height: 200,
                                          title: regions[999],
                                          series: [
                                            {
                                              type: "pie",
                                              name: "Titolo di studio (%)",
                                              colors: [
                                                chartColors.chart_1,
                                                chartColors.chart_2,
                                                chartColors.chart_3,
                                                chartColors.chart_4,
                                              ],
                                              data: [
                                                jobsList.getIn([
                                                  1,
                                                  "scuola_obbligo",
                                                ]) && [
                                                  "Scuola dell'obbligo",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "scuola_obbligo",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                jobsList.getIn([
                                                  1,
                                                  "diploma_sup",
                                                ]) && [
                                                  "Diploma superiore",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "diploma_sup",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                jobsList.getIn([
                                                  1,
                                                  "diploma_prof",
                                                ]) && [
                                                  "Diploma professionale",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "diploma_prof",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                jobsList.getIn([
                                                  1,
                                                  "ed_univers",
                                                ]) && [
                                                  "Laurea",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "ed_univers",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                              ],
                                            },
                                          ],
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className={s.ResultColumn}>
                                <div className={s.ResultColumnTitle}>
                                  <Typography
                                    className={s.TypographyH3}
                                    variant="h3"
                                  >
                                    {"Età (%)"}
                                  </Typography>
                                  <div className={s.LegendContainer}>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_1}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Giovani, meno di 29 anni"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_2}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Minimo 30 anni"}
                                      </Typography>
                                    </div>
                                    <div className={s.Legend}>
                                      <Spot
                                        className={s.LegendSpot}
                                        color={chartColors.chart_3}
                                        size={10}
                                      />
                                      <Typography variant={"caption"}>
                                        {"Età indifferente"}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>

                                <div className={cn(s.ResultColumnCells, s.Pie)}>
                                  <div className={s.Cell}>
                                    <div className={s.Chart}>
                                      {renderPieChart({
                                        width: 200,
                                        height: 200,
                                        title:
                                          selectedRegion !==
                                          jobsList.getIn([0, "regionId"])
                                            ? `${regions[999]}`
                                            : `${
                                                regions[selectedRegion || 999]
                                              }`,
                                        series: [
                                          {
                                            type: "pie",
                                            name: "Età (%)",
                                            colors: [
                                              chartColors.chart_1,
                                              chartColors.chart_2,
                                              chartColors.chart_3,
                                            ],
                                            data: [
                                              jobsList.getIn([
                                                0,
                                                "fino29anni",
                                              ]) && [
                                                "Giovani, meno di 29 anni",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "fino29anni",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              jobsList.getIn([
                                                0,
                                                "da30oltre",
                                              ]) && [
                                                "Minimo 30 anni",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "da30oltre",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                              jobsList.getIn([
                                                0,
                                                "eta_nonrilev",
                                              ]) && [
                                                "Età indifferente",
                                                parseFloat(
                                                  (
                                                    jobsList.getIn([
                                                      0,
                                                      "eta_nonrilev",
                                                    ]) * 100
                                                  ).toFixed(0),
                                                ),
                                              ],
                                            ],
                                          },
                                        ],
                                      })}
                                    </div>
                                  </div>
                                  {jobsList.size > 1 && (
                                    <div className={s.Cell}>
                                      <div className={s.Chart}>
                                        {renderPieChart({
                                          width: 200,
                                          height: 200,
                                          title: regions[999],
                                          series: [
                                            {
                                              type: "pie",
                                              name: "Età (%)",
                                              colors: [
                                                chartColors.chart_1,
                                                chartColors.chart_2,
                                                chartColors.chart_3,
                                              ],
                                              data: [
                                                jobsList.getIn([
                                                  1,
                                                  "fino29anni",
                                                ]) && [
                                                  "Giovani, meno di 29 anni",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "fino29anni",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                jobsList.getIn([
                                                  1,
                                                  "da30oltre",
                                                ]) && [
                                                  "Minimo 30 anni",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "da30oltre",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                jobsList.getIn([
                                                  1,
                                                  "eta_nonrilev",
                                                ]) && [
                                                  "Età indifferente",
                                                  parseFloat(
                                                    (
                                                      jobsList.getIn([
                                                        1,
                                                        "eta_nonrilev",
                                                      ]) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                              ],
                                            },
                                          ],
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Typography variant="subtitle1">
                            Nessun dato coi parametri inseriti.
                            <p>
                              {
                                "Prova a cercare in un'altra regione o una diversa professione."
                              }
                            </p>
                          </Typography>
                        )
                      ) : !jobsLoading && jobsList.size === 0 ? (
                        <Typography variant="subtitle1" className={s.Info}>
                          {jobsParams.get("regionId") === "999" ? (
                            "Dati non disponibili per questa professione"
                          ) : (
                            <Fragment>
                              Il dato non è disponibile per questa categoria
                              professionale in{" "}
                              {regions[jobsParams.get("regionId")]}.
                              <br />
                              Consulta i risultati per{" "}
                              <Link
                                color={"primary"}
                                classes={{
                                  root: classes.linkRoot,
                                }}
                                onClick={() =>
                                  (window.location.href = `#/professioni/999/${jobsParams.get(
                                    "jobId",
                                  )}/${jobsParams.get("index") || 0}`)
                                }
                              >
                                l’Italia
                              </Link>
                            </Fragment>
                          )}
                        </Typography>
                      ) : (
                        <CircularProgress
                          className={s.CircularProgress}
                          color="primary"
                          size={100}
                          thickness={2}
                        />
                      )}
                      {jobsParams.get("jobId") === "tutte" && (
                        <div className={s.AccordionContainer}>
                          <div className={s.OrderRow}>
                            <Fab
                              color={
                                ascendant.get("jobName") === null
                                  ? "default"
                                  : "primary"
                              }
                              size="small"
                              className={s.OrderFab}
                              onClick={() => onSort("jobName")}
                            >
                              {" "}
                              {ascendant.get("jobName") ||
                              ascendant.get("jobName") === null
                                ? "A-Z"
                                : "Z-A"}
                            </Fab>
                          </div>
                          {jobsList.map((job, index) => (
                            <ExpansionPanel
                              key={job.get("jobGroupId")}
                              className={s.ExpansionPanel}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                className={
                                  index % 2 === 0
                                    ? s.ExpansionPanelSummaryFill
                                    : ""
                                }
                              >
                                <Link
                                  href={`#/professioni/${jobsParams.get(
                                    "regionId",
                                  )}/${job.get("jobId")}`}
                                  underline={"none"}
                                >
                                  {jobs[job.get("jobId")]} (
                                  {formatDigit(job.get("jobId"))})
                                </Link>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails
                                className={s.ExpansionPanelDetails}
                              >
                                <Table className={s.AccordionTable}>
                                  <TableBody>
                                    <TableRow className={s.AccordionTableRow}>
                                      <TableCell
                                        className={s.AccordionTableCell}
                                      >
                                        <Tooltip
                                          title={
                                            <Typography
                                              variant="h4"
                                              color="inherit"
                                            >
                                              Attivazioni di contratti previsti
                                              dalle imprese per l’anno 2019.
                                            </Typography>
                                          }
                                        >
                                          <Typography
                                            variant={"h6"}
                                            className={s.TableCellTitle}
                                          >
                                            Domanda per assunzioni{" "}
                                            {formatNumber(job.get("ass_tot"))}
                                          </Typography>
                                        </Tooltip>
                                        <Divider className={s.Divider} />
                                        <Typography
                                          variant={"h6"}
                                          className={s.TableCellTitle}
                                        >
                                          Difficoltà di reperimento{" "}
                                          {(
                                            100 -
                                            job.get("diff") * 100
                                          ).toFixed(0)}{" "}
                                          %
                                        </Typography>
                                        <div className={s.LegendContainer}>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_1}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              {"Ridotto numero di candidati"}
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_2}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Inadeguatezza dei candidati
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_3}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Altro
                                            </Typography>
                                          </div>
                                        </div>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={{
                                            credits: {
                                              enabled: false,
                                            },
                                            chart: {
                                              type: "bar",
                                              height: 50,
                                            },
                                            title:
                                              "Difficoltà di reperimento (%)",
                                            xAxis: {
                                              categories: [""],
                                              visible: false,
                                            },
                                            yAxis: {
                                              min: 0,
                                              max: 100,
                                              visible: false,
                                            },
                                            plotOptions: {
                                              series: {
                                                stacking: "normal",
                                              },
                                            },
                                            legend: {
                                              enabled: false,
                                            },
                                            series: [
                                              {
                                                name: "Altro",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      (job.get("diff_altro") /
                                                        job.get("diff")) *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_3,
                                              },
                                              {
                                                name: "Inad. candidati",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      (job.get(
                                                        "inadeg_candidati",
                                                      ) /
                                                        job.get("diff")) *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_2,
                                              },
                                              {
                                                name: "Rid. num. candidati",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      (job.get(
                                                        "ridotti_candidati",
                                                      ) /
                                                        job.get("diff")) *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_1,
                                              },
                                            ],
                                            tooltip: {
                                              outside: true,
                                              formatter() {
                                                return `<tspan style="fill:${
                                                  this.color
                                                }" x="8" dy="15">●</tspan> ${
                                                  this.series.name +
                                                  ": " +
                                                  parseFloat(
                                                    (
                                                      this.y * job.get("diff")
                                                    ).toFixed(0),
                                                  ) +
                                                  "%"
                                                }`;
                                              },
                                            },
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className={s.AccordionTableRow}>
                                      <TableCell
                                        className={s.AccordionTableCell}
                                      >
                                        <Typography
                                          variant={"h6"}
                                          className={s.TableCellTitle}
                                        >
                                          Titolo di studio (%)
                                        </Typography>
                                        <div className={s.LegendContainer}>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_1}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              {"Scuola dell'obbligo"}
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_3}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Diploma professionale
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_2}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Diploma superiore
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_4}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Laurea
                                            </Typography>
                                          </div>
                                        </div>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={{
                                            credits: {
                                              enabled: false,
                                            },
                                            chart: {
                                              type: "bar",
                                              height: 50,
                                            },
                                            title: "Titolo di studio (%)",
                                            xAxis: {
                                              categories: [""],
                                              visible: false,
                                            },
                                            yAxis: {
                                              min: 0,
                                              max: 100,
                                              visible: false,
                                            },
                                            plotOptions: {
                                              series: {
                                                stacking: "normal",
                                              },
                                            },
                                            legend: {
                                              enabled: false,
                                            },
                                            series: [
                                              {
                                                name: "Laurea",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("ed_univers") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_4,
                                              },
                                              {
                                                name: "Diploma professionale",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("diploma_prof") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_3,
                                              },
                                              {
                                                name: "Diploma superiore",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("diploma_sup") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_2,
                                              },
                                              {
                                                name: "Scuola dell'obbligo",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get(
                                                        "scuola_obbligo",
                                                      ) * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_1,
                                              },
                                            ],
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className={s.AccordionTableRow}>
                                      <TableCell
                                        className={s.AccordionTableCell}
                                      >
                                        <Typography
                                          variant={"h6"}
                                          className={s.TableCellTitle}
                                        >
                                          Età (%)
                                        </Typography>
                                        <div className={s.LegendContainer}>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_1}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              {"Giovani, meno di 29 anni"}
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_2}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              {"Minimo 30 anni"}
                                            </Typography>
                                          </div>
                                          <div className={s.Legend}>
                                            <Spot
                                              className={s.LegendSpot}
                                              color={chartColors.chart_3}
                                              size={10}
                                            />
                                            <Typography
                                              className={s.LegendTypography}
                                              variant={"caption"}
                                            >
                                              Età indifferente
                                            </Typography>
                                          </div>
                                        </div>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={{
                                            credits: {
                                              enabled: false,
                                            },
                                            chart: {
                                              type: "bar",
                                              height: 50,
                                            },
                                            title: "Età (%)",
                                            xAxis: {
                                              categories: [""],
                                              visible: false,
                                            },
                                            yAxis: {
                                              min: 0,
                                              max: 100,
                                              visible: false,
                                            },
                                            plotOptions: {
                                              series: {
                                                stacking: "normal",
                                              },
                                            },
                                            legend: {
                                              enabled: false,
                                            },
                                            series: [
                                              {
                                                name: "Età indifferente",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("eta_nonrilev") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_3,
                                              },
                                              {
                                                name: "Minimo 30 anni",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("da30oltre") * 100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_2,
                                              },
                                              {
                                                name:
                                                  "Giovani, meno di 29 anni",
                                                data: [
                                                  parseFloat(
                                                    (
                                                      job.get("fino29anni") *
                                                      100
                                                    ).toFixed(0),
                                                  ),
                                                ],
                                                color: chartColors.chart_1,
                                              },
                                            ],
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          ))}
                          {jobsList.size !== count && (
                            <Button
                              className={cn(sa.W100, sa.MT20)}
                              variant="outlined"
                              color="primary"
                              onClick={() => setPage(page + 1)}
                            >
                              Carica altri risultati
                            </Button>
                          )}
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
}

JobsResults.propTypes = {
  ascendant: object,
  classes: object,
  clearList: func,
  count: number,
  getJobsAjax: func,
  getSkillsByJobIdAjax: func,
  jobsList: object,
  jobsLoading: bool,
  jobsParams: object,
  page: number,
  restUri: string,
  setAscendant: func,
  setPage: func,
  skillsList: object,
  t: func,
};

const mapStateToProps = (state) => ({
  ascendant: getAscendant(state),
  jobsList: getJobsList(state),
  jobsLoading: getAjaxLoading(state).get("GET_JOBS_1"),
  jobsParams: getJobsParams(state),
  restUri: getRestUri(state),
  page: getPage(state),
  count: getCount(state),
  skillsList: getSkillsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearList,
      getJobsAjax,
      setAscendant,
      setPage,
      getSkillsByJobIdAjax,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(JobsResults)));
