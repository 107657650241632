// CONSTANTS
import SkillsList from "../skills-list";

const skillsLabels = require("constants/skills-labels");
const skillsDescriptions = require("constants/skills-descriptions");

// REACT
import React, { useState } from "react";
import { func, array, string, object, bool } from "prop-types";
import { Trans, translate } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// STYLE
import s from "./style.module.scss";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import sg from "css/style.module.scss";
import cn from "classnames";
import { HelpOutline as HelpIcon } from "@material-ui/icons";

const styles = (theme) => ({
  SkillSvg: {
    fill: theme.palette.primary.main,
  },
});

function SkillsTooltip({ classes, className, selectedSkills, showTitle }) {
  return (
    <ExpansionPanel className={cn(className, s.ExpansionPanel)}>
      <ExpansionPanelSummary
        color={"primary"}
        className={s.ExpansionPanelSummary}
        expandIcon={<ExpandMoreIcon color={"primary"} />}
      >
        <div className={s.SkillSvgContainer}>
          <Tooltip
            title={
              <Typography variant="h4" color="inherit">
                Il pannello «Scopri le competenze collegate» mostra la
                conoscenza, la skill e l’attitudine piu’ importanti per la
                professione selezionata
              </Typography>
            }
          >
            <Typography color="primary" className={sg.NBold}>
              {"Scopri le competenze collegate"}
            </Typography>
          </Tooltip>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={s.ExpansionPanelDetails}>
        <SkillsList
          selectedSkills={selectedSkills}
          showTitle={showTitle}
          showLink
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

SkillsTooltip.propTypes = {
  className: string,
  classes: object,
  selectedSkills: array,
  showTitle: bool,
  t: func,
};

export default withStyles(styles)(translate()(SkillsTooltip));
