// CONSTANTS

// REACT
import { object } from "prop-types";
import React, { Fragment, useMemo, useState } from "react";
import { translate } from "react-i18next";
import Autocomplete from "components/autocomplete";
import ItalyMap from "components/italy-map";
import {
  Fab,
  OutlinedInput,
  FormControl,
  InputLabel,
  Link,
  Paper,
  Select,
  Typography,
  withStyles,
  Button,
  MuiThemeProvider,
} from "@material-ui/core";
import PageToolbar from "components/page-toolbar";
import { SearchOutlined as SearchIcon } from "@material-ui/icons";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// STYLE
import s from "./style.module.scss";
import sa from "css/atomic.module.scss";
import { common } from "styles/mui-themes";

const jobsLabelsExtended = require("constants/jobs-labels-extended");
const regions = require("constants/regions-labels");
const strings = require("constants/strings");

const styles = (theme) => ({
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
});

function JobsSearch({ classes }) {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);

  const jobsLabels = useMemo(() => {
    const jobsLabels2 = [];
    Object.keys(jobsLabelsExtended).map((key) => {
      jobsLabelsExtended[key].values.map((value, index) => {
        jobsLabels2.push({
          value: `${key}:${index}`,
          label: value.label,
        });
      });
    });
    return jobsLabels2;
  }, []);

  const selectedJobParts = (selectedJob && selectedJob.split(":")) || [];

  return (
    <div className={s.Container}>
      <PageToolbar
        title={
          "Scopri come una professione si inserisce nel mercato del lavoro"
        }
        tooltipText={
          "I risultati di questa pagina provengono dal Sistema informativo Excelsior. Questo mette a disposizione dati, approfondimenti e altri strumenti informativi sulla domanda di professioni e competenze delle imprese italiane e sull’individuazione delle principali aree di mismatch tra domanda e offerta di lavoro e le possibili misure di intervento atte a colmarlo, nonché guide, manuali e altri strumenti informativi di sistema a supporto dell’orientamento scolastico, universitario e lavorativo. L’indagine valorizza in modo integrato le informazioni desunte dagli archivi amministrativi camerali su imprese e occupazione e coinvolge annualmente un campione di oltre 500.000 imprese di tutti i settori economici e di tutte le dimensioni. L’elevato numero di interviste e la metodologia adottata per l’elaborazione dei dati consentono di ottenere dati previsionali mensili e annuali sui fabbisogni professionali richiesti dalle imprese per tutte le province italiane. "
        }
      />
      <Paper className={s.Paper}>
        <div className={s.Left}>
          <Typography variant={"subtitle1"}>Seleziona una regione</Typography>
          <Typography className={s.CurrentView} variant={"h6"}>
            {"Stai visualizzando:"}
          </Typography>
          {selectedRegion ? (
            <div className={s.Region}>
              <MuiThemeProvider theme={common}>
                <Link
                  color={"secondary"}
                  classes={{
                    root: classes.linkRoot,
                  }}
                  onClick={() => setSelectedRegion(null)}
                  underline={"none"}
                >
                  <Typography color={"inherit"} variant={"h6"}>
                    {"Tutta Italia >"}
                  </Typography>
                </Link>
              </MuiThemeProvider>
              &nbsp;
              <Typography
                classes={{
                  root: classes.regionText,
                }}
              >
                {regions[selectedRegion]}
              </Typography>
            </div>
          ) : (
            <Typography variant={"h6"}>{"Tutta Italia"}</Typography>
          )}
          <MuiThemeProvider theme={common}>
            <ItalyMap
              className={s.Map}
              selected={selectedRegion}
              onRegionClick={setSelectedRegion}
            />
          </MuiThemeProvider>
          <FormControl className={s.RegionSelect} fullWidth variant="filled">
            <Select
              native
              value={selectedRegion || ""}
              onChange={(e) => setSelectedRegion(e.target.value)}
              input={
                <OutlinedInput
                  color="primary"
                  fullWidth
                  name="region"
                  id="region-select"
                />
              }
            >
              <option value="999">{"Seleziona una Regione"}</option>
              {Object.keys(regions).map((regionId) => {
                if (regionId !== "999") {
                  return (
                    <option key={regionId} value={regionId}>
                      {regions[regionId]}
                    </option>
                  );
                }
              })}
            </Select>
          </FormControl>
        </div>
        <div className={s.Right}>
          <Typography variant="subtitle1">Cerca una professione</Typography>
          <MuiThemeProvider theme={common}>
            <Link
              color={"secondary"}
              href="http://professioni.istat.it/cp2011/"
              underline={"none"}
              target="_blank"
            >
              <Typography variant="h4">
                {"Mostra la classificazione completa delle professioni >"}
              </Typography>
            </Link>
          </MuiThemeProvider>
          <div className={s.SearchContainer}>
            <Autocomplete
              className={s.Input}
              suggestions={jobsLabels}
              placeholder={"Medici, Guide turistiche, Professori, ecc..."}
              onChange={setSelectedJob}
              label={"Cerca Professione"}
              onKeyPress={(e) => {
                if (e.key === "Enter" && selectedJob) {
                  window.location.href = `#/professioni/${
                    selectedRegion ||
                    Object.keys(regions).find(
                      (key) => regions[key] === "ITALIA",
                    )
                  }/${selectedJobParts[0]}/${selectedJobParts[1]}`;
                }
              }}
            />
            <Fab
              className={s.SearchFab}
              aria-label="Professioni"
              color="primary"
              disabled={!selectedJob}
              href={`#/professioni/${
                selectedRegion ||
                Object.keys(regions).find((key) => regions[key] === "ITALIA")
              }/${selectedJobParts[0]}/${selectedJobParts[1]}`}
            >
              <SearchIcon className={sa.CW} />
            </Fab>
            <Button
              aria-label="Cerca"
              className={s.SearchButton}
              color="primary"
              disabled={!selectedJob}
              onClick={() =>
                (window.location.href = `#/professioni/${
                  selectedRegion ||
                  Object.keys(regions).find((key) => regions[key] === "ITALIA")
                }/${selectedJobParts[0]}/${selectedJobParts[1]}`)
              }
              variant={"contained"}
            >
              <Typography color="inherit">{"Cerca"}</Typography>
            </Button>
          </div>
          <Typography className={s.Info} variant="body2">
            Scopri statistiche sulle domande di assunzione, difficoltà di
            reperimento, titoli di studio o età
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

JobsSearch.propTypes = {
  classes: object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(JobsSearch)));
