// CONSTANTS
// REACT
import React, { Fragment, useEffect, useState } from "react";
import { array, bool, func, object, string, number } from "prop-types";
import { translate } from "react-i18next";
import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  FormControl,
  Link,
  MuiThemeProvider,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import ItalyMap from "components/italy-map";
import PageToolbar from "components/page-toolbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBack from "components/arrow-back";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCoursesAjax } from "redux/actions/ajax";
import { getRestUri, getAjaxLoading } from "redux/getters/global";
import { getAscendant, getCoursesList } from "redux/getters/courses";
import { getCoursesParams } from "redux/getters/routing";
import { clearList, setAscendant } from "redux/actions";
// STYLE
import s from "./style.module.scss";
import { common } from "styles/mui-themes";
import cn from "classnames";
import sa from "css/atomic.module.scss";
import { getCount } from "redux/getters/courses";
import { formatDigit } from "../../../../services/formatter";

const regions = require("constants/regions-labels");
const jobsLabels = require("constants/jobs-labels");
const jobsLabelsExtended = require("constants/jobs-labels-extended");
const coursesTypesLabels = require("constants/courses-types-labels");

// LIBS

const styles = (theme) => ({
  tableRoot: {
    "& td, th": {
      padding: "15px 8px",
    },
  },
  title: {
    marginTop: 10,
  },
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
});

function CoursesUniversities({
  ascendant,
  clearList,
  count,
  coursesList,
  coursesLoading,
  params,
  restUri,
  getCoursesAjax,
  setAscendant,
  classes,
}) {
  const [page, setPage] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState(
    parseInt(params.get("regionId"), 10) === 999
      ? null
      : parseInt(params.get("regionId"), 10),
  );
  const LIMIT = 7;

  function onSort(sortBy) {
    setPage(0);
    clearList();
    setAscendant(
      sortBy,
      ascendant.get(sortBy)
        ? false
        : ascendant.get(sortBy) !== null
        ? null
        : true,
    );
  }

  useEffect(() => {
    getCoursesAjax(restUri, {
      query: {
        regionId: selectedRegion || 999,
        codtipolau: params.get("codtipolau"),
        jobId: params.get("jobId") !== "null" ? params.get("jobId") : undefined,
        _id: params.get("_id") !== "null" ? params.get("_id") : undefined,
        coursesText:
          params.get("coursesText") !== "null"
            ? params.get("coursesText")
            : undefined,
        skip: page * LIMIT,
        limit: LIMIT,
        sort: ascendant
          .map((item) => (item !== null ? (item ? "ASC" : "DESC") : null))
          .toJS(),
      },
    });
  }, [params, page, ascendant]);

  useEffect(() => {
    window.location.href = `#/percorsi-formativi/atenei/${
      selectedRegion || "999"
    }/${params.get("codtipolau")}/${params.get("jobId")}/${params.get(
      "coursesText",
    )}/${params.get("index") || "0"}`;
  }, [selectedRegion]);

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = "NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.href = "/NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.click();
  };

  return (
    <div className={s.Container}>
      <PageToolbar
        title={"Scopri i Corsi di Laurea a cui puoi iscriverti"}
        tooltipText={"Scarica il PDF con le note alla lettura."}
        downloadFile={onDownload}
      />
      <Paper className={s.Paper}>
        <div className={s.Left}>
          <div className={s.Column}>
            <MuiThemeProvider theme={common}>
              <Link
                color={"secondary"}
                href={"/#/percorsi-formativi/ricerca"}
                underline={"none"}
              >
                <ArrowBack />
                <Typography variant="h4">
                  {"Home Percorsi Formativi"}
                </Typography>
              </Link>
            </MuiThemeProvider>

            <Typography
              classes={{
                root: classes.title,
              }}
              variant="subtitle1"
            >
              {coursesTypesLabels[params.get("codtipolau")]}
            </Typography>
            <Typography
              classes={{
                root: classes.title,
              }}
              variant="subtitle1"
            >
              Seleziona una regione
            </Typography>
            <Typography className={s.CurrentView} variant={"h6"}>
              {"Stai visualizzando:"}
            </Typography>
          </div>
          {selectedRegion ? (
            <div className={s.Region}>
              <MuiThemeProvider theme={common}>
                <Link
                  color={"secondary"}
                  classes={{
                    root: classes.linkRoot,
                  }}
                  onClick={() => setSelectedRegion(null)}
                  underline={"none"}
                >
                  <Typography color={"inherit"} variant={"h6"}>
                    {"Tutta Italia >"}
                  </Typography>
                </Link>
              </MuiThemeProvider>
              &nbsp;
              <Typography
                classes={{
                  root: classes.regionText,
                }}
              >
                {regions[selectedRegion]}
              </Typography>
            </div>
          ) : (
            <Typography variant={"h6"}>{"Tutta Italia"}</Typography>
          )}
          <MuiThemeProvider theme={common}>
            <ItalyMap
              selected={selectedRegion}
              onRegionClick={(selected) => {
                setSelectedRegion(selected);
                setPage(0);
              }}
              className={s.Map}
            />
          </MuiThemeProvider>
          <FormControl className={s.RegionSelect} fullWidth variant="filled">
            <Select
              native
              value={selectedRegion || ""}
              onChange={(e) => setSelectedRegion(e.target.value)}
              input={
                <OutlinedInput
                  color="primary"
                  fullWidth
                  name="region"
                  id="region-select"
                />
              }
            >
              <option value="999">{"Seleziona una Regione"}</option>
              {Object.keys(regions).map((regionId) => {
                if (regionId !== "999") {
                  return (
                    <option key={regionId} value={regionId}>
                      {regions[regionId]}
                    </option>
                  );
                }
              })}
            </Select>
          </FormControl>
        </div>
        {!coursesLoading ? (
          coursesList.size > 0 ? (
            <div className={s.Right}>
              <Typography
                classes={{
                  root: classes.title,
                }}
                variant="subtitle1"
              >
                {jobsLabelsExtended[params.get("jobId")] &&
                jobsLabelsExtended[params.get("jobId")].values[
                  params.get("index")
                ] &&
                !jobsLabelsExtended[params.get("jobId")].values[
                  params.get("index")
                ].show4Digit
                  ? `La professione "${
                      jobsLabelsExtended[params.get("jobId")].values[
                        params.get("index")
                      ].label
                    }" fa parte della categoria professionale "${
                      jobsLabelsExtended[params.get("jobId")].name
                    } (${formatDigit(
                      params.get("jobId"),
                    )})". I corsi che dichiarano di formare lavoratori in questa categoria professionale sono: ` ||
                    `La tua ricerca per : ${params
                      .get("coursesText")
                      .replace(/%20/g, " ")}`
                  : jobsLabelsExtended[params.get("jobId")]
                  ? jobsLabelsExtended[params.get("jobId")].name +
                    " (" +
                    formatDigit(params.get("jobId")) +
                    ")"
                  : `La tua ricerca per : ${params
                      .get("coursesText")
                      .replace(/%20/g, " ")}`}
              </Typography>

              <Table
                classes={{
                  root: classes.tableRoot,
                }}
                className={s.Table}
              >
                <TableHead>
                  <TableCell />
                  <TableCell>
                    <div className={s.Row}>
                      <Typography
                        variant={"h6"}
                        className={s.TypographyTofinoRegular}
                      >
                        Ateneo
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={s.Row}>
                      <Typography
                        variant={"h6"}
                        className={s.TypographyTofinoRegular}
                      >
                        Città
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={s.Row}>
                      <Typography
                        variant={"h6"}
                        className={s.TypographyTofinoRegular}
                      >
                        Regione
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell />
                </TableHead>
                <TableBody>
                  {coursesList.map(
                    (course, index) =>
                      index >= page * LIMIT &&
                      index <= page * LIMIT + LIMIT - 1 && (
                        <TableRow key={course.get("_id")}>
                          <TableCell>
                            <Typography
                              variant={"h6"}
                              className={s.TypographyTofinoMedium}
                            >
                              {course.get("nomecorso")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={"h6"}
                              className={s.TypographyTofinoMedium}
                            >
                              {course.get("nomeateneo")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={"h6"}
                              className={s.TypographyTofinoMedium}
                            >
                              {course.get("citta")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={"h6"}
                              className={s.TypographyTofinoMedium}
                            >
                              {regions[course.get("regionId")]}
                            </Typography>
                          </TableCell>
                          <TableCell className={s.LinkCell}>
                            <Link
                              href={`#/percorsi-formativi/ateneo/${course.get(
                                "_id",
                              )}/${selectedRegion}/${params.get(
                                "codtipolau",
                              )}/${params.get("jobId")}/${
                                params.get("coursesText") ?? null
                              }`}
                              underline={"none"}
                            >
                              <Typography
                                variant={"h6"}
                                color={"primary"}
                                className={s.TypographyTofinoMedium}
                              >
                                {"Dettagli"}
                              </Typography>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelDisplayedRows={({ from, to }) =>
                        `${from}-${to} di ${count}`
                      }
                      rowsPerPageOptions={[LIMIT]}
                      count={count}
                      rowsPerPage={LIMIT}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={(ev, page) => setPage(page)}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              <div className={s.AccordionContainer}>
                <Typography variant="subtitle1">Corsi</Typography>
                <div className={s.TestStyle}>
                  {coursesList.map((course, index) => (
                    <ExpansionPanel
                      className={s.ExpansionPanel}
                      key={course.get("_id")}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        className={
                          index % 2 === 0 ? s.ExpansionPanelSummaryFill : ""
                        }
                      >
                        <Link
                          href={`#/percorsi-formativi/ateneo/${course.get(
                            "_id",
                          )}/${selectedRegion}/${params.get(
                            "codtipolau",
                          )}/${params.get("jobId")}/${
                            params.get("coursesText") ?? null
                          }`}
                          underline={"none"}
                        >
                          <Typography
                            color={"inherit"}
                            variant={"h6"}
                            className={s.TypographyTofinoRegular}
                          >
                            {`${course.get("nomecorso")} - ${course.get(
                              "nomeateneobreve",
                            )} `}
                          </Typography>
                        </Link>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  color={"inherit"}
                                  variant={"h6"}
                                  className={s.TypographyTofinoRegular}
                                >
                                  Nome Corso
                                </Typography>
                              </TableCell>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TypographyTofinoMedium}
                                >
                                  {course.get("nomecorso")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  color={"inherit"}
                                  variant={"h6"}
                                  className={s.TypographyTofinoRegular}
                                >
                                  Nome Ateneo
                                </Typography>
                              </TableCell>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TypographyTofinoMedium}
                                >
                                  {course.get("nomeateneo")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  color={"inherit"}
                                  variant={"h6"}
                                  className={s.TypographyTofinoRegular}
                                >
                                  Nome Città
                                </Typography>
                              </TableCell>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TypographyTofinoMedium}
                                >
                                  {course.get("citta")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  color={"inherit"}
                                  variant={"h6"}
                                  className={s.TypographyTofinoRegular}
                                >
                                  Regione
                                </Typography>
                              </TableCell>
                              <TableCell className={s.TableCellExpansion}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TypographyTofinoMedium}
                                >
                                  {regions[course.get("regionId")]}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </div>
                {coursesList.size !== count && (
                  <Button
                    className={cn(sa.W100, sa.MT20)}
                    variant="outlined"
                    color="primary"
                    onClick={() => setPage(page + 1)}
                  >
                    Carica altri risultati
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className={s.Right}>
              <Typography
                classes={{
                  root: classes.title,
                }}
                variant="subtitle1"
              >
                {params.get("jobId") !== "null" ? (
                  selectedRegion === null ? (
                    `Nessun percorso formativo per l'occupazione cercata tra gli atenei presenti in AlmaLaurea.
                    Potrebbe trattarsi di un'occupazione che richiede un altro tipo di Laurea, che necessita di studi
                    specialistici post-laurea (es. Chirurgo, Medico specialista...) oppure, al contrario, di
                    un'occupazione che non richiede studi universitari.`
                  ) : (
                    <Fragment>
                      {`Nessun percorso formativo per l'occupazione cercata tra
                      gli atenei presenti in AlmaLaurea per questa regione.
                      Prova a effettuare la ricerca per tutta`}
                      <Link
                        className={s.Link}
                        color={"primary"}
                        onClick={() => setSelectedRegion(null)}
                        underline={"none"}
                      >
                        &nbsp;l’Italia
                      </Link>
                      <br />
                      <br />
                      {`Potrebbe
                      anche trattarsi di un'occupazione che richiede un altro
                      tipo di Laurea, che necessita di studi specialistici
                      post-laurea (es. Chirurgo, Medico specialista...) oppure,
                      al contrario, di un'occupazione che non richiede studi
                      universitari.`}
                    </Fragment>
                  )
                ) : (
                  <Fragment>
                    Non ci sono percorsi formativi tra gli atenei di AlmaLaurea
                    corrispondenti alla ricerca effettuata: prova con un altro
                    testo o in alternativa consulta la lista di tutti i corsi di
                    laurea
                    <Link
                      href="https://www.universitaly.it/index.php/cercacorsi/universita"
                      target="_blank"
                      style={{ cursor: "pointer" }}
                      underline={"none"}
                    >
                      {" "}
                      {"qui"}
                    </Link>
                    .
                  </Fragment>
                )}
              </Typography>
            </div>
          )
        ) : (
          <CircularProgress
            className={s.Progress}
            color="primary"
            size={100}
            thickness={2}
          />
        )}
      </Paper>
    </div>
  );
}

CoursesUniversities.propTypes = {
  ascendant: object,
  classes: object,
  clearList: func,
  count: number,
  coursesList: array,
  coursesLoading: bool,
  getAscendant: func,
  getCoursesAjax: func,
  params: object,
  restUri: string,
  setAscendant: func,
  t: func,
};

const mapStateToProps = (state) => ({
  ascendant: getAscendant(state),
  coursesList: getCoursesList(state),
  coursesLoading: getAjaxLoading(state).get("GET_COURSES_1"),
  restUri: getRestUri(state),
  params: getCoursesParams(state),
  count: getCount(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAscendant,
      getCoursesAjax,
      clearList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(CoursesUniversities)));
