// CONSTANTS
import * as keys from 'constants/translations/keys'

// LIBS
import cn from 'classnames'

// REACT
import React, { useState } from 'react'
import { func } from 'prop-types'
import { translate, Trans } from 'react-i18next'
import Header from 'components/header'
import { Grid, Link, MuiThemeProvider, Paper, Typography } from '@material-ui/core'

// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// STYLE
import s from './style.module.scss'
import { common } from 'styles/mui-themes'
import sg from 'css/style.module.scss'
import inapp from 'assets/images/logo_inapp.png'
import alma from 'assets/images/logo_alma_laurea.png'
import union from 'assets/images/unioncamere.png'
import oecd from 'assets/images/oecd_logo_english.png'

function InfoInapp(props) {
  return (
    <MuiThemeProvider theme={common}>
        <div className={s.Container}>
          <Header/>
          <div className={cn(sg.Wrapper, s.Content)}>
            <Grid container>
              <Grid item sm={12} md={9}>
                <Paper className={s.Paper}>
                  <img className={s.Logo} alt={'InApp'} src={inapp}/>
                  <Typography variant='h5' className={s.Title}>
                    INAPP - Public Policy Innovation
                  </Typography>
                  <Typography className={s.Text} variant='h6'>
                    L’Istituto Nazionale per l’Analisi delle Politiche Pubbliche (INAPP) è un Ente Pubblico di Ricerca, che svolge analisi,
                    monitoraggio e valutazione delle politiche del lavoro e dei servizi per il lavoro, delle politiche dell’istruzione e della
                    formazione, delle politiche sociali e di tutte quelle politiche pubbliche che hanno effetti sul mercato del lavoro.
                    <br/>
                    <br/>
                    L’INAPP è vigilato dal Ministero del Lavoro e delle Politiche Sociali e la sua missione istituzionale è quella di produrre
                    conoscenza ed evidenze scientifiche utili a supportare il policy making, contribuendo, in questo modo, allo sviluppo
                    scientifico, economico e sociale dell’Italia. L’attività di ricerca dell’Istituto è volta a studiare fenomeni d’importanza
                    strategica per la collettività quali: l’evoluzione del mercato del lavoro con una particolare enfasi sulle politiche tese ad
                    influenzarne l’andamento; il welfare, l’innovazione e gli investimenti sociali; le professioni e le competenze; le
                    istituzioni e le politiche della formazione; la povertà, la disabilità e l’inclusione sociale.
                    <br/>
                    <br/>
                    Insieme all’Istat, INAPP è l’unico Ente di informazione statistica riconosciuto dal Sistema Statistico Nazionale (SISTAN),
                    di cui è membro, mantiene e sviluppa alcune tra le più importanti indagini sul mercato del lavoro e sul sistema della
                    formazione in Italia. Dal 2017, INAPP ha riportato l’Italia nel consorzio europeo ERIC-ESS e cura per l’Italia la
                    fondamentale indagine European Social Survey ed è Agenzia nazionale del programma comunitario Erasmus+ per
                    l’ambito istruzione e formazione professionale. INAPP è inoltre Responsabile del Project Management dell’indagine
                    OCSE PIAAC in collaborazione con ANPAL ed ISTAT con il coordinamento generale del Ministero del Lavoro e delle
                    Politiche Sociali.
                    <br/>
                    <br/>
                    Dal 1° gennaio 2018 INAPP opera in qualità di Organismo intermedio del Programma operativo nazionale Sistemi di
                    politiche attive per l’occupazione (SPAO) del Fondo Sociale Europeo, in base a quanto previsto dall’art. 123 del
                    Regolamento (UE) n. 1303/2013.
                    <br/>
                    <br/>
                    L’INAPP contribuisce alla Piattaforma Competenze e Lavoro fornendo un sottoinsieme di variabili, concernenti
                    conoscenze, skills e attitudini delle professioni italiane, tratte dall’Indagine Campionaria sulle professioni.
                    <br/>
                    <br/>
                    L’Indagine Campionaria sulle Professioni INAPP descrive in dettaglio le caratteristiche di tutte le professioni in cui si
                    articola il mercato del lavoro italiano, da quelle operanti nelle imprese private a quelle presenti nell’ambito delle
                    istituzioni e delle strutture pubbliche, fino a quelle autonome. Si tratta dell’unica esperienza europea di replica del
                    modello statunitense O*Net (Occupational Information Network) capace di fornire informazioni al massimo livello di
                    dettaglio circa l’intero spettro di caratteristiche che definiscono i contenuti del lavoro rappresentati a livello di singola
                    professione.
                    <br/>
                    <br/>
                    Le risposte sono fornite direttamente dai lavoratori che svolgono le differenti professioni e che vengono intervistati
                    con tecnica Capi (Computer assisted personal interviewing). L’indagine si basa su un campione casuale di 16.000
                    lavoratori intervistati nell’esercizio della propria professione e si pone l’obiettivo di ricostruire, per tutte le 800 Unità
                    Professionali (5-digit) della Classificazione delle Professioni (CP 2011) il livello di importanza e il grado di complessità di
                    un ampio set di conoscenze, competenze e attività lavorative. Le prime due edizioni sono state condotte in stretta collaborazione
                    con Istat (anni 2007 e 2013). La terza edizione, progettata e gestita da INAPP, sarà realizzata nel corso del 2022 ed è inserita
                    nel Programma Operativo Nazionale - Sistemi di politiche attive per l&#39;occupazione (PON SPAO), cofinanziato dal Fondo
                    Sociale Europeo (FSE) programmazione 2014-2020, per il quale l’INAPP opera in qualità di Organismo intermedio con
                    funzioni di gestione.
                    <br/>
                    <br/>
                    L’indagine si inserisce nell&#39;ambito delle iniziative avviate per il rafforzamento e l’implementazione del Sistema
                    informativo Professioni, occupazione e fabbisogni, consultabile al link: <Link href='http://professionioccupazione.isfol.it'>
                    <Typography
                      variant={'h6'}
                      className={s.LinkText}
                    >http://professionioccupazione.isfol.it
                    </Typography>
                  </Link>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Paper className={s.RightPaper}>
                  <img className={s.RightLogo} onClick={() => window.location.href = '#/info-almalaurea'} alt={'AlmaLaurea'} src={alma} />
                  <img className={s.RightLogo} onClick={() => window.location.href = '#/info-unioncamere'} alt={'Unioncamere'} src={union} />
                  <img className={s.RightLogo} onClick={() => window.location.href = '#/info-oecd'} alt={'OECD'} src={oecd} />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </MuiThemeProvider>
  )
}

InfoInapp.propTypes = {
  t: func
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)((translate()(InfoInapp)))
