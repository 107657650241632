// CONSTANTS
import * as keys from "constants/translations/keys";

// LIBS
import cn from "classnames";

// REACT
import React, { useState } from "react";
import { func } from "prop-types";
import { translate, Trans } from "react-i18next";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  MuiThemeProvider,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import Header from "components/header";

// STYLE
import s from "./style.module.scss";
import { common } from "styles/mui-themes";
import sg from "css/style.module.scss";
import unioncamere from "assets/images/unioncamere.png";
import inapp from "assets/images/logo_inapp.png";
import alma from "assets/images/logo_alma_laurea.png";
import oecd from "assets/images/oecd_logo_english.png";

function MoreInfo(props) {
  return (
    <MuiThemeProvider theme={common}>
      <div className={s.Container}>
        <Header />
        <div className={cn(sg.Wrapper, s.Content)}>
          <Paper className={s.Paper}>
            <Typography variant="h5" className={s.Title}>
              Maggiori informazioni
            </Typography>
            <Typography className={s.Text} variant="h6">
              La digitalizzazione, la globalizzazione, l’invecchiamento della
              popolazione, la transizione ecologica, e da ultimo, la crisi
              indotta dalla pandemia di COVID-19 stanno rapidamente cambiando il
              panorama nel quale gli individui si trovano a interagire, lavorare
              e produrre. Questi fenomeni hanno un profondo impatto sulle
              competenze richieste nel mercato del lavoro e, ad oggi, molti
              individui faticano ad adattarsi al cambiamento.
              <br />
              <br />
              In media, più del 34% dei lavoratori delle economie Europee
              possiede competenze e un livello di istruzione che non corrispondono a
              quelli richiesti dal loro lavoro. In Italia, questa percentuale è
              addirittura superiore (39%) e pone in evidenza l’importanza di
              disegnare strumenti a sostegno dei giovani, delle famiglie, dei
              lavoratori e delle imprese per allineare l’offerta di competenze
              alle domande del mercato del lavoro.
              <br />
              <br />
              La Piattaforma Competenze e Lavoro è pensata come uno strumento
              “smart” che consente all’utente di ottenere informazioni su
              fabbisogni professionali, competenze e percorsi formativi,
              interrogando le banche dati dei diversi partner coinvolti.
              <br />
              <br />
              I punti di ingresso alla piattaforma sono tre:
              <br />
              <br />
              - Professioni: dove l’utente troverà informazioni sui fabbisogni
              professionali delle imprese italiane, la loro domanda disaggregata
              per livello di istruzione, e età.
              <br />
              <br />
              - Competenze: dove l’utente troverà informazioni sulle conoscenze,
              skills e attitudini legate alle diverse figure professionali del
              mercato del lavoro italiano.
              <br />
              <br />
              - Percorsi formativi: dove l’utente troverà informazioni sui corsi
              di laurea forniti dagli atenei italiani coinvolti da Almalaurea
              con dettaglio sul profilo dei laureati (per esempio, la
              soddisfazione per il corso seguito o la valutazione delle
              infrastrutture) e la loro condizione occupazionale (per esempio,
              il tasso di occupazione o la retribuzione ad un anno dalla
              laurea).
              <br />
              <br />I punti di ingresso alla piattaforma sono collegati l’uno
              all’altro dando la possibilità all’utente di muoversi facilmente
              fra le diverse aree tematiche e ottenere informazioni dettagliate
              sui fabbisogni professionali delle imprese Italiane, sulle
              competenze legate alle diverse figure professionali e sui percorsi
              formativi universitari disponibili sul territorio nazionale.
            </Typography>
          </Paper>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

MoreInfo.propTypes = {
  t: func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(MoreInfo));
