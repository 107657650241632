// LIBS
import immutable from 'immutable'

// REDUX
import initialState from '../../initial-states/global'
import {
  UPDATE_AJAX_LOADING
} from '../../action-types/global'

export default (state = initialState, action) => {
  let api, isLoading, uid

  switch (action.type) {
    case UPDATE_AJAX_LOADING:
      ({ api, isLoading, uid } = action)
      state = state.setIn(['ajaxLoaders', `${api}_${uid}`], isLoading)
      return state
    default:
      return state
  }
}
