import en from './en'
import it from './it'

export default {
  en: {
    translations: en
  },
  it: {
    translations: it
  }
}
