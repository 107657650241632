// CONSTANTS

const labels = require('constants/skills-labels')
const skillsLabels = Object.keys(labels).map(key => ({ value: key, label: labels[key] }))

// REACT
import React, { useState } from 'react'
import { func } from 'prop-types'
import { translate } from 'react-i18next'
import Autocomplete from 'components/autocomplete'
import PageToolbar from 'components/page-toolbar'
import { SearchOutlined as SearchIcon } from '@material-ui/icons'

// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Fab,
  Paper,
  Typography, withStyles
} from '@material-ui/core'

// STYLE
import s from './style.module.scss'
import sa from 'css/atomic.module.scss'

function SkillsSearch(props) {
  const [selectedSkills, setSelectedSkills] = useState([])
  const {
    t
  } = props

  function onSubmit() {
    let hash = `${selectedSkills}`
    hash = hash.replace(/,/g, '/')
    window.location.href = `#/competenze/aree-professionali/${hash}`
  }

  return (
    <div className={s.Container}>
      <PageToolbar
        title={'Scopri come una competenza si inserisce nel mercato del lavoro'}
        tooltipText={'I risultati mostrati in questa pagina provengono dall’Indagine Campionaria sulle Professioni INAPP. Questa descrive in dettaglio le caratteristiche di tutte le professioni in cui si articola il mercato del lavoro italiano, da quelle operanti nelle imprese private a quelle presenti nell’ambito delle istituzioni e delle strutture pubbliche, fino a quelle autonome. Si tratta dell’unica esperienza europea di replica del modello statunitense O*Net (Occupational Information Network) capace di fornire informazioni al massimo livello di dettaglio circa l’intero spettro di caratteristiche che definiscono i contenuti del lavoro rappresentati a livello di singola professione.'}
      />
      <Paper className={s.Paper}>
        <div className={s.SearchContainer}>
          <div className={s.Column}>
            <div className={s.Row}>
              <Autocomplete
                className={s.Input}
                suggestions={skillsLabels}
                placeholder={'Tecnologia, espressione orale, matematica, ecc...'}
                onChange={setSelectedSkills}
                label={'Cerca competenze'}
                multiple
                limit={3}
              />
              <Fab
                className={s.SearchFab}
                color='primary'
                aria-label='Competenze'
                onClick={() => onSubmit()}
                disabled={!selectedSkills.length}
              >
                <SearchIcon className={sa.CW}/>
              </Fab>
              <Button
                aria-label='Competenze'
                className={s.SearchButton}
                color='primary'
                disabled={!selectedSkills.length}
                onClick={() => onSubmit()}
                variant={'contained'}
              >
                <Typography color='inherit'>{'Cerca'}</Typography>
              </Button>
            </div>
            <Typography>
              Scopri statistiche sulle domande di assunzione, difficoltà di reperimento, titoli di studio o età
              ricercata dalle imprese
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  )
}

SkillsSearch.propTypes = {
  t: func
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)((translate()(SkillsSearch)))
