// CONSTANTS

// REACT
import React, { useEffect, useState } from 'react'
import { any, array, func, object, string } from 'prop-types'
import { translate } from 'react-i18next'
import { Button, withStyles } from '@material-ui/core'

// LIBS
import cn from 'classnames'

// STYLE

// options [{value valore che viene passato indietro quando clicchi, label}]

export const styles = theme => {
  const light = theme.palette.type === 'light'
  const borderColor = light ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)'
  const buttonSelectedColor = theme.palette.primary.contrastText

  return {
    root: {
      position: 'relative',
      display: 'inline-flex',
      border: '1px solid',
      borderColor: borderColor,
      borderRadius: 999,
      marginTop: 10
      /*
      height: 40px
  width: fit-content;
  display: flex;
  border-radius: 50px;
  justify-content: space-around;
  */
    },
    selector: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: '100%',
      background: theme.palette.primary.main,
      borderRadius: 999,
      transition: 'all ease-in .2s',
      zIndex: 1
    },
    buttonRoot: {
      borderRadius: 999,
      padding: '10px 20px',
      zIndex: 2
    },
    buttonLabel: {},
    buttonSelected: {
      color: buttonSelectedColor
    }
  }
}

function DegreeSwitch({
  className,
                        options,
                        buttonProps,
                        classes,
                        onClick,
                        value
                      }) {
  const [selectorStyle, setSelectorStyle] = useState({})
  const [windowWidth, setWindowWidth] = useState()

  const buttonRefs = []
  options.map(() => {
    buttonRefs.push(React.createRef())
  })

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const index = options.findIndex(option => option.value === value)
    if (index !== -1) {

      const button = buttonRefs[index].current
      const width = Math.round(button.getBoundingClientRect().width) + 1
      const left = Math.round(button.getBoundingClientRect().x - button.parentNode.getBoundingClientRect().x) - 1

      setSelectorStyle({
        width,
        left
      })
    }
  }, [value, windowWidth])

  return (
    <div className={cn(classes.root, className)}>
      {
        options.map((opt, index) => (
          <div key={opt.value}
            ref={buttonRefs[index]}>
            <Button
              className={cn({
                [classes.buttonSelected]: opt.value === value
              })}
              fullWidth
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel
              }}
              onClick={() => onClick && onClick(opt.value)}
              {...buttonProps}
            >{opt.label}</Button>
          </div>
        ))
      }
      {
        value && <div
          className={classes.selector}
          style={selectorStyle}
        />
      }
    </div>
  )
}

DegreeSwitch.propTypes = {
  buttonProps: object,
  className: string,
  classes: object,
  onClick: func,
  options: array.isRequired,
  value: any
}

export default withStyles(styles)(translate()(DegreeSwitch))
