// CONSTANTS
import resources from 'constants/translations'

// REACT
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { I18nextProvider } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import HashRouter, { Route } from 'react-redux-hash-router'
import Home from './scenes/home'
import Jobs from './scenes/jobs'
import Skills from './scenes/skills'
import Courses from './scenes/courses'
import InfoAlmalaurea from './scenes/info-almalaurea'
import InfoInapp from './scenes/info-inapp'
import InfoUnioncamere from './scenes/info-unioncamere'
import InfoOecd from './scenes/info-oecd'
import MoreInfo from './scenes/more-info'

// REDUX
import { Provider } from 'react-redux'
import store from './redux/store'

// SERVICES
import createI18n from 'services/createI18n'
import lng from 'services/language'

// STYLES
import theme  from 'styles/mui-themes'
import s from 'css/style.module.scss'
import 'assets/css/reset.css'
import 'assets/css/fonts.css'

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={createI18n({ resources, lng })}>
        <MuiThemeProvider theme={theme}>
          <div className={s.Container}>
            <HashRouter
              routerStatePath='routing.mainRouter'
              loaderComponent={<div>Loading...</div>}
            >
              <Route hash='#' initAccess={true} routeKey='home'>
                <Home/>
              </Route>
              <Route hash='#/professioni' initAccess={true} routeKey='jobs'>
                <Jobs/>
              </Route>
              <Route hash='#/competenze' initAccess={true} routeKey='skills'>
                <Skills/>
              </Route>
              <Route hash='#/percorsi-formativi' initAccess={true} routeKey='courses'>
                <Courses/>
              </Route>
              <Route hash='#/info-almalaurea' initAccess={true} routeKey='infoAlmalaurea'>
                <InfoAlmalaurea/>
              </Route>
              <Route hash='#/info-inapp' initAccess={true} routeKey='infoInapp'>
                <InfoInapp/>
              </Route>
              <Route hash='#/info-unioncamere' initAccess={true} routeKey='infoUnioncamere'>
                <InfoUnioncamere/>
              </Route>
              <Route hash='#/info-oecd' initAccess={true} routeKey='infoOecd'>
                <InfoOecd/>
              </Route>
              <Route hash='#/maggiori-informazioni' initAccess={true} routeKey='moreInfo'>
                <MoreInfo/>
              </Route>
            </HashRouter>
          </div>
        </MuiThemeProvider>
      </I18nextProvider>
    </Provider>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
