// REDUX
import { all, takeEvery, put, select, take } from 'redux-saga/effects'
import {
  GET_COURSES_BY__ID_AJAX_SUCCESS,
  GET_COURSES_BY__ID_AJAX_FAILED
} from 'redux/action-types/ajax'
import {
  GET_COURSE_FROM_REGION_AND_ITALY
} from 'redux/action-types/courses'
import { getRestUri } from 'redux/getters/global'
import { getCoursesBy_idAjax, getItalyCoursesByCodalma_classeconfrontoAjax } from 'redux/actions/ajax'
import { getCoursesParams } from 'redux/getters/routing'

export default function * getCourseSaga () {
  yield takeEvery(GET_COURSE_FROM_REGION_AND_ITALY, function * () {
    const state = yield select()
    const restUri = getRestUri(state)
    const _id = getCoursesParams(state).get('_id')
    yield put(getCoursesBy_idAjax(restUri, _id))
    let action = yield take([GET_COURSES_BY__ID_AJAX_SUCCESS, GET_COURSES_BY__ID_AJAX_FAILED])
    if (action.type === GET_COURSES_BY__ID_AJAX_SUCCESS) {
      const codalma_classeconfronto = action.data.course.codalma_classeconfronto
      yield put(getItalyCoursesByCodalma_classeconfrontoAjax(restUri, codalma_classeconfronto))
    } else {
      // HANDLE FAIL
    }
  })
}
