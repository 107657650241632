// LIBS
import immutable from 'immutable'

// REDUX
import initialState from '../../initial-states/skills'
import {
  GET_GROUPED_SKILLS_AJAX_SUCCESS, GET_SKILLS_BY_JOBID_AJAX_SUCCESS
} from 'redux/action-types/ajax'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPED_SKILLS_AJAX_SUCCESS:
      state = state.set('grouped', immutable.fromJS(action.data.groupedSkills))
      return state
    case GET_SKILLS_BY_JOBID_AJAX_SUCCESS:
      state = state.set('list', immutable.fromJS(action.data.skills))
      return state
    default:
      return state
  }
}
