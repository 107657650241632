// CONSTANTS
import * as keys from "constants/translations/keys";

// LIBS
import cn from "classnames";

// REACT
import React, { useState } from "react";
import { func } from "prop-types";
import { translate, Trans } from "react-i18next";
import { Grid, MuiThemeProvider, Paper, Typography } from "@material-ui/core";
import Header from "components/header";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";

// STYLE
import s from "./style.module.scss";
import { common } from "styles/mui-themes";
import sg from "css/style.module.scss";
import oecd from "assets/images/oecd_logo_english.png";
import unioncamere from "assets/images/unioncamere.png";
import alma from "assets/images/logo_alma_laurea.png";
import inapp from "assets/images/logo_inapp.png";
import jpmorgan from "assets/images/jpmc_nsaw_v.png";

function InfoOecd(props) {
  return (
    <MuiThemeProvider theme={common}>
      <div className={s.Container}>
        <Header />
        <div className={cn(sg.Wrapper, s.Content)}>
          <Grid container>
            <Grid item sm={12} md={9}>
              <Paper className={s.Paper}>
                <img className={s.Logo} alt={"OECD"} src={oecd} />
                <Typography variant="h5" className={s.Title}>
                  OECD- OCSE: Organizzazione per la Cooperazione e lo Sviluppo
                  Economico
                </Typography>
                <Typography className={s.Text} variant="h6">
                  L&#39;Organizzazione per la cooperazione e lo sviluppo
                  economico (OCSE) è un&#39;organizzazione internazionale che
                  lavora per promuovere, a livello globale, politiche che
                  migliorino il benessere economico e sociale dei cittadini.
                  <br />
                  <br />
                  L’OCSE costituisce un forum di raccolta, diffusione e scambio
                  di dati e analisi, di confronto ed armonizzazione delle
                  migliori prassi sulle politiche pubbliche a livello nazionale
                  ed internazionale, fornendo consulenze ai governi dei Paesi
                  membri sulle misure a sostegno di una crescita resiliente,
                  inclusiva, e sostenibile. L’OCSE si occupa di tematiche che
                  attengono a numerosi settori, da quello economico a quello
                  finanziario, dal settore sociale alla governance, dallo
                  sviluppo sostenibile alla cooperazione tecnologica e
                  all’innovazione. Nel perseguimento dei suoi obiettivi, l’OCSE
                  si avvale di un’ampia gamma di strumenti quali: l’adozione di
                  standard e principi comuni, raccomandazioni e convenzioni; la
                  pubblicazione, due volte l’anno, dell’Economic Outlook,
                  contenente l’aggiornamento del quadro macroeconomico globale;
                  l’elaborazione di studi nazionali e comparativi; gli
                  esami-Paese, condotti secondo il metodo della &quot;peer
                  review&quot;, nonché la definizione di linee guida e
                  coordinamento delle politiche di cooperazione allo sviluppo.
                  <br />
                  <br />
                  Negli ultimi anni, l’OCSE ha dedicato notevole attenzione alle
                  politiche economiche e sociali necessarie per far fronte alle
                  profonde trasformazioni nel mondo del lavoro. La
                  digitalizzazione, la globalizzazione, l’invecchiamento della
                  popolazione, la transizione ecologica, e da ultimo, la crisi
                  indotta dalla pandemia di COVID-19, stanno avendo un profondo
                  impatto sul numero e sulla qualità dei posti di lavoro
                  disponibili e sulle competenze necessarie per eseguire bene i
                  compiti di una professione. La misura in cui individui,
                  imprese e società possono trarre vantaggio da questi
                  cambiamenti dipenderà in modo cruciale dalla prontezza dei
                  sistemi educativi e di formazione degli adulti, e da come
                  questi saranno capaci di dare supporto allo sviluppo delle
                  competenze necessarie ad affrontare le sfide del futuro.
                  <br />
                  <br />
                  In questo contesto, l’OCSE, in collaborazione con J.P. Morgan
                  ha contribuito allo sviluppo della Piattaforma Competenze e
                  Lavoro, nell’ambito delle iniziative “Getting Skills Right” e
                  “New Skills at Work”.
                  <br />
                  <br />
                  Per approfondimenti sul lavoro dell’OCSE su tematiche relative
                  alle competenze e al mondo del lavoro, è possibile consultare
                  la pagina:
                  <Link href="https://www.oecd.org/employment/skills-and-work/">
                    <Typography variant={"h6"} className={s.LinkText}>
                      https://www.oecd.org/employment/skills-and-work/
                    </Typography>
                  </Link>
                  <img className={s.LogoJP} alt={"JP Morgan"} src={jpmorgan} />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Paper className={s.RightPaper}>
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-almalaurea")}
                  alt={"AlmaLaurea"}
                  src={alma}
                />
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-inapp")}
                  alt={"InApp"}
                  src={inapp}
                />
                <img
                  className={s.RightLogo}
                  onClick={() => (window.location.href = "#/info-unioncamere")}
                  alt={"Unioncamere"}
                  src={unioncamere}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

InfoOecd.propTypes = {
  t: func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(InfoOecd));
