// REACT
import React, { Fragment } from 'react'
import { func } from 'prop-types'
import { translate } from 'react-i18next'
import HashRouter, { Route } from 'react-redux-hash-router'
import JobsSearch from './scenes/jobs-search'
import JobsResults from './scenes/jobs-results'
import Header from 'components/header'
import JobsGroupsResults from './scenes/jobs-groups-results'
// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// STYLE
import sg from 'css/style.module.scss'
import { MuiThemeProvider } from '@material-ui/core'
import { first } from 'styles/mui-themes'
import JobsCourseResults from './scenes/jobs-course-results'

function Jobs(props) {
  const {
    t
  } = props

  return (
    <div style={{
      backgroundColor: '#589dde',
      backgroundImage:'url("data:image/svg+xml,%3Csvg width=\'12\' height=\'16\' viewBox=\'0 0 12 16\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z\' fill=\'%2361a7e8\' fill-opacity=\'1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
      minHeight: '100vh'
    }}>
      <Header/>
      <MuiThemeProvider theme={first}>
        <div className={sg.Wrapper}>
          <HashRouter
            routerStatePath='routing.jobsRouter'
            loaderComponent={<div>Loading...</div>}
          >
            <Route hash='#/professioni/ricerca' initAccess={true} routeKey='jobsSearch'>
              <JobsSearch/>
            </Route>
            <Route hash='#/professioni/{regionId}/{jobId}/{index}' initAccess={true} routeKey='jobsResults'>
              <JobsResults/>
            </Route>
            <Route hash='#/professioni/gruppi/{regionId}/{jobGroupId}' initAccess={true} routeKey='jobsGroupsResults'>
              <JobsGroupsResults/>
            </Route>
            <Route hash='#/professioni/corso/{regionId}/{codtipolau}/{courseId}' initAccess={true} routeKey='jobsCourseResults'>
              <JobsCourseResults/>
            </Route>
          </HashRouter>
        </div>
      </MuiThemeProvider>
    </div>
  )
}

Jobs.propTypes = {
  t: func
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)((translate()(Jobs)))
