// CONSTANTS
import * as keys from 'constants/translations/keys'

// REACT
import React, { useState } from 'react'
import { func, string, shape } from 'prop-types'
import { Trans, translate } from 'react-i18next'
import { SvgIcon, withStyles } from '@material-ui/core'

// LIBS
import cn from 'classnames'

// STYLE
import s from './style.module.scss'

const styles = (theme) => ({
  arrowBack: {
    stroke: theme.palette.secondary.main,
    marginRight: '10px',
    fill: 'none',
    'stroke-linecap': 'round',
    height: '10px',
    width: '6px'
  }
})

function ArrowBack( props ) {
  const { classes } = props
    return (
      <SvgIcon {...props} classes={{ root: classes.arrowBack }} viewBox="0 0 6.201 10.989">
          <path style={{ width: '100%', height:'100%' }} transform="translate(12107 2637.6) rotate(180)" d="m12101 2627.1l4.994 4.995-4.994 4.995"/>
      </SvgIcon>
    )
}

ArrowBack.propTypes = {
  className: string,
  classes: shape({}),
  stroke: string,
  t: func
}

export default (withStyles(styles)(translate()(ArrowBack)))
