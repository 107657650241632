// LIBS
import immutable from "immutable";

export default immutable.fromJS({
  list: [],
  page: 0,
  ascendant: {
    jobName: null,
    ass_tot: false,
    diff: null,
    scuola_obbligo: null,
    fino29anni: null,
  },
  count: 0,
});
