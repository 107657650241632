// CONSTANTS
import { common } from "styles/mui-themes";
// REACT
import React, { Fragment, useEffect, useState } from "react";
import { func, object } from "prop-types";
import { translate } from "react-i18next";
import {
  Button,
  FormControl,
  Link,
  MuiThemeProvider,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "components/autocomplete";
import ItalyMap from "components/italy-map";
import DegreeSwitch from "components/degree-switch";
import PageToolbar from "components/page-toolbar";
// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCoursesParams } from "redux/getters/routing";
// STYLE
import s from "./style.module.scss";
const labels = require("constants/jobs-labels");
const regions = require("constants/regions-labels");
const courses = require("constants/courses-labels");
const strings = require("constants/strings");
const cn = require("classnames");

const jobsLabelsExtended = require("constants/jobs-labels-extended");
const jobsLabels = [];
Object.keys(jobsLabelsExtended).map((key) => {
  jobsLabelsExtended[key].values.map((value, index) => {
    jobsLabels.push({
      value: `${key}:${index}`,
      label: value.label,
    });
  });
});

const styles = (theme) => ({
  label: {
    marginTop: 50,
  },
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
  appBarRoot: {
    backgroundColor: "white",
  },
  tabsDiv: {
    borderBottom: "1px solid #e8e8e8",
  },
  tab: {
    color: theme.palette.primary.main,
    fontFamily: '"Nunito-Bold", sans-serif !important',
    "&focus": {
      opacity: 1,
    },
  },
});

function CoursesSearch({ classes, params }) {
  let coursesText = params.get("coursesText") || "null";
  coursesText = coursesText === "null" ? null : decodeURIComponent(coursesText);
  const [selectedJob, setSelectedJob] = useState(params.get("jobId") || null);
  const [selectedIndex, setSelectedIndex] = useState(params.get("index") || 0);
  const [selectedRegion, setSelectedRegion] = useState(
    parseInt(params.get("regionId"), 10),
  );
  const [selectedCodtipolau, setSelectedCodtipolau] = useState(
    params.get("codtipolau") || null,
  );
  const [selectedTab, setSelectedTab] = useState(
    parseInt(params.get("searchType"), 10) || 0,
  );
  const [selectedCourse, setSelectedCourse] = useState(coursesText);

  useEffect(() => {
    setSelectedTab(parseInt(params.get("searchType"), 10) || 0);
    const region = params.get("regionId") || "999";
    setSelectedRegion(region === "999" ? null : parseInt(region, 10));
    const jobId = params.get("jobId") || "null";
    setSelectedJob(jobId === "null" ? null : jobId);
    const index = params.get("index") || 0;
    setSelectedIndex(index);
    const codtipolau = params.get("codtipolau") || null;
    setSelectedCodtipolau(codtipolau === "null" ? null : codtipolau);
  }, [params]);

  const search = () => {
    window.location.href = `#/percorsi-formativi/atenei/${
      selectedRegion || "999"
    }/${selectedCodtipolau}/${selectedJob}/null/${selectedIndex}`;
  };

  const searchByCourse = (coursesText) => {
    window.location.href = `#/percorsi-formativi/atenei/${
      selectedRegion || "999"
    }/${selectedCodtipolau}/null/${coursesText}/${selectedIndex}`;
  };

  const handleTabChange = (ev, value) => {
    refreshPage({
      tab: value,
    });
  };

  const refreshPage = ({
    tab = selectedTab,
    region = selectedRegion || "999",
    jobId = selectedJob,
    codtipolau = selectedCodtipolau,
    coursesText = selectedCourse,
    index = selectedIndex,
  }) => {
    window.location.href = `#/percorsi-formativi/ricerca/${tab}/${region}/${jobId}/${coursesText}/${codtipolau}/${index}`;
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = "NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.href = "/NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.click();
  };

  return (
    <div className={s.Container}>
      <Link
              className={s.DownloadLink}
        onClick={onDownload}
      >
        <Typography color="inherit" variant={"h4"}>
          {"Scarica il PDF con le note alla lettura"}
        </Typography>
      </Link>
      <PageToolbar
        title={"Scopri i Corsi di Laurea a cui puoi iscriverti"}
        tooltipText={"Scarica il PDF con le note alla lettura."}
        downloadFile={onDownload}
      />
      <Paper className={s.Paper}>
        <div className={s.Left}>
          <Typography variant="subtitle1">Seleziona una regione</Typography>
          <Typography className={s.CurrentView} variant={"h6"}>
            {"Stai visualizzando:"}
          </Typography>
          {selectedRegion ? (
            <div className={s.Region}>
              <MuiThemeProvider theme={common}>
                <Link
                  color={"secondary"}
                  classes={{
                    root: classes.linkRoot,
                  }}
                  onClick={() => refreshPage({ region: 999 })}
                  underline={"none"}
                >
                  <Typography color="inherit" variant={"h6"}>
                    {"Tutta Italia >"}
                  </Typography>
                </Link>
              </MuiThemeProvider>
              &nbsp;
              <Typography
                classes={{
                  root: classes.regionText,
                }}
              >
                {regions[selectedRegion]}
              </Typography>
            </div>
          ) : (
            <Typography variant={"h6"}>{"Tutta Italia"}</Typography>
          )}
          <MuiThemeProvider theme={common}>
            <ItalyMap
              className={s.Map}
              selected={selectedRegion}
              onRegionClick={(region) => refreshPage({ region: region || 999 })}
            />
          </MuiThemeProvider>
          <FormControl className={s.RegionSelect} fullWidth variant="filled">
            <Select
              native
              value={selectedRegion || ""}
              onChange={(e) => refreshPage({ region: e.target.value })}
              input={
                <OutlinedInput
                  color="primary"
                  fullWidth
                  name="region"
                  id="region-select"
                />
              }
            >
              <option value="999">{"Seleziona una Regione"}</option>
              {Object.keys(regions).map((regionId) => {
                if (regionId !== "999") {
                  return (
                    <option key={regionId} value={regionId}>
                      {regions[regionId]}
                    </option>
                  );
                }
              })}
            </Select>
          </FormControl>
        </div>
        <div className={s.Right}>
          <div className={classes.tabsDiv}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabChange}
              value={selectedTab}
              variant="fullWidth"
            >
              <Tab
                classes={{
                  root: classes.tab,
                }}
                label="Cerca per corso di laurea"
              />
              <Tab
                classes={{
                  root: classes.tab,
                }}
                label="Cerca per professione"
              />
            </Tabs>
          </div>
          {selectedTab === 1 && (
            <Fragment>
              <Typography
                classes={{
                  root: classes.label,
                }}
                variant={"subtitle1"}
              >
                Quale professione cerchi?
              </Typography>
              <Link
                color={"secondary"}
                href={"http://professioni.istat.it/cp2011/"}
                target="_blank"
                underline={"none"}
              >
                <Typography variant="h4">{"Mostra la classificazione completa delle professioni >"}</Typography>
              </Link>
              <div className={s.SearchContainer}>
                <Autocomplete
                  className={cn(s.Input, s.TextField, s.multiline)}
                  initialValue={
                    !!selectedJob &&
                    selectedJob !== "null" &&
                    jobsLabelsExtended[selectedJob].values[selectedIndex].label
                  }
                  suggestions={jobsLabels}
                  onChange={(jobId) =>
                    !!jobId &&
                    refreshPage({
                      jobId: jobId.split(":")[0],
                      index: jobId.split(":")[1],
                    })
                  }
                  placeholder={"Medici, Guide turistiche, Professori, ecc..."}
                  label={
                    "Inizia a scrivere dunque seleziona una professione dall'elenco"
                  }
                  onKeyPress={(e) => {
                    if (
                      e.key === "Enter" &&
                      selectedJob &&
                      selectedCodtipolau
                    ) {
                      search();
                    }
                  }}
                />
              </div>
            </Fragment>
          )}
          {selectedTab === 0 && (
            <Fragment>
              <Typography
                classes={{
                  root: classes.label,
                }}
                variant={"subtitle1"}
              >
                Quale corso di laurea cerchi?
              </Typography>
              <Link
                color={"secondary"}
                href={`https://www.universitaly.it/index.php/cercacorsi/universita
              `}
                target="_blank"
                underline={"none"}
              >
                <Typography variant="h4">
                  {
                    "Mostra tutti i corsi dell’offerta formativa universitaria >"
                  }
                </Typography>
              </Link>
              <div className={s.SearchContainer}>
                <TextField
                  className={s.TextField}
                  fullWidth
                  label={"Cerca Percorsi Formativi tramite ricerca libera"}
                  onChange={(ev) => {
                    setSelectedCourse(ev.target.value);
                    refreshPage({ coursesText: ev.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (
                      e.key === "Enter" &&
                      selectedCourse &&
                      selectedCodtipolau
                    ) {
                      searchByCourse(selectedCourse);
                    }
                  }}
                  placeholder={"Ingegneria, Scienze statistiche, Medicina, ..."}
                  value={selectedCourse}
                  variant="outlined"
                />
              </div>
            </Fragment>
          )}
          <div className={s.DegreeType}>
            <Tooltip
              title={
                <Typography variant="h4" color="inherit">
                  I corsi di laurea a cui ci si può iscrivere con un diploma di
                  scuola secondaria di secondo grado sono i triennali e quelli a
                  ciclo unico. Le lauree triennali rilasciano un titolo
                  universitario di primo livello. Le lauree a ciclo unico, che
                  spesso prevedono il superamento di test di ammissione, hanno
                  durata di 5 o 6 anni e rilasciano un titolo universitario di
                  secondo livello. Alle lauree magistrali invece si può accedere
                  solo se in possesso di una laurea triennale (o di livello
                  superiore) e rilasciano un titolo universitario di secondo
                  livello.
                </Typography>
              }
            >
              <Typography variant={"subtitle1"}>Che Tipo di Laurea?</Typography>
            </Tooltip>
            <FormControl className={s.DegreeSelect} fullWidth variant="filled">
              <Select
                native
                value={selectedCodtipolau || "-"}
                onChange={(e) => refreshPage({ codtipolau: e.target.value })}
                input={
                  <OutlinedInput
                    color="primary"
                    fullWidth
                    name="region"
                    id="region-select"
                  />
                }
              >
                <option value={"-"}>{"Seleziona il tipo di corso"}</option>
                <option value={"L"}>{"Laurea triennale"}</option>
                <option value={"LS"}>{"Laurea magistrale"}</option>
                <option value={"LSE"}>{"Ciclo unico"}</option>
              </Select>
            </FormControl>
            <DegreeSwitch
              className={s.DegreeSwitch}
              onClick={(codtipolau) => refreshPage({ codtipolau })}
              options={[
                { label: "Laurea triennale", value: "L" },
                { label: "Ciclo unico", value: "LSE" },
                { label: "Laurea magistrale", value: "LS" },
              ]}
              value={selectedCodtipolau}
            />
          </div>
          <Button
            aria-label="Cerca"
            className={s.SearchButton}
            color="primary"
            disabled={
              !selectedCodtipolau ||
              selectedCodtipolau === "-" ||
              (selectedTab === 1 ? !selectedJob : !selectedCourse)
            }
            onClick={() =>
              selectedTab === 1
                ? search(selectedJob)
                : searchByCourse(selectedCourse)
            }
            variant={"contained"}
          >
            <Typography color="inherit">{"Cerca"}</Typography>
          </Button>
        </div>
      </Paper>
    </div>
  );
}

CoursesSearch.propTypes = {
  classes: object,
  params: object,
  t: func,
};

const mapStateToProps = (state) => ({
  params: getCoursesParams(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(CoursesSearch)));
