export const GET_COURSES_AJAX_REQUEST = 'GET_COURSES_AJAX_REQUEST'
export const GET_COURSES_AJAX_SUCCESS = 'GET_COURSES_AJAX_SUCCESS'
export const GET_COURSES_AJAX_FAILED = 'GET_COURSES_AJAX_FAILED'

export const GET_COURSES_BY__ID_AJAX_REQUEST = 'GET_COURSES_BY__ID_AJAX_REQUEST'
export const GET_COURSES_BY__ID_AJAX_SUCCESS = 'GET_COURSES_BY__ID_AJAX_SUCCESS'
export const GET_COURSES_BY__ID_AJAX_FAILED = 'GET_COURSES_BY__ID_AJAX_FAILED'

export const GET_JOBS_FROM_COURSES_BY__ID_AJAX_REQUEST = 'GET_JOBS_FROM_COURSES_BY__ID_AJAX_REQUEST'
export const GET_JOBS_FROM_COURSES_BY__ID_AJAX_SUCCESS = 'GET_JOBS_FROM_COURSES_BY__ID_AJAX_SUCCESS'
export const GET_JOBS_FROM_COURSES_BY__ID_AJAX_FAILED = 'GET_JOBS_FROM_COURSES_BY__ID_AJAX_FAILED'

export const GET_GROUPED_SKILLS_AJAX_REQUEST = 'GET_GROUPED_SKILLS_AJAX_REQUEST'
export const GET_GROUPED_SKILLS_AJAX_SUCCESS = 'GET_GROUPED_SKILLS_AJAX_SUCCESS'
export const GET_GROUPED_SKILLS_AJAX_FAILED = 'GET_GROUPED_SKILLS_AJAX_FAILED'

export const GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_REQUEST = 'GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_REQUEST'
export const GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_SUCCESS = 'GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_SUCCESS'
export const GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_FAILED = 'GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_FAILED'

export const GET_JOBS_AJAX_REQUEST = 'GET_JOBS_AJAX_REQUEST'
export const GET_JOBS_AJAX_SUCCESS = 'GET_JOBS_AJAX_SUCCESS'
export const GET_JOBS_AJAX_FAILED = 'GET_JOBS_AJAX_FAILED'

export const GET_JOBS_BY__ID_AJAX_REQUEST = 'GET_JOBS_BY__ID_AJAX_REQUEST'
export const GET_JOBS_BY__ID_AJAX_SUCCESS = 'GET_JOBS_BY__ID_AJAX_SUCCESS'
export const GET_JOBS_BY__ID_AJAX_FAILED = 'GET_JOBS_BY__ID_AJAX_FAILED'

export const GET_SKILLS_BY_JOBID_AJAX_REQUEST = 'GET_SKILLS_BY_JOBID_AJAX_REQUEST'
export const GET_SKILLS_BY_JOBID_AJAX_SUCCESS = 'GET_SKILLS_BY_JOBID_AJAX_SUCCESS'
export const GET_SKILLS_BY_JOBID_AJAX_FAILED = 'GET_SKILLS_BY_JOBID_AJAX_FAILED'

