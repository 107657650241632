export const formatDigit = (digit, n = 1) => {
  const ret = [];

  const str = digit.toString();
  for (let i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n));
  }

  return ret.join(".");
};

export const formatNumber = (num) => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
