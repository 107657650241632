// LIBS
import immutable from 'immutable'

// REDUX
import initialState from '../../initial-states/courses'
import {
  GET_COURSES_AJAX_SUCCESS,
  GET_COURSES_BY__ID_AJAX_SUCCESS,
  GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_SUCCESS
} from '../../action-types/ajax'
import { HASH_ROUTING_SWITCH_ROUTE } from 'react-redux-hash-router'
import { CLEAR_LIST, SET_ASCENDANT } from 'redux/action-types/global'

export default (state = initialState, action) => {
  switch (action.type) {
    case HASH_ROUTING_SWITCH_ROUTE:
    case CLEAR_LIST:
      state = state.set('page', 0)
      state = state.set('list', immutable.fromJS([]))
      return state
    case GET_COURSES_AJAX_SUCCESS:
      state = state.update('list', list => list.concat(immutable.fromJS(action.data.courses).filter(item => list.indexOf(item) < 0)))
      if (action.requestQueries.sort){
        state = state.set('list', state.get('list').sort((i1, i2) => action.requestQueries.asc ?
          i1.get(action.requestQueries.sort) < i2.get(action.requestQueries.sort)
          : i1.get(action.requestQueries.sort) > i2.get(action.requestQueries.sort)))
      }
      state = state.set('count', action.data.count)
      return state
    case SET_ASCENDANT:
      state = state.set('ascendant', state.get('ascendant').map(() => null))
      state = state.setIn(['ascendant', action.field], action.ascendant)
      return state
    case GET_COURSES_BY__ID_AJAX_SUCCESS:
      state = state.set('current', immutable.fromJS(action.data.course))
      return state
    case GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_AJAX_SUCCESS:
      state = state.set('italy_current', immutable.fromJS(action.data.italy_course))
      return state
    default:
      return state
  }
}
