// ASSETS
import entryAnimation from "assets/animations/entry";
import jobsAnimation from "assets/animations/professioni";
import skilldAnimation from "assets/animations/competenze";
import pfsAnimation from "assets/animations/percorsi";
import alma from "assets/images/logo_alma_laurea.png";
import inapp from "assets/images/logo_inapp.png";
import union from "assets/images/unioncamere.png";
import oecd from "assets/images/oecd_logo_english.png";

// LIBS
import cn from "classnames";

// REACT
import React, { useEffect, useRef, useState } from "react";
import { object, func, string } from "prop-types";
import {
  Button,
  Link,
  Typography,
  MuiThemeProvider,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { translate } from "react-i18next";
import Header from "components/header";
import ChoiceBox from "./components/choice-box";
import Lottie from "react-lottie";
import useWindowSize from "@rehooks/window-size";
import ArrowForward from "components/arrow-forward";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// STYLE
import { common, first, second, third } from "styles/mui-themes";
import s from "./style.module.scss";
import sg from "css/style.module.scss";

const styles = {
  title: {
    display: "inline-block",
    paddingLeft: 60,
    marginLeft: -60,
    "@media (max-width: 768px)": {
      fontSize: 24,
      borderBottomWidth: 5,
      paddingLeft: 20,
      marginLeft: -20,
      marginTop: 20,
    },
  },
  title2: {},
};

function Home(props) {
  const { classes, t } = props;
  const entryAnimationContainer = useRef();
  const choicesContainer = useRef();
  const [w, setW] = useState(0);
  const [h, setH] = useState(0);
  const windowSize = useWindowSize();

  const resize = () => {
    const { width } = entryAnimationContainer.current.getBoundingClientRect();
    setW(width);
    setH((width / 25) * 18);
  };

  useEffect(() => resize(), []);

  useEffect(() => resize(), [windowSize]);

  const goToChoices = () =>
    choicesContainer.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });

  return (
    <MuiThemeProvider theme={common}>
      <Header />
      <div className={sg.Wrapper}>
        <div className={s.FirstSectionContainer}>
          <div className={s.FirstSectionContent}>
            <div className={s.Left}>
              <div className={s.Animation}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: entryAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <Typography
                classes={{
                  root: classes.title,
                }}
                variant="h2"
              >
                {"Piattaforma Competenze e Lavoro"}
              </Typography>
              <div className={s.LeftContent}>
                <Typography className={s.Text} variant={"body1"}>
                  La Piattaforma Competenze e Lavoro nasce da un’iniziativa
                  congiunta di AlmaLaurea, INAPP, Unioncamere e OCSE con
                  l’obiettivo di presentare informazioni sui fabbisogni
                  professionali delle imprese Italiane, sulle competenze
                  necessarie per eseguire bene i compiti di una professione e i
                  percorsi formativi universitari disponibili sul territorio
                  nazionale.
                  <br />
                  Queste informazioni possono aiutare giovani, famiglie,
                  lavoratori e imprese ad allineare l’offerta di competenze alle
                  domande del mercato del lavoro e a trarre vantaggio dalle
                  trasformazioni che stanno rivoluzionando il mondo del lavoro.
                  <br />
                  La piattaforma è accessibile da PC, tablet o smartphone
                  tramite diversi browser, ma per una visualizzazione ottimale,
                  si consiglia l’utilizzo del software Google Chrome su PC/Mac.
                </Typography>
                <MuiThemeProvider theme={common}>
                  <Link
                    color={"secondary"}
                    className={s.Link}
                    href={"#/maggiori-informazioni"}
                    underline={"none"}
                  >
                    <Typography variant={"h4"}>
                      {"Maggiori informazioni >"}
                    </Typography>
                  </Link>
                </MuiThemeProvider>
                <Button
                  className={s.Button}
                  color={"primary"}
                  onClick={() => goToChoices()}
                  variant={"contained"}
                >
                  <Typography
                    className={s.TextButton}
                    color="inherit"
                    variant="h3"
                  >
                    {"INIZIA! Scegli un’area"}
                  </Typography>
                </Button>
                <div className={s.LogosContainer}>
                  <img
                    onClick={() => (window.location.href = "#/info-almalaurea")}
                    alt={"AlmaLaurea"}
                    src={alma}
                  />
                  <img
                    className={s.inappLogo}
                    onClick={() => (window.location.href = "#/info-inapp")}
                    alt={"INAPP"}
                    src={inapp}
                  />
                  <img
                    onClick={() =>
                      (window.location.href = "#/info-unioncamere")
                    }
                    alt={"Unioncamere"}
                    src={union}
                  />
                  <img
                    className={s.oecdLogo}
                    onClick={() => (window.location.href = "#/info-oecd")}
                    alt={"OECD"}
                    src={oecd}
                  />
                </div>
              </div>
            </div>
            <div className={s.Right} ref={entryAnimationContainer}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: entryAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={h}
                width={w}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={sg.Wrapper}>
        <div className={s.SecondSectionContainer} ref={choicesContainer}>
          <Typography
            classes={{
              root: classes.title2,
            }}
            variant="h3"
          >
            {"Scegli l'area con cui avviare la tua ricerca:"}
          </Typography>
          <div className={s.ChoiceContainer}>
            <MuiThemeProvider theme={first}>
              <ChoiceBox
                title={"Professioni"}
                href="#/professioni/ricerca"
                animation={jobsAnimation}
                subtitle={
                  "Informati sui fabbisogni professionali delle imprese italiane e sulle professioni in forte\n" +
                  "domanda sul territorio nazionale e nella tua regione"
                }
                backgroundColor={"#589dde"}
                backgroundImage={
                  "url(\"data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2361a7e8' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E\")"
                }
              />
            </MuiThemeProvider>
            <MuiThemeProvider theme={second}>
              <ChoiceBox
                title={"Competenze"}
                href="#/competenze/ricerca"
                subtitle={
                  "Informati sulle competenze legate alle diverse figure professionali del mercato del\n" +
                  "lavoro italiano"
                }
                animation={skilldAnimation}
                backgroundColor={"#edb52d"}
                backgroundImage={
                  "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f0be43' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
                }
              />
            </MuiThemeProvider>
            <MuiThemeProvider theme={third}>
              <ChoiceBox
                title={"Percorsi Formativi"}
                href="#/percorsi-formativi/ricerca"
                subtitle={
                  "Informati sui corsi universitari disponibili e sui loro sbocchi professionali e la\n" +
                  "soddisfazione degli studenti"
                }
                animation={pfsAnimation}
                backgroundColor={"#ae1c3a"}
                backgroundImage={
                  "url(\"data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23b61f3e' fill-opacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
                }
              />
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

Home.propTypes = {
  classes: object,
  t: func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(Home)));
