// ASSETS
import logo from 'assets/images/logo_payoff_pos.svg'
import ProfessioniIcon from 'assets/icons/categories/_professioni_pos_spessa.svg'
import CompenzeIcon from 'assets/icons/categories/_competenze_pos_spessa.svg'
import PercorsiIcon from 'assets/icons/categories/_percorsi_pos_spessa.svg'

// LIBS
import cn from 'classnames'

// REACT
import React, { useState } from 'react'
import { func, object, string } from 'prop-types'
import { translate } from 'react-i18next'
import {
  AppBar,
  Button,
  ButtonBase,
  Divider,
  Link,
  Menu,
  MenuItem,
  MuiThemeProvider,
  Toolbar, Typography,
  withStyles
} from '@material-ui/core'
import PathIcon from 'components/path-icon'
import Share from '@material-ui/icons/Share'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share'

// REDUX
import { connect } from 'react-redux'
import { getActiveScreen, getActiveScreenPage } from 'redux/getters/routing'

// STYLE
import light, { first, second, third } from 'styles/mui-themes'
import sg from 'css/style.module.scss'
import s from './style.module.scss'

// CONSTANTS

const styles = {
  colorDefault: {
    backgroundColor: 'white'
  },
  button: {
    color: '#CECECE',
  },
  buttonText: {
    marginLeft: 15,
    textTransform: 'none',
    color: 'inherit'
  },
  menu: {
    border: '1px solid #CECECE'
  }
}

function Header({
                  classes,
                  currentPage,
                  currentScreenPage
                }) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  return (
    <MuiThemeProvider theme={light}>
      <AppBar
        classes={{
          root: classes.colorDefault
        }}
        className={s.AppBar}
        color='default'
        position="static"
      >
        <Toolbar className={cn(sg.Wrapper, s.ToolBar)}>
          <Link
            href={'#/'}
            underline={'none'}>
            <img
              alt={'competenze e lavoro'}
              className={s.Logo}
              src={logo}
            />
          </Link>
          {
            currentPage !== 'home' && <div className={s.Buttons}>
              <MuiThemeProvider theme={first}>
                <PathIcon
                  className={s.PathIcon}
                  disabled={currentScreenPage === 'jobsSearch'}
                  href={'#/professioni/ricerca'}
                >
                  {ProfessioniIcon}
                </PathIcon>
              </MuiThemeProvider>
              <MuiThemeProvider theme={second}>
                <PathIcon
                  className={s.PathIcon}
                  disabled={currentScreenPage === 'skillsJobGroups'}
                  href={'#/competenze/aree-professionali'}
                >
                  {CompenzeIcon}
                </PathIcon>
              </MuiThemeProvider>
              <MuiThemeProvider theme={third}>
                <PathIcon
                  className={s.PathIcon}
                  disabled={currentScreenPage === 'coursesSearch'}
                  href={'#/percorsi-formativi/ricerca'}
                >
                  {PercorsiIcon}
                </PathIcon>
              </MuiThemeProvider>
            </div>
          }
          <div className={s.Socials}>
            <Button className={classes.button} onClick={handleClick}>
              <Share /><Typography
              variant='subtitle1'
              classes={{
                root: classes.buttonText
              }}
            >
              Condividi
            </Typography>
            </Button>
          </div>
          <Menu
            classes={{
              paper: classes.menu,
              list: classes.menu
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem disableRipple disableGutters dense>
              <FacebookShareButton
                  url={window.location.href}>
                  <ButtonBase>
                    <FacebookIcon
                      logoFillColor='#CCC'
                      iconBgStyle={{
                        fill: 'white'
                      }}
                      size={40}/>
                  </ButtonBase>
              </FacebookShareButton>
              <div className={s.Divider}/>
              <TwitterShareButton
                  url={window.location.href}>
                  <ButtonBase>
                    <TwitterIcon
                      logoFillColor='#CCC'
                      iconBgStyle={{
                        fill: 'white'
                      }}
                      size={40}/>
                  </ButtonBase>
              </TwitterShareButton>
              <div className={s.Divider}/>
              <LinkedinShareButton
                  url={window.location.href}>
                  <ButtonBase>
                    <LinkedinIcon
                      logoFillColor='#CCC'
                      iconBgStyle={{
                        fill: 'white'
                      }}
                      size={40}/>
                  </ButtonBase>
              </LinkedinShareButton>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}

Header.propTypes = {
  classes: object,
  currentPage: string,
  currentScreenPage: string,
  t: func
}

const mapStateToProps = state => ({
  currentPage: getActiveScreen(state),
  currentScreenPage: getActiveScreenPage(state)
})

export default connect(mapStateToProps)(withStyles(styles)(translate()(Header)))
