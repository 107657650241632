// CONSTANTS
import * as keys from 'constants/translations/keys'

// REACT
import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { Trans, translate } from 'react-i18next'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { HelpOutline as HelpIcon, InfoOutlined as InfoIcon } from '@material-ui/icons'

// LIBS
import cn from 'classnames'

// STYLE
import s from './style.module.scss'

function PageToolbar({
  downloadFile,
  t,
  title,
  tooltipText
}) {
  return (
    <div className={s.Container}>
      <Typography
        variant='h3'
        className={s.Subtitle2}
      >{title}</Typography>
      <Tooltip
        title={
          <Typography variant='h4' color='inherit'>
            {tooltipText}
          </Typography>
        }
      >
        <IconButton aria-label="Help" size="small" className={s.Button} onClick={downloadFile}>
          <HelpIcon className={s.Icon}/>
        </IconButton>
      </Tooltip>
    </div>
  )
}

PageToolbar.propTypes = {
    downloadFile: func,
    t: func,
    title: string,
    tooltipText: string,
}

export default (translate()(PageToolbar))
