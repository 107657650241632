// CONSTANTS
import * as keys from 'constants/translations/keys'

// REACT
import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { Trans, translate } from 'react-i18next'

// LIBS
import cn from 'classnames'

// STYLE
import s from './style.module.scss'

function JobsSvg({
 className
}) {
    return (
  <svg className={className} x="0px" y="0px" viewBox="0 0 1100 1100">
    <g>
      <path d="M1033.15,806.29c-7.91-25.95-22.27-49.77-41.54-68.87c-0.01-0.01-0.02-0.02-0.03-0.03
		c-30.46-30.64-72.68-48.18-115.88-48.18c-0.15,0-0.29,0-0.44,0c-22.46,0.01-44.31,4.49-64.94,13.31c-2.46,1.05-5.32,0.5-7.22-1.4
		l-168.3-168.3c-1.23-1.23-1.93-2.91-1.92-4.65c0-1.75,0.7-3.42,1.94-4.65l223.46-221.78c1.18-1.18,2.77-1.85,4.44-1.9l52.92-1.5
		c4.16-0.13,7.93-2.35,10.06-5.95l75.74-127.36c2.87-4.79,2.12-10.88-1.81-14.82l-65.44-65.45c-3.94-3.93-10.03-4.67-14.8-1.81
		L792.02,158.7c-3.61,2.14-5.83,5.9-5.96,10.08l-1.5,52.72c-0.05,1.68-0.74,3.27-1.92,4.46l-222.9,222.53
		c-2.57,2.56-6.72,2.56-9.28,0L397.48,295.52c-1.51-1.51-2.19-3.66-1.83-5.76c7.47-43.31-2.37-86.94-27.72-122.85
		c-25.34-35.91-63.16-59.8-106.47-67.26c-41.64-7.19-83.99,1.77-119.19,25.21c-5.5,3.72-6.96,11.28-3.21,16.82
		c1.05,1.55,2.47,2.86,4.1,3.78l84.88,47.3c22.14,12.62,26.61,48.47,9.82,78.33c-16.89,30.04-49.84,45.07-71.93,32.82l-78.67-42.6
		c-2.88-1.58-6.15-1.94-9.27-1.04c-3.11,0.91-5.68,2.96-7.24,5.8c-1.12,2.03-1.63,4.31-1.48,6.63
		c5.68,90.56,83.97,159.55,174.52,153.92c18.78-1.18,37.08-5.53,54.4-12.93c2.46-1.05,5.32-0.5,7.22,1.4L444.55,554.2
		c1.23,1.23,1.92,2.9,1.92,4.64c0,1.74-0.69,3.41-1.92,4.64l-60.22,60.22c-2.55,2.55-6.68,2.57-9.26,0.03l-13.65-13.47
		c-6.26-5.99-14.31-8.99-22.38-8.99c-8.09,0-16.2,3.02-22.51,9.06l-18.6,18.6c-11.81,11.5-12.55,30.12-1.76,42.46
		c2.28,2.6,2.14,6.53-0.3,8.97L74.66,901.22c-6.05,5.79-9.43,13.46-9.61,21.7c-0.18,8.23,2.85,16.04,8.55,22
		c0.33,0.35,0.64,0.65,0.95,0.95l63.31,63.32c11.86,12.39,31.42,12.82,43.7,1.06c0.33-0.31,0.64-0.63,0.95-0.95l221.52-221.34
		c2.44-2.44,6.36-2.57,8.97-0.3c12.34,10.8,30.96,10.06,42.4-1.69l18.76-18.76c12.38-11.86,12.82-31.41,1.06-43.7
		c-0.31-0.33-0.63-0.64-0.95-0.95l-13.76-13.76c-2.55-2.54-2.57-6.66-0.05-9.23l59.84-61.15c1.23-1.25,2.91-1.96,4.66-1.98
		c0.01,0,0.03,0,0.04,0c1.74,0,3.41,0.69,4.64,1.92l181.39,181.39c1.5,1.5,2.18,3.64,1.83,5.73c-7.29,43.34,2.74,86.93,28.23,122.73
		c25.49,35.8,63.4,59.53,106.74,66.82c53.03,8.92,107.18-8.7,144.83-47.13c4.23-4.77,3.79-12.45-1.21-16.9
		c-1.3-1.16-2.8-2-4.47-2.52l-105.03-28.16c-12.51-4.02-22.33-13.58-26.56-25.68c-5.89-15.16-6.61-32.33-1.98-48.15
		c4.12-15.38,12.66-29.16,24.02-38.65c11.88-9.93,25.59-13.76,37.66-10.54l103.75,27.94c0.93,0.11,1.88,0.09,2.81-0.04
		c0.34-0.05,0.69-0.05,1.03-0.07c0.05,0,0.1,0,0.15,0c3.18,0,6.19-1.22,8.47-3.45c2.32-2.26,3.62-5.29,3.66-8.53
		c0.02-1.98-0.45-3.97-1.36-5.73C1033.41,807.05,1033.26,806.68,1033.15,806.29z M576.82,465.57l227.76-228.13
		c2.19-2.21,3.44-5.13,3.52-8.25l1.5-49.96c0.07-2.24,1.28-4.3,3.21-5.45l106.03-63.02c2.59-1.54,5.88-1.13,8,1l44.88,44.88
		c2.11,2.11,2.53,5.37,1.03,7.95l-61.71,105.84c-1.13,1.94-3.18,3.17-5.43,3.25l-50.86,1.87c-2.91,0.07-5.58,1.16-7.71,3.01
		c-0.21,0.18-0.43,0.32-0.63,0.51L618.27,507.02c-1.28,1.28-2.96,1.92-4.64,1.92c-1.68,0-3.36-0.64-4.64-1.92l-32.16-32.16
		C574.25,472.29,574.25,468.14,576.82,465.57z M387.22,769.29c-0.24,0.36-0.51,0.7-0.83,1.02l-221.04,221.6
		c-1.28,1.28-2.97,1.93-4.65,1.93c-1.67,0-3.35-0.64-4.63-1.91l-64.14-63.76c-1.24-1.23-1.94-2.91-1.94-4.65
		c0-1.75,0.69-3.42,1.93-4.66l222.34-221.79c2.57-2.56,6.72-2.55,9.28,0.01l32.16,32.16l30.75,31.87
		C388.65,763.36,388.89,766.78,387.22,769.29z M457.12,748.95l-18.14,18.51c-1.23,1.26-2.91,1.96-4.67,1.97c-0.01,0-0.01,0-0.02,0
		c-1.75,0-3.43-0.7-4.66-1.94L316.87,653.98c-2.55-2.57-2.54-6.71,0.02-9.27l18.7-18.7c1.23-1.23,2.9-1.92,4.64-1.92
		c0.01,0,0.01,0,0.02,0c1.75,0.01,3.42,0.71,4.65,1.95l112.2,113.7C459.62,742.29,459.63,746.39,457.12,748.95z M502.7,622.02
		l-60.22,60.21c-1.28,1.28-2.96,1.92-4.64,1.92c-1.68,0-3.36-0.64-4.64-1.92l-32.16-32.16c-2.57-2.57-2.57-6.72,0-9.29l60.21-60.22
		c2.46-2.46,6.82-2.46,9.29,0l32.16,32.16c1.23,1.23,1.92,2.9,1.92,4.64C504.63,619.12,503.93,620.79,502.7,622.02z M995.47,789.85
		c-1.62,2.12-4.36,3.06-6.94,2.36l-67.68-18.32c-4.9-1.32-9.96-2-15.05-2.02c-30.49,2.56-56.91,21.49-69.71,48.31
		c-2.96,6.19-5.19,12.8-6.58,19.73c-0.03,0.16-0.07,0.33-0.12,0.49c-5.95,21.02-4.77,43.75,3.33,64.03
		c7.27,18.97,23.2,33.57,42.65,39.13l69.46,18.67c2.55,0.69,4.44,2.83,4.8,5.45c0.36,2.61-0.88,5.19-3.14,6.54
		c-22.45,13.38-47.16,19.74-71.57,19.74c-47.84,0-94.52-24.44-120.73-68.44c-18.73-31.41-24.42-68.33-16.04-103.94
		c0.92-4.05-0.31-8.27-3.29-11.24L313.33,388.46c-1.88-1.76-4.25-2.82-6.74-3.1c-2.49-0.28-5.09,0.22-7.42,1.54
		c-20.37,10.75-43.09,16.38-65.9,16.38c-0.04,0-0.09,0-0.12,0c-58.78,0-111.7-37.12-131.71-92.41c-0.93-2.58-0.17-5.46,1.92-7.24
		c2.09-1.78,5.06-2.07,7.45-0.74l42.63,23.75c8.87,4.97,18.91,7.59,29.07,7.59c0.08,0,0.16,0,0.25,0
		c25.85-1.49,49.89-14.31,65.85-34.46c3.68-4.65,6.93-9.69,9.68-15.07c23.77-42.48,15.08-92.49-19.24-111.71l-54.45-30.87
		c-2.24-1.27-3.53-3.72-3.3-6.28c0.22-2.56,1.91-4.75,4.33-5.62c72.84-26,153.26,12.07,179.29,84.91
		c9.06,25.37,10.63,52.66,4.54,78.9c-0.92,4.07,0.3,8.29,3.28,11.25l422.45,422.26c3.77,3.77,9.47,4.66,14.2,2.18
		c20.16-10.62,42.88-16.25,65.68-16.26c0.03,0,0.07,0,0.11,0c49.43,0,95.68,26.45,120.75,69.06
		C997.26,784.84,997.09,787.73,995.47,789.85z"/>
      <path
            d="M248.59,299.78c-15.96,20.15-40,32.96-65.85,34.46c0,0,0,0,0,0C208.6,332.74,232.64,319.93,248.59,299.78z"/>
      <path
            d="M836.08,820.17c12.81-26.82,39.22-45.75,69.71-48.31c0,0,0,0,0,0C875.31,774.42,848.89,793.35,836.08,820.17z"
      />
      <path
            d="M313.33,388.46c-1.88-1.77-4.25-2.82-6.74-3.1C309.08,385.64,311.45,386.69,313.33,388.46z"/>
      <path
            d="M854.73,275.56c-2.91,0.07-5.58,1.16-7.71,3.01C849.15,276.72,851.81,275.63,854.73,275.56z"/>
      <path
            d="M387.22,769.29c1.66-2.52,1.42-5.93-0.75-8.18C388.64,763.36,388.89,766.78,387.22,769.29z"/>
    </g>
</svg>
)
}

JobsSvg.propTypes = {
  className: string,
  t: func
}

export default (translate()(JobsSvg))
