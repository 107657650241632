// CONSTANTS
// REACT
import React from 'react'
import { any, bool, func, object, string } from 'prop-types'
import { translate } from 'react-i18next'
import { ButtonBase, withStyles } from '@material-ui/core'
// LIBS
import cn from 'classnames'
// STYLE
import s from './style.module.scss'

const styles = theme => ({
  fabRoot: {
    background: 'white',
    opacity: .4,
    '&:hover': {
      opacity: .8
    }
  },
  fabDisabled: {
    background: 'white !important',
    opacity: 1
  }
})

function PathIcon({
  children,
  classes,
  className,
  disabled,
  href
}) {
  return (
    <ButtonBase
      aria-label='Competenze'
      classes={{
        root: classes.fabRoot,
        disabled: classes.fabDisabled
      }}
      className={cn(className, s.IconButton)}
      disabled={disabled}
      href={href}
    >
      <img className={s.IconImage} src={children} alt="icon" />
    </ButtonBase>
  )
}

PathIcon.propTypes = {
  children: any,
  className: string,
  classes: object,
  disabled: bool,
  href: string,
  path: string,
  t: func
}

export default withStyles(styles)(translate()(PathIcon))
