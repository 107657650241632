// CONSTANTS
import { all } from "redux-saga/effects";

const jobsLabels = require("constants/jobs-labels");
const courserTooltips = require("constants/courses-tooltips");
const coursesTypesLabels = require("constants/courses-types-labels");
const coursesPLabels = require("constants/courses-labels-p");
const coursesOccLabels = require("constants/courses-labels-occ");

// LIBS

// REACT
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import useWindowSize from "@rehooks/window-size";
import { bool, func, object, string } from "prop-types";
import { translate } from "react-i18next";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import PageToolbar from "components/page-toolbar";
import cn from "classnames";
import ArrowBack from "components/arrow-back";
import {
  CircularProgress,
  FormControl,
  Icon,
  Link,
  MuiThemeProvider,
  OutlinedInput,
  Paper,
  Select,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAjaxLoading, getRestUri } from "redux/getters/global";
import { getCoursesParams } from "redux/getters/routing";
import { getCoursesAjax } from "redux/actions/ajax";
import { clearList } from "redux/actions/global";
import {
  getCourse,
  getCoursesList,
  getItalyCourse,
} from "redux/getters/courses";
import { getCourseFromRegionAndItaly } from "redux/actions/courses";

// STYLE
import s from "./style.module.scss";
import sa from "css/atomic.module.scss";
import { common } from "styles/mui-themes";
import { formatDigit, formatNumber } from "../../../../services/formatter";

HC_more(Highcharts);

const styles = (theme) => ({});

function CoursesUniversity({
  course,
  coursesLoading,
  courseByIDLoading,
  italyCourseLoading,
  getCourseFromRegionAndItaly,
  getCoursesAjax,
  clearList,
  italyCourse,
  coursesList,
  restUri,
  params,
}) {
  const [selectUniversity, setSelectUniversity] = useState(null);
  const [selectedOccZero, setSelectedOccZero] = useState(false);
  const [selectedPZero, setSelectedPZero] = useState(false);
  const chartRef = useRef(null);
  const [page, setPage] = useState(0);

  const coursesListFiltered = useMemo(
    () =>
      coursesList
        ? coursesList
            .filter((c) => c.get("_id") !== course.get("_id"))
            .sort(
              (a, b) =>
                (a.get("regionId") - b.get("regionId")) * 10000 +
                a.get("nomeateneo").localeCompare(b.get("nomeateneo")),
            )
        : null,
    [coursesList, page],
  );

  const coursesListPaginated = useMemo(
    () =>
      coursesListFiltered
        ? coursesListFiltered.slice(page * 10, (page + 1) * 10)
        : null,
    [coursesListFiltered, page],
  );

  const coursesListCount = useMemo(
    () =>
      coursesList
        ? coursesList.filter((c) => c.get("_id") !== course.get("_id")).size
        : 0,
    [coursesList],
  );

  useEffect(() => {
    getCourseFromRegionAndItaly();
  }, [params]);

  useEffect(() => {
    clearList();
    // Ricerca testuale
    if (params.get("jobId") === "null") {
      if (course && course.get("_id").toString() === params.get("_id")) {
        getCoursesAjax(restUri, {
          query: {
            regionId:
              params.get("regionId") !== "null" ? params.get("regionId") : 999,
            codclasse1_miur: course.get("codclasse1_miur"),
            jobId: params.get("jobId") !== "null" ? params.get("jobId") : null,
            _id: params.get("_id") !== "null" ? params.get("_id") : null,
          },
        });
      }
    } else {
      getCoursesAjax(restUri, {
        query: {
          regionId:
            params.get("regionId") !== "null" ? params.get("regionId") : 999,
          jobId: params.get("jobId") !== "null" ? params.get("jobId") : null,
          codtipolau: params.get("codtipolau"),
        },
      });
    }
  }, [params, course]);

  useEffect(() => {
    if (course && italyCourse) {
      let occAllZero = true;
      let pAllZero = true;
      for (let z = 1; z <= 8; z++) {
        if (occAllZero) {
          if (course.get(`occ${z}`) && course.get(`occ${z}`) !== 0) {
            occAllZero = false;
          }
          if (italyCourse.get(`occ${z}`) && italyCourse.get(`occ${z}`) !== 0) {
            occAllZero = false;
          }
        }
        if (pAllZero) {
          if (course.get(`p${z}`) && course.get(`p${z}`) !== 0) {
            pAllZero = false;
          }
          if (italyCourse.get(`p${z}`) && italyCourse.get(`p${z}`) !== 0) {
            pAllZero = false;
          }
        }
      }
      setSelectedPZero(pAllZero);
      setSelectedOccZero(occAllZero);
    }
  }, [course, italyCourse]);
  useEffect(() => {
    if (selectUniversity) {
      window.location.href = `/#/percorsi-formativi/ateneo/${selectUniversity}/${params.get(
        "regionId",
      )}/${params.get("codtipolau")}/${params.get("jobId")}/${
        params.get("coursesText") ?? null
      }`;
    }
  }, [selectUniversity]);

  const renderSection = (path) => {
    if (
      (path === "p" && !selectedPZero) ||
      (path === "occ" && !selectedOccZero)
    ) {
      return renderRows(path);
    }
  };

  const allProfiloValuesNull = useMemo(() => {
    let result = 0;
    if (course) {
      for (let i = 1; i < 9; i++) {
        result += course.get(`p${i}`);
      }
    }
    return !Boolean(result);
  }, [course]);

  const allOccupazioneValuesNull = useMemo(() => {
    let result = 0;
    if (course) {
      for (let i = 1; i < 9; i++) {
        result += course.get(`occ${i}`);
      }
    }
    return !Boolean(result);
  }, [course]);

  const percOccIntervMinorThan50 = useMemo(() => {
    if (course) {
      return (
        course.get("occnuminterv") /
          (course.get("occnumlau") !== 0 ? course.get("occnumlau") : 1) <
        0.5
      );
    }
    return false;
  }, [course]);

  const percPIntervMinorThan50 = useMemo(() => {
    if (course) {
      return (
        course.get("pnuminterv") /
          (course.get("pnumlau") !== 0 ? course.get("pnumlau") : 1) <
        0.5
      );
    }
    return false;
  }, [course]);

  const percOccInterv = useMemo(() => {
    if (course) {
      return (
        (course.get("occnuminterv") /
          (course.get("occnumlau") !== 0 ? course.get("occnumlau") : 1)) *
        100
      ).toFixed(1);
    }
    return 0;
  }, [course]);

  const percPInterv = useMemo(() => {
    if (course) {
      return (
        (course.get("pnuminterv") /
          (course.get("pnumlau") !== 0 ? course.get("pnumlau") : 1)) *
        100
      ).toFixed(1);
    }
    return 0;
  }, [course]);

  const renderRows = (path) => {
    let table = [];
    if (course && italyCourse) {
      for (let i = 1; i <= 4; i++) {
        let row = [];
        for (let j = 0; j < 2; j++) {
          let k = i * 2 - 1 + j;
          let categories = [];
          let data = [];
          categories.push("CORSO");
          data.push({
            y: course.get(`${path}${k}`) !== 0 ? course.get(`${path}${k}`) : 0,
            color: {
              linearGradient: {
                x1: 0,
                y1: 1,
              },
              stops: [
                path === "p"
                  ? [0, "rgba(187, 46, 61, 1)"]
                  : [0, "rgba(187, 46, 61, 1)"],
                path === "p"
                  ? [1, "rgba(187, 46, 61, 0.8)"]
                  : [1, "rgba(187, 46, 61, 0.8)"],
              ],
            },
          });
          if (italyCourse.get(`${path}${k}`) !== 0) {
            categories.push("CLASSE NAZIONALE");
            data.push({
              y:
                italyCourse.get(`${path}${k}`) !== 0
                  ? italyCourse.get(`${path}${k}`)
                  : null,
              color: {
                linearGradient: {
                  x1: 0,
                  y1: 1,
                },
                stops: [
                  path === "p"
                    ? [0, "rgba(187, 46, 61, 0.5)"]
                    : [0, "rgba(187, 46, 61, 0.5)"],
                  path === "p"
                    ? [1, "rgba(187, 46, 61, 0.3)"]
                    : [1, "rgba(187, 46, 61, 0.3)"],
                ],
              },
            });
          }
          row.push(
            <div
              className={
                j === 0
                  ? i === 4
                    ? cn(s.TableCell, s.TableCellLeft)
                    : cn(s.TableCell, s.TableCellLeft, s.TableCellNotLastRow)
                  : i === 4
                  ? cn(s.TableCell)
                  : cn(s.TableCell, s.TableCellNotLastRow)
              }
            >
              <Tooltip
                title={
                  <Typography variant="h4" color="inherit">
                    {courserTooltips[`${path}${k}`]}
                  </Typography>
                }
              >
                <div className={s.SectionTitle}>
                  <img
                    src={
                      require(`assets/icons/courses/${path}/${k - 1}.png`)
                        ?.default
                    }
                    width={20}
                    height={20}
                  />
                  <Typography className={s.CellTitle} variant="body2">
                    {path === "p"
                      ? coursesPLabels[`${path}${k}`]
                      : coursesOccLabels[`${path}${k}`]}
                  </Typography>
                </div>
              </Tooltip>
              {!(path === "occ" && allOccupazioneValuesNull) &&
              !(path === "p" && allProfiloValuesNull) &&
              (course.get(`${path}${k}`) !== 0 ||
                italyCourse.get(`${path}${k}`) !== 0) &&
              !(path === "p" && course.get("pnuminterv") === 0) &&
              !(path === "occ" && course.get("occnuminterv") === 0) &&
              !(path === "p" && course.get("pnumlau") < 5) &&
              !(path === "occ" && course.get("occnumlau") < 5) &&
              !(
                path === "occ" &&
                k === 1 &&
                params.get("codtipolau") === "LS"
              ) ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  ref={chartRef}
                  options={{
                    chart: {
                      type: "bar",
                      height: data.length === 2 ? 60 : 42,
                      marginLeft: 130,
                    },
                    title: {
                      text: "",
                    },
                    xAxis: {
                      categories,
                      labels: {
                        formatter() {
                          return `<span style="font-weight: ${
                            this.value !== "CLASSE NAZIONALE" ? 900 : 400
                          };color: ${
                            this.value === "CLASSE NAZIONALE"
                              ? path === "p"
                                ? "rgba(187, 46, 61, 0.8)"
                                : "rgba(187, 46, 61, 0.8)"
                              : path === "p"
                              ? "rgba(187, 46, 61, 1)"
                              : "rgba(187, 46, 61, 1)"
                          }">${this.value}</span>`;
                        },
                      },
                    },
                    legend: {
                      enabled: false,
                    },
                    yAxis: {
                      min: 0,
                      max:
                        path === "occ" && k === 5
                          ? null
                          : path === "occ" && k === 8
                          ? 11
                          : 110,
                      visible: false,
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          enabled: true,
                          formatter() {
                            return this.y === 0
                              ? "-"
                              : path === "occ" && k === 5
                              ? formatNumber(this.y.toFixed(0))
                              : this.y.toFixed(1);
                          },
                        },
                      },
                      series: {
                        dataLabels: {
                          enabled: true,
                          overflow: "allow",
                          crop: false,
                        },
                        states: { hover: { enabled: false } },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    tooltip: { enabled: false },
                    series: [
                      {
                        data,
                      },
                    ],
                  }}
                />
              ) : (
                <Typography
                  variant="subtitle1"
                  className={s.TableDisclaimerText}
                >
                  {path === "occ" &&
                  k === 1 &&
                  params.get("codtipolau") === "LS"
                    ? "Statistica non disponibile per i laureati di secondo livello"
                    : "Statistica non disponibile"}
                </Typography>
              )}
            </div>,
          );
        }
        table.push(<div className={s.TableRow}>{row}</div>);
      }
      return <div className={s.Table}> {table}</div>;
    }
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = "NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.href = "/NOTE ALLA LETTURA DEGLI INDICATORI ALMALAUREA.pdf";
    link.click();
  };

  const nomeCorsoCompleto = course
    ? course.get("nomeclasse").charAt(0).toUpperCase() +
      course.get("nomeclasse").slice(1).replace(/\./, ",").replace(/\d/, "-$&")
    : "";

  return (
    <div className={s.Container}>
      <PageToolbar
        title={"Scopri i Corsi di Laurea a cui puoi iscriverti"}
        tooltipText={"Scarica il PDF con le note alla lettura."}
        downloadFile={onDownload}
      />
      <Paper className={s.Paper}>
        <MuiThemeProvider theme={common}>
          <Link
            color={"secondary"}
            href={"/#/percorsi-formativi/ricerca"}
            underline={"none"}
          >
            <ArrowBack />
            <Typography variant="h4">{"Home Percorsi Formativi"}</Typography>
          </Link>
        </MuiThemeProvider>
        <Typography className={s.Title} variant="h3">
          {course && course.get("nomecorso")}
        </Typography>
        <Tooltip
          title={
            <Typography variant="h4" color="inherit">
              CLASSE NAZIONALE si riferisce al complesso dei laureati in tutti i
              corsi di laurea in Italia afferenti alla classe di laurea indicata
              per cui l&#39;informazione è disponibile.
            </Typography>
          }
        >
          <Typography variant="subtitle1">
            {course && nomeCorsoCompleto}
          </Typography>
        </Tooltip>
        <div className={cn(sa.DF, sa.AIFE, sa.JCSB)}>
          <Typography variant="subtitle1">
            {`${coursesTypesLabels[params.get("codtipolau")]} - ${
              course && course.get("nomeateneo")
            }`}
          </Typography>
        </div>
        <Link
          className={s.Link}
          underline={"none"}
          href={`#/professioni/corso/${
            course && course.get("regionId")
          }/${params.get("codtipolau")}/${course && course.get("_id")}`}
        >
          {
            "Vuoi scoprire gli sbocchi professionali, la domanda di lavoro e i fabbisogni di competenze? Esplora qui >"
          }
        </Link>
        <div className={s.ResultRow}>
          <div className={s.CoursesContainer}>
            <Typography
              variant="h4"
              className={cn(s.Typography, s.FontReducer)}
            >
              {jobsLabels[params.get("jobId")]
                ? "Altri risultati per:"
                : "Altri risultati per la tua ricerca:"}
            </Typography>
            <Typography variant="subtitle1" className={s.FontReducer}>
              {jobsLabels[params.get("jobId")]}{" "}
              {params.get("jobId") !== "null" && (
                <Fragment>({formatDigit(params.get("jobId"))})</Fragment>
              )}
            </Typography>
            <Typography variant="subtitle1" className={s.FontReducer}>
              {jobsLabels[params.get("jobId")]
                ? coursesTypesLabels[params.get("codtipolau")]
                : `${
                    coursesTypesLabels[params.get("codtipolau")]
                  } in "${decodeURIComponent(
                    params.get("coursesText"),
                  )}" della classe di laurea "${nomeCorsoCompleto}"`}
            </Typography>
            {coursesListPaginated &&
              coursesListPaginated.map((course) => (
                <Link
                  className={s.SideBarLink}
                  href={`/#/percorsi-formativi/ateneo/${course.get(
                    "_id",
                  )}/${params.get("regionId")}/${params.get(
                    "codtipolau",
                  )}/${params.get("jobId")}/${
                    params.get("coursesText") ?? null
                  }`}
                  key={course.get("_id")}
                  underline={"none"}
                >
                  {}
                  <br />
                  {}
                  <Typography
                    color="primary"
                    variant="h4"
                    className={s.CourseLink}
                  >
                    {course.get("nomecorso")}
                  </Typography>
                  <Typography color="primary" variant="h4">
                    {course.get("nomeateneo")}
                  </Typography>
                </Link>
              ))}

            {page > 0 && (
              <Link onClick={() => setPage((p) => p - 1)}>
                <Typography
                  className={s.AlmaLaureaPaginationLink}
                  color="primary"
                  variant="subtitle1"
                >
                  {"← Precedenti"}
                </Typography>
              </Link>
            )}
            {(page + 1) * 10 < coursesListCount && (
              <Link onClick={() => setPage((p) => p + 1)}>
                <Typography
                  className={s.AlmaLaureaPaginationLink}
                  color="primary"
                  variant="subtitle1"
                >
                  {"Successivi →"}
                </Typography>
              </Link>
            )}
          </div>
          <div className={s.CoursesContainerMobile}>
            <Typography
              variant="h4"
              className={cn(s.Typography, s.FontReducer)}
            >
              {jobsLabels[params.get("jobId")]
                ? "Altri risultati per:"
                : "Altri risultati per la tua ricerca:"}
            </Typography>
            <Typography variant="subtitle1" className={s.FontReducer}>
              {jobsLabels[params.get("jobId")]}
              {params.get("jobId") !== "null" && (
                <Fragment>({formatDigit(params.get("jobId"))})</Fragment>
              )}
            </Typography>
            <Typography variant="subtitle1" className={s.FontReducer}>
              {coursesTypesLabels[params.get("codtipolau")]}
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                native
                value={selectUniversity || ""}
                onChange={(e) => setSelectUniversity(e.target.value)}
                input={
                  <OutlinedInput
                    color="primary"
                    fullWidth
                    name="university"
                    id="university-select"
                  />
                }
              >
                <option value="-1">Seleziona un corso</option>
                {coursesListFiltered &&
                  coursesListFiltered.map((course) => {
                    return (
                      <option key={course.get("_id")} value={course.get("_id")}>
                        {`${course.get("nomecorso")} - ${course.get(
                          "nomeateneobreve",
                        )} `}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          {!courseByIDLoading && !coursesLoading && !italyCourseLoading ? (
            <div className={s.TableContainer}>
              {!selectedPZero &&
                percPIntervMinorThan50 &&
                !(course.get("occnumlau") < 5 && course.get("pnumlau") < 5) &&
                course.get("pnuminterv") !== 0 &&
                course.get("pnuminterv") < 5 && (
                  <Typography variant="h6" className={sa.MB30}>
                    Tasso di risposta nell’indagine sul Profilo dei Laureati:{" "}
                    {percPInterv}%.
                    <br />
                    Prestare cautela nell&apos;interpretazione dei dati
                  </Typography>
                )}
              {!selectedPZero && (
                <Fragment>
                  <div className={s.TableTitle}>
                    <Typography
                      variant="subtitle1"
                      className={s.TableTitleText}
                    >
                      <img
                        className={s.TableTitleImg}
                        src={require("assets/icons/profilo.png")?.default}
                        width={40}
                        height={40}
                      />
                      {`Profilo dei laureati - numero di intervistati: ${
                        course && course.get("pnuminterv")
                      } `}
                    </Typography>
                    {course && course.get("link1") && (
                      <Link
                        target="_blank"
                        href={course.get("link1")}
                        underline={"none"}
                      >
                        <Typography
                          className={s.AlmaLaureaLink}
                          color="primary"
                          variant="subtitle1"
                        >
                          {"Approfondisci"}
                        </Typography>
                      </Link>
                    )}
                  </div>
                  {renderSection("p")}
                </Fragment>
              )}
              {!selectedOccZero &&
                percOccIntervMinorThan50 &&
                !(course.get("occnumlau") < 5 && course.get("pnumlau") < 5) &&
                course.get("occnuminterv") !== 0 &&
                course.get("occnuminterv") < 5 && (
                  <Typography variant="h6" className={sa.MB30}>
                    Tasso di risposta nell’indagine sulla Condizione
                    occupazionale dei laureati a un anno dalla laurea:{" "}
                    {percOccInterv}%.
                    <br />
                    Prestare cautela nell&apos;interpretazione dei dati
                  </Typography>
                )}
              {!selectedOccZero && (
                <Fragment>
                  <div className={s.TableTitle}>
                    <Typography
                      variant="subtitle1"
                      className={s.TableTitleText}
                    >
                      <img
                        className={s.TableTitleImg}
                        src={require("assets/icons/competenze.png")?.default}
                        width={40}
                        height={40}
                      />
                      {`Condizione occupazionale dei laureati a un anno dalla laurea - numero di intervistati: ${
                        course && course.get("occnuminterv")
                      } `}
                    </Typography>
                    {course && course.get("link2") && (
                      <Link
                        target="_blank"
                        href={course.get("link2")}
                        underline={"none"}
                      >
                        <Typography
                          className={s.AlmaLaureaLink}
                          color="primary"
                          variant="subtitle1"
                        >
                          {"Approfondisci"}
                        </Typography>
                      </Link>
                    )}
                  </div>

                  {renderSection("occ")}
                </Fragment>
              )}

              {course &&
                course.get("occnumlau") < 5 &&
                course.get("pnumlau") < 5 && (
                  <Typography variant="subtitle1" className={s.TableTitleText}>
                    Non sono disponibili statistiche per il corso di laurea
                    selezionato. Potrebbe trattarsi di dati relativi ad un corso
                    di un ateneo non presente in AlmaLaurea, di un corso di
                    nuova attivazione o con un numero di laureati inferiore a 5
                    unità. Per maggiori informazioni, consultare le note alla
                    lettura{" "}
                    <Link className={s.textlink} onClick={onDownload}>
                      qui.
                    </Link>
                  </Typography>
                )}
            </div>
          ) : (
            <CircularProgress
              className={s.CircularProgress}
              color="primary"
              size={100}
              thickness={2}
            />
          )}
        </div>
      </Paper>
    </div>
  );
}

CoursesUniversity.propTypes = {
  clearList: func,
  course: object,
  courseByIDLoading: bool,
  coursesList: object,
  coursesLoading: bool,
  getCourseFromRegionAndItaly: func,
  getCoursesAjax: func,
  italyCourse: object,
  italyCourseLoading: bool,
  params: object,
  restUri: string,
  t: func,
};

const mapStateToProps = (state) => ({
  course: getCourse(state),
  coursesLoading: getAjaxLoading(state).get("GET_COURSES_1"),
  courseByIDLoading: getAjaxLoading(state).get("GET_COURSES_BY_ID_1"),
  italyCourseLoading: getAjaxLoading(state).get(
    "GET_ITALY_COURSES_BY_CODALMA_CLASSECONFRONTO_1",
  ),
  italyCourse: getItalyCourse(state),
  restUri: getRestUri(state),
  params: getCoursesParams(state),
  coursesList: getCoursesList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCourseFromRegionAndItaly,
      getCoursesAjax,
      clearList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(CoursesUniversity)));
