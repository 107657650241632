// CONSTANTS
const skillsLabels = require("constants/skills-labels");
const skillsDescriptions = require("constants/skills-descriptions");

// REACT
import React, { useState } from "react";
import { func, array, string, object, bool } from "prop-types";
import { Trans, translate } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// STYLE
import s from "./style.module.scss";
import { Link, Typography, withStyles } from "@material-ui/core";

const skillTitles = [
  "Conoscenza più importante – ",
  "Skill più importante – ",
  "Attitudine più importante – ",
];

const skillDesc = [
  "Scopri la Conoscenza",
  "Scopri la Skill",
  "Scopri l'Attitudine",
];

const styles = (theme) => ({
  SkillSvg: {
    fill: theme.palette.primary.main,
  },
});

function SkillsList({ selectedSkills, showTitle, showLink }) {
  return (
    <div className={s.Details}>
      {selectedSkills.map((skill, index) => (
        <div key={skill} className={s.Info}>
          <Typography variant="subtitle1" className={s.Subtitle}>
            {showTitle && skillTitles[index]}
            {skillsLabels[skill]}
            {showLink && (
              <Link
                underline="none"
                href={`#/competenze/aree-professionali/${skill}`}
              >
                {" "}
                - {skillDesc[index]}
              </Link>
            )}
          </Typography>
          <Typography className={s.Text}>
            {skillsDescriptions[skill]}
          </Typography>
        </div>
      ))}
    </div>
  );
}

SkillsList.propTypes = {
  className: string,
  classes: object,
  selectedSkills: array,
  showLink: bool,
  showTitle: bool,
  t: func,
};

SkillsList.defaultPropTypes = {
  showTitle: true,
  showLink: false,
};

export default withStyles(styles)(translate()(SkillsList));
