import _ from "lodash";
import { createMuiTheme } from "@material-ui/core/styles";

const themeBase = {
  shadows: [
    "none",
    "0px 1px 3px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.04),0px 2px 1px -1px rgba(0,0,0,0.02)",
    "0px 1px 5px 0px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.04),0px 3px 1px -2px rgba(0,0,0,0.02)",
    "0px 1px 8px 0px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.04),0px 3px 3px -2px rgba(0,0,0,0.02)",
    "0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.02)",
    "0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.04),0px 1px 14px 0px rgba(0,0,0,0.02)",
    "0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.04),0px 1px 18px 0px rgba(0,0,0,0.02)",
    "0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.04),0px 2px 16px 1px rgba(0,0,0,0.02)",
    "0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.02)",
    "0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.04),0px 3px 16px 2px rgba(0,0,0,0.02)",
    "0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.04),0px 4px 18px 3px rgba(0,0,0,0.02)",
    "0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.04),0px 4px 20px 3px rgba(0,0,0,0.02)",
    "0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.04),0px 5px 22px 4px rgba(0,0,0,0.02)",
    "0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.04),0px 5px 24px 4px rgba(0,0,0,0.02)",
    "0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.04),0px 5px 26px 4px rgba(0,0,0,0.02)",
    "0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.04),0px 6px 28px 5px rgba(0,0,0,0.02)",
    "0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.02)",
    "0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.04),0px 6px 32px 5px rgba(0,0,0,0.02)",
    "0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.04),0px 7px 34px 6px rgba(0,0,0,0.02)",
    "0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.04),0px 7px 36px 6px rgba(0,0,0,0.02)",
    "0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.04),0px 8px 38px 7px rgba(0,0,0,0.02)",
    "0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.04),0px 8px 40px 7px rgba(0,0,0,0.02)",
    "0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.04),0px 8px 42px 7px rgba(0,0,0,0.02)",
    "0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.04),0px 9px 44px 8px rgba(0,0,0,0.02)",
    "0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.02)",
  ],
  palette: {
    type: "light",
    background: {
      paper: "#fff",
    },
    buttonText: "#455a68",
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Nunito-Regular", sans-serif',
    h2: {
      fontFamily: '"Tofino-Bold", sans-serif',
      fontSize: "3em",
      letterSpacing: ".1em",
    },
    h3: {
      fontFamily: '"Tofino-Bold", sans-serif',
      fontSize: 24,
      fontWeight: "normal",
      lineHeight: "1.4em",
      letterSpacing: ".07em",
      "@media (max-width: 768px)": {
        fontSize: 18,
      },
    },
    h4: {
      fontFamily: '"Nunito-Regular", sans-serif',
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "1.4em",
      display: "inline",
      color: "#5bbb73",
    },
    h5: {
      fontFamily: '"Tofino-Bold", sans-serif',
      fontSize: 28,
      fontWeight: "normal",
    },
    h6: {
      fontFamily: '"Tofino-Regular", sans-serif',
      fontSize: 16,
      fontWeight: "normal",
      color: "#3F5A6A",
      opacity: 0.7,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: "0.800rem",
      lineHeight: 1.4,
      margin: "10px 0",
    },
    subtitle1: {
      fontFamily: '"Tofino-Bold", sans-serif',
      fontSize: 16,
      fontWeight: "normal",
    },
    subtitle2: {
      fontFamily: '"Tofino-Book", sans-serif',
      fontSize: 28,
      fontWeight: "normal",
      color: "#5E5E62",
    },
    caption: {
      fontSize: 12,
    },
  },
};

export default createMuiTheme(themeBase);

export const first = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      primary: {
        main: "#459ddb",
      },
    },
  }),
);

export const second = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      primary: {
        main: "#f5b942",
        contrastText: "#fff",
      },
    },
  }),
);

export const third = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      primary: {
        main: "#bb2e3d",
      },
      secondary: {
        main: "#fff",
      },
    },
  }),
);

export const common = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      primary: {
        main: "#455a68",
      },
      secondary: {
        main: "#5bbb73",
      },
    },
    typography: {
      h4: {
        color: "#5bbb73",
      },
    },
  }),
);

export const chartColors = {
  chart_1: "#459ddb",
  chart_2: "#f5b942",
  chart_3: "#bb2e3d",
  chart_4: "#5bbb73",
  chart_fill_background: "#F2F2F2",
};
