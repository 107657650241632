// CONSTANTS
import { chartColors, common } from "styles/mui-themes";
// REACT
import React, { Fragment, useEffect, useState } from "react";
import { func, number, object, string, bool } from "prop-types";
import { translate } from "react-i18next";
import {
  Button,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  FormControl,
  Link,
  MuiThemeProvider,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import Spot from "components/spot";
import ItalyMap from "components/italy-map";
import PageToolbar from "components/page-toolbar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBack from "components/arrow-back";
// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getJobsParams } from "redux/getters/routing";
import { getJobsAjax } from "redux/actions/ajax";
import { getAjaxLoading, getRestUri } from "redux/getters";
import {
  getAscendant,
  getCount,
  getJobsList,
  getPage,
} from "redux/getters/jobs";
import { clearList, setAscendant, setPage } from "redux/actions";
import { getGroupedSkills } from "redux/getters/skills";
// STYLE
import s from "./style.module.scss";
import sa from "css/atomic.module.scss";
import cn from "classnames";
import { formatDigit, formatNumber } from "../../../../services/formatter";

const jobs = require("constants/jobs-labels");
const jobsGroups = require("constants/jobs-groups-labels");
const regions = require("constants/regions-labels");
const strings = require("constants/strings");
const jobGroupsLabels = require("constants/jobs-groups-labels");

const styles = (theme) => ({
  tableRoot: {
    "& td, th": {
      padding: "15px 8px",
    },
  },
  title: {},
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
});

function JobsGroupsResults({
  ascendant,
  classes,
  clearList,
  count,
  restUri,
  getJobsAjax,
  jobsList,
  jobsParams,
  page,
  setPage,
  setAscendant,
  groupedSkills,
  jobsLoading,
}) {
  const LIMIT = 4;

  const [selectedRegion, setSelectedRegion] = useState(
    jobsParams.get("regionId") === "999"
      ? null
      : parseInt(jobsParams.get("regionId"), 10),
  );

  useEffect(() => {
    setSelectedRegion(
      jobsParams.get("regionId") === "999"
        ? null
        : parseInt(jobsParams.get("regionId"), 10),
    );
    jobsList.size <= (page + 1) * LIMIT &&
      getJobsAjax(restUri, {
        query: {
          regionId: jobsParams.get("regionId"),
          jobGroupId: jobsParams.get("jobGroupId"),
          skip: page * LIMIT,
          limit: LIMIT,
          sort: ascendant
            .map((item) => (item !== null ? (item ? "ASC" : "DESC") : null))
            .toJS(),
        },
      });
  }, [jobsParams, page, ascendant]);

  useEffect(() => {
    window.location.href = `#/professioni/gruppi/${
      selectedRegion || "999"
    }/${jobsParams.get("jobGroupId")}`;
  }, [selectedRegion]);

  function onSort(sortBy) {
    setPage(0);
    clearList();
    setAscendant(
      sortBy,
      ascendant.get(sortBy)
        ? false
        : ascendant.get(sortBy) !== null
        ? null
        : true,
    );
  }

  return (
    <div className={s.Container}>
      <PageToolbar
        title={
          "Scopri come una professione si inserisce nel mercato del lavoro"
        }
        tooltipText={
          "I risultati di questa pagina provengono dal Sistema informativo Excelsior. Questo mette a disposizione dati, approfondimenti e altri strumenti informativi sulla domanda di professioni e competenze delle imprese italiane e sull’individuazione delle principali aree di mismatch tra domanda e offerta di lavoro e le possibili misure di intervento atte a colmarlo, nonché guide, manuali e altri strumenti informativi di sistema a supporto dell’orientamento scolastico, universitario e lavorativo. L’indagine valorizza in modo integrato le informazioni desunte dagli archivi amministrativi camerali su imprese e occupazione e coinvolge annualmente un campione di oltre 500.000 imprese di tutti i settori economici e di tutte le dimensioni. L’elevato numero di interviste e la metodologia adottata per l’elaborazione dei dati consentono di ottenere dati previsionali mensili e annuali sui fabbisogni professionali richiesti dalle imprese per tutte le province italiane. "
        }
      />
      <Paper className={s.Paper}>
        <div className={cn(s.GlobalRow, sa.AIFE)}>
          <div className={s.GlobalBlock}>
            <div className={sa.MB30}>
              <MuiThemeProvider theme={common}>
                <Link
                  color={"secondary"}
                  href={"#/competenze/aree-professionali"}
                  underline={"none"}
                >
                  <ArrowBack />
                  <Typography variant="h4">{"Home Competenze"}</Typography>
                </Link>
              </MuiThemeProvider>
            </div>
            <Typography
              classes={{
                root: classes.title,
              }}
              variant="h5"
            >
              {jobsGroups[jobsParams.get("jobGroupId")] ||
                strings[jobsParams.get("jobGroupId")]}{" "}
              ({formatDigit(jobsParams.get("jobGroupId"))})
            </Typography>
          </div>
          <div className={s.Groups}>
            {groupedSkills.size !== 0 && (
              <Fragment>
                <Typography className={s.LinkTitle} variant={"h6"}>
                  {"Altri risultati in base alle competenze cercate:"}
                </Typography>
                {groupedSkills.map(
                  (skill) =>
                    skill.get("jobGroupId") !==
                      parseInt(jobsParams.get("jobGroupId"), 10) && (
                      <Link
                        color={"primary"}
                        variant={"h6"}
                        className={s.Link}
                        href={`#/professioni/gruppi/${
                          selectedRegion || "999"
                        }/${skill.get("jobGroupId")}`}
                        underline={"none"}
                      >
                        <Typography
                          color={"inherit"}
                          variant="h6"
                          className={s.TypographyLink}
                          key={skill}
                        >
                          - {jobGroupsLabels[skill.get("jobGroupId")]}
                        </Typography>
                      </Link>
                    ),
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div className={s.GlobalRow}>
          <div className={s.Left}>
            {
              <Typography variant={"subtitle1"}>
                Seleziona una regione
              </Typography>
            }
            <Typography className={s.CurrentView} variant={"h6"}>
              {"Stai visualizzando:"}
            </Typography>
            {selectedRegion ? (
              <div className={s.Region}>
                <MuiThemeProvider theme={common}>
                  <Link
                    color={"secondary"}
                    classes={{
                      root: classes.linkRoot,
                    }}
                    onClick={() => setSelectedRegion(null)}
                    underline={"none"}
                  >
                    <Typography color={"inherit"} variant={"h6"}>
                      {"Tutta Italia >"}
                    </Typography>
                  </Link>
                </MuiThemeProvider>
                &nbsp;
                <Typography
                  classes={{
                    root: classes.regionText,
                  }}
                >
                  {regions[selectedRegion]}
                </Typography>
              </div>
            ) : (
              <Typography variant={"h6"}>{"Tutta Italia"}</Typography>
            )}
            <FormControl className={s.RegionSelect} fullWidth variant="filled">
              <Select
                native
                value={selectedRegion || ""}
                onChange={(e) => setSelectedRegion(e.target.value)}
                input={
                  <OutlinedInput
                    color="primary"
                    fullWidth
                    name="region"
                    id="region-select"
                  />
                }
              >
                <option value="999">{"Seleziona una Regione"}</option>
                {Object.keys(regions).map((regionId) => {
                  if (regionId !== "999") {
                    return (
                      <option key={regionId} value={regionId}>
                        {regions[regionId]}
                      </option>
                    );
                  }
                })}
              </Select>
            </FormControl>
            <MuiThemeProvider theme={common}>
              <ItalyMap
                className={s.Map}
                selected={selectedRegion}
                onRegionClick={setSelectedRegion}
              />
            </MuiThemeProvider>
          </div>
          <div className={s.Right}>
            {jobsList.size > 0 ? (
              <Fragment>
                <Typography className={s.TableInfo} variant={"h6"}>
                  {
                    "Clicca sull’intestazione della tabella per riordinare i valori"
                  }
                </Typography>
                <Table
                  className={s.Table}
                  classes={{
                    root: classes.tableRoot,
                  }}
                  padding="dense"
                >
                  <TableHead>
                    <TableCell className={s.TableFirstRow1}>
                      <TableSortLabel
                        active={ascendant.get("jobName") !== null}
                        className={s.Number}
                        direction={ascendant.get("jobName") ? "asc" : "desc"}
                        onClick={() =>
                          onSort("jobName", ascendant.get("jobName"))
                        }
                      >
                        <div className={s.TableCellInfo}>
                          <Typography
                            variant={"h6"}
                            className={s.TableCellTitle}
                          >
                            {"Nome professione"}
                          </Typography>
                        </div>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={s.TableFirstRow2}>
                      <TableSortLabel
                        active={ascendant.get("ass_tot") !== null}
                        className={s.Number}
                        direction={ascendant.get("ass_tot") ? "asc" : "desc"}
                        onClick={() =>
                          onSort("ass_tot", ascendant.get("ass_tot"))
                        }
                      >
                        <Tooltip
                          title={
                            <Typography variant="h4" color="inherit">
                              Attivazioni di contratti previsti dalle imprese
                              per l’anno 2019.
                            </Typography>
                          }
                        >
                          <div className={s.TableCellInfo}>
                            <img
                              src={
                                require("assets/icons/jobs/ass_tot.png")
                                  ?.default
                              }
                              width={20}
                              height={20}
                            />
                            <Typography
                              variant={"h6"}
                              className={s.TableCellTitle}
                            >
                              {"Domanda per assunzioni"}
                            </Typography>
                          </div>
                        </Tooltip>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={s.TableFirstRow3}>
                      <TableSortLabel
                        active={ascendant.get("diff") !== null}
                        className={s.Number}
                        direction={ascendant.get("diff") ? "asc" : "desc"}
                        onClick={() => onSort("diff", ascendant.get("diff"))}
                      >
                        <Tooltip
                          title={
                            <Typography variant="h4" color="inherit">
                              È una dichiarazione delle imprese sulla difficoltà
                              nel reperire, nel territorio in cui operano,
                              candidati idonei a ricoprire la figura
                              professionale ricercata. Le difficoltà sono
                              sintetizzate in due grandi motivazioni (ridotto
                              numero di candidati o inadeguatezza dei
                              candidati), cui si aggiunge una modalità
                              &quot;altro&quot;, eventualmente da specificare.
                            </Typography>
                          }
                        >
                          <div className={s.TableCellInfo}>
                            <img
                              src={
                                require("assets/icons/jobs/diff.png")?.default
                              }
                              width={20}
                              height={20}
                            />
                            <Typography
                              variant={"h6"}
                              className={s.TableCellTitle}
                            >
                              {"Difficoltà di reperimento (%)"}
                            </Typography>
                          </div>
                        </Tooltip>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={s.TableFirstRow4}>
                      <TableSortLabel
                        active={ascendant.get("scuola_obbligo") !== null}
                        className={s.Number}
                        direction={
                          ascendant.get("scuola_obbligo") ? "asc" : "desc"
                        }
                        onClick={() =>
                          onSort(
                            "scuola_obbligo",
                            ascendant.get("scuola_obbligo"),
                          )
                        }
                      >
                        <div className={s.TableCellInfo}>
                          <img
                            src={
                              require("assets/icons/jobs/scuola.png")?.default
                            }
                            width={20}
                            height={20}
                          />
                          <Typography
                            variant={"h6"}
                            className={s.TableCellTitle}
                          >
                            {"Titolo di studio (%)"}
                          </Typography>
                        </div>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={s.TableFirstRow5}>
                      <TableSortLabel
                        active={ascendant.get("fino29anni") !== null}
                        className={s.Number}
                        direction={ascendant.get("fino29anni") ? "asc" : "desc"}
                        onClick={() =>
                          onSort("fino29anni", ascendant.get("fino29anni"))
                        }
                      >
                        <div className={s.TableCellInfo}>
                          <img
                            src={require("assets/icons/jobs/age.png")?.default}
                            width={20}
                            height={20}
                          />
                          <Typography
                            variant={"h6"}
                            className={s.TableCellTitle}
                          >
                            {"Età (%)"}
                          </Typography>
                        </div>
                      </TableSortLabel>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_1}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Ridotto numero di candidati"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_2}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Inadeguatezza dei candidati"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_3}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Altro"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_1}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Scuola dell'obbligo"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_3}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Diploma professionale"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_2}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Diploma superiore"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_4}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Laurea"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_1}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Giovani, meno di 29 anni"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_2}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Minimo 30 anni"}
                          </Typography>
                        </div>
                        <div className={s.Legend}>
                          <Spot
                            className={s.Spot}
                            color={chartColors.chart_3}
                            size={10}
                          />
                          <Typography
                            className={s.LegendTypography}
                            variant={"caption"}
                          >
                            {"Età indifferente"}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    {jobsList.map((job, index) => {
                      if (
                        index >= page * LIMIT &&
                        index <= page * LIMIT + LIMIT - 1
                      ) {
                        const diff = 100 - job.get("diff") * 100;
                        const diff_altro =
                          (parseFloat(job.get("diff_altro")) / diff) *
                          100 *
                          100;
                        const inadeg_candidati =
                          (parseFloat(job.get("inadeg_candidati")) / diff) *
                          100 *
                          100;
                        const ridotti_candidati =
                          (parseFloat(job.get("ridotti_candidati")) / diff) *
                          100 *
                          100;
                        return (
                          <TableRow key={index}>
                            <TableCell className={s.LabelCell}>
                              <Link
                                className={s.Link}
                                href={`#/professioni/${jobsParams.get(
                                  "regionId",
                                )}/${job.get("jobId")}/-1`}
                                underline={"none"}
                              >
                                <Typography color="primary" variant={"h4"}>
                                  {jobs[job.get("jobId")]} (
                                  {formatDigit(job.get("jobId"))})
                                </Typography>
                              </Link>
                            </TableCell>
                            {job.get("ass_tot") < 20 ? (
                              <TableCell align="center" colSpan={4}>
                                <Typography variant={"caption"}>
                                  {selectedRegion !== null ? (
                                    <Fragment>
                                      Il dato non é disponibile per questa
                                      categoria professionale in{" "}
                                      {regions[selectedRegion]}. Consulta i
                                      risultati per
                                      <Link
                                        className={s.TableLink}
                                        color={"secondary"}
                                        onClick={() => setSelectedRegion(null)}
                                        underline={"none"}
                                      >
                                        &nbsp;l’Italia
                                      </Link>
                                    </Fragment>
                                  ) : (
                                    "Dati non disponibili per questa professione\n"
                                  )}
                                </Typography>
                              </TableCell>
                            ) : (
                              <Fragment>
                                <TableCell align="center">
                                  <Typography
                                    variant={"h6"}
                                    className={s.Number}
                                  >
                                    {formatNumber(job.get("ass_tot"))}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <div className={sa.DF}>
                                    <Typography
                                      className={s.DiffTypography}
                                      variant={"h6"}
                                    >
                                      {diff.toFixed(0)} %
                                    </Typography>
                                    <div className={s.DiffChart}>
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                          credits: {
                                            enabled: false,
                                          },
                                          chart: {
                                            type: "bar",
                                            height: 50,
                                            margin: [10, 0, 10, 0],
                                          },
                                          title:
                                            "Difficoltà di reperimento (%)",
                                          xAxis: {
                                            categories: [""],
                                            lineColor: "transparent",
                                          },
                                          yAxis: {
                                            min: 0,
                                            max: 100,
                                            visible: false,
                                          },
                                          plotOptions: {
                                            series: {
                                              stacking: "normal",
                                            },
                                          },
                                          legend: {
                                            enabled: false,
                                          },
                                          series: [
                                            {
                                              name: "Altro",
                                              data: [diff_altro],
                                              color: chartColors.chart_3,
                                            },
                                            {
                                              name: "Inad. candidati",
                                              data: [inadeg_candidati],
                                              color: chartColors.chart_2,
                                            },
                                            {
                                              name: "Rid. num. candidati",
                                              data: [ridotti_candidati],
                                              color: chartColors.chart_1,
                                            },
                                          ],
                                          tooltip: {
                                            outside: true,
                                            formatter() {
                                              return `<tspan style="fill:${
                                                this.color
                                              }" x="8" dy="15">●</tspan> ${
                                                this.series.name +
                                                ": " +
                                                ((this.y * diff) / 100).toFixed(
                                                  0,
                                                ) +
                                                "%"
                                              }`;
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      credits: {
                                        enabled: false,
                                      },
                                      chart: {
                                        type: "bar",
                                        height: 50,
                                        margin: [10, 0, 10, 0],
                                      },
                                      title: "Titolo di studio (%)",
                                      xAxis: {
                                        categories: [""],
                                        visible: false,
                                      },
                                      yAxis: {
                                        min: 0,
                                        max: 100,
                                        visible: false,
                                      },
                                      plotOptions: {
                                        series: {
                                          stacking: "normal",
                                        },
                                      },
                                      legend: {
                                        enabled: false,
                                      },
                                      series: [
                                        {
                                          name: "Ed. univers.",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("ed_univers") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_4,
                                        },
                                        {
                                          name: "Dipl. prof.",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("diploma_prof") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_3,
                                        },
                                        {
                                          name: "Dipl. sup.",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("diploma_sup") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_2,
                                        },
                                        {
                                          name: "Scuola obbligo",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("scuola_obbligo") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_1,
                                        },
                                      ],
                                      tooltip: {
                                        outside: true,
                                        style: {
                                          fontSize: 10,
                                        },
                                        formatter() {
                                          return `<tspan style="fill:${
                                            this.color
                                          }" x="8" dy="15">●</tspan> ${
                                            this.series.name +
                                            ": " +
                                            this.y +
                                            "%"
                                          }`;
                                        },
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      credits: {
                                        enabled: false,
                                      },
                                      chart: {
                                        type: "bar",
                                        height: 50,
                                        margin: [10, 0, 10, 0],
                                      },
                                      title: "Età (%)",
                                      xAxis: {
                                        categories: [""],
                                        visible: false,
                                      },
                                      yAxis: {
                                        min: 0,
                                        max: 100,
                                        visible: false,
                                      },
                                      plotOptions: {
                                        series: {
                                          stacking: "normal",
                                        },
                                      },
                                      legend: {
                                        enabled: false,
                                      },
                                      series: [
                                        {
                                          name: "Età indifferente",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("eta_nonrilev") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_3,
                                        },
                                        {
                                          name: "> o = 30",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("da30oltre") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_2,
                                        },
                                        {
                                          name: "Giovani, meno di 29 anni",
                                          data: [
                                            parseFloat(
                                              (
                                                job.get("fino29anni") * 100
                                              ).toFixed(0),
                                            ),
                                          ],
                                          color: chartColors.chart_1,
                                        },
                                      ],
                                      tooltip: {
                                        outside: true,
                                        style: {
                                          fontSize: 10,
                                        },
                                        formatter() {
                                          return `<tspan style="fill:${
                                            this.color
                                          }" x="8" dy="15">●</tspan> ${
                                            this.series.name +
                                            ": " +
                                            this.y +
                                            "%"
                                          }`;
                                        },
                                      },
                                    }}
                                  />
                                </TableCell>
                              </Fragment>
                            )}
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow className={s.AccordionTableRow}>
                      <TablePagination
                        labelDisplayedRows={({ from, to }) =>
                          `${from}-${to} di ${count}`
                        }
                        rowsPerPageOptions={[LIMIT]}
                        count={count}
                        rowsPerPage={LIMIT}
                        page={page}
                        SelectProps={{
                          native: true,
                        }}
                        onChangePage={(ev, page) => setPage(page)}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Fragment>
            ) : !jobsLoading ? (
              <Typography variant={"h3"}>
                Dati non disponibili per questa professione
              </Typography>
            ) : (
              <CircularProgress />
            )}

            <div className={s.AccordionContainer}>
              <div className={s.OrderRow}>
                <Fab
                  color={
                    ascendant.get("jobName") === null ? "default" : "primary"
                  }
                  size="small"
                  className={s.OrderFab}
                  onClick={() => onSort("jobName")}
                >
                  {" "}
                  {ascendant.get("jobName") || ascendant.get("jobName") === null
                    ? "A-Z"
                    : "Z-A"}
                </Fab>
              </div>
              {jobsList.map((job, index) => {
                const diff = 100 - job.get("diff") * 100;
                const diff_altro =
                  (parseFloat(job.get("diff_altro")) / diff) * 100 * 100;
                const inadeg_candidati =
                  (parseFloat(job.get("inadeg_candidati")) / diff) * 100 * 100;
                const ridotti_candidati =
                  (parseFloat(job.get("ridotti_candidati")) / diff) * 100 * 100;

                return (
                  <ExpansionPanel
                    key={job.get("jobGroupId")}
                    className={s.ExpansionPanel}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={
                        index % 2 === 0 ? s.ExpansionPanelSummaryFill : ""
                      }
                    >
                      <Link
                        href={`#/professioni/${jobsParams.get(
                          "regionId",
                        )}/${job.get("jobId")}/-1`}
                        underline={"none"}
                      >
                        {jobs[job.get("jobId")]} (
                        {formatDigit(job.get("jobId"))})
                      </Link>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={s.ExpansionPanelDetails}>
                      {job.get("ass_tot") < 20 ? (
                        <Typography variant={"caption"}>
                          {selectedRegion !== null ? (
                            <Fragment>
                              Il dato non é disponibile per questa categoria
                              professionale in {regions[selectedRegion]}.
                              Consulta i risultati per
                              <Link
                                className={s.TableLink}
                                color={"secondary"}
                                onClick={() => setSelectedRegion(null)}
                                underline={"none"}
                              >
                                &nbsp;l’Italia
                              </Link>
                            </Fragment>
                          ) : (
                            "Dati non disponibili per questa professione\n"
                          )}
                        </Typography>
                      ) : (
                        <Table className={s.AccordionTable}>
                          <TableBody>
                            <TableRow className={s.AccordionTableRow}>
                              <TableCell className={s.AccordionTableCell}>
                                <Tooltip
                                  title={
                                    <Typography variant="h4" color="inherit">
                                      Attivazioni di contratti previsti dalle
                                      imprese per l’anno 2019.
                                    </Typography>
                                  }
                                >
                                  <Typography
                                    variant={"h6"}
                                    className={s.TableCellTitle}
                                  >
                                    Domanda per assunzioni:{" "}
                                    {formatNumber(job.get("ass_tot"))}
                                  </Typography>
                                </Tooltip>
                                <Divider className={s.Divider} />
                                <Typography
                                  variant={"h6"}
                                  className={s.TableCellTitle}
                                >
                                  Difficoltà di reperimento:&nbsp;
                                  {diff.toFixed(0)} %
                                </Typography>
                                <div className={s.LegendContainer}>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Ridotto numero di candidati"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Inadeguatezza dei candidati
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Altro
                                    </Typography>
                                  </div>
                                </div>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                    credits: {
                                      enabled: false,
                                    },
                                    chart: {
                                      type: "bar",
                                      height: 50,
                                    },
                                    title: "Difficoltà di reperimento (%)",
                                    xAxis: {
                                      categories: [""],
                                      visible: false,
                                    },
                                    yAxis: {
                                      min: 0,
                                      max: 100,
                                      visible: false,
                                    },
                                    plotOptions: {
                                      series: {
                                        stacking: "normal",
                                      },
                                    },
                                    legend: {
                                      enabled: false,
                                    },
                                    series: [
                                      {
                                        name: "Altro",
                                        data: [diff_altro],
                                        color: chartColors.chart_3,
                                      },
                                      {
                                        name: "Inad. candidati",
                                        data: [inadeg_candidati],
                                        color: chartColors.chart_2,
                                      },
                                      {
                                        name: "Rid. num. candidati",
                                        data: [ridotti_candidati],
                                        color: chartColors.chart_1,
                                      },
                                    ],
                                    tooltip: {
                                      outside: true,
                                      formatter() {
                                        return `<tspan style="fill:${
                                          this.color
                                        }" x="8" dy="15">●</tspan> ${
                                          this.series.name +
                                          ": " +
                                          ((this.y * diff) / 100).toFixed(0) +
                                          "%"
                                        }`;
                                      },
                                    },
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow className={s.AccordionTableRow}>
                              <TableCell className={s.AccordionTableCell}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TableCellTitle}
                                >
                                  Titolo di studio (%)
                                </Typography>
                                <div className={s.LegendContainer}>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Scuola dell'obbligo"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Diploma professionale
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Diploma superiore
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_4}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Educazione universitaria
                                    </Typography>
                                  </div>
                                </div>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                    credits: {
                                      enabled: false,
                                    },
                                    chart: {
                                      type: "bar",
                                      height: 50,
                                    },
                                    title: "Titolo di studio (%)",
                                    xAxis: {
                                      categories: [""],
                                      visible: false,
                                    },
                                    yAxis: {
                                      min: 0,
                                      max: 100,
                                      visible: false,
                                    },
                                    plotOptions: {
                                      series: {
                                        stacking: "normal",
                                      },
                                    },
                                    legend: {
                                      enabled: false,
                                    },
                                    series: [
                                      {
                                        name: "Ed. univers.",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("ed_univers") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_4,
                                      },
                                      {
                                        name: "Dipl. prof.",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("diploma_prof") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_3,
                                      },
                                      {
                                        name: "Dipl. sup.",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("diploma_sup") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_2,
                                      },
                                      {
                                        name: "Scuola obbligo",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("scuola_obbligo") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_1,
                                      },
                                    ],
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow className={s.AccordionTableRow}>
                              <TableCell className={s.AccordionTableCell}>
                                <Typography
                                  variant={"h6"}
                                  className={s.TableCellTitle}
                                >
                                  Età (%)
                                </Typography>
                                <div className={s.LegendContainer}>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_1}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Giovani, meno di 29 anni"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_2}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      {"Minimo 30 anni"}
                                    </Typography>
                                  </div>
                                  <div className={s.Legend}>
                                    <Spot
                                      className={s.LegendSpot}
                                      color={chartColors.chart_3}
                                      size={10}
                                    />
                                    <Typography
                                      className={s.LegendTypography}
                                      variant={"caption"}
                                    >
                                      Età indifferente
                                    </Typography>
                                  </div>
                                </div>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                    credits: {
                                      enabled: false,
                                    },
                                    chart: {
                                      type: "bar",
                                      height: 50,
                                    },
                                    title: "Età (%)",
                                    xAxis: {
                                      categories: [""],
                                      visible: false,
                                    },
                                    yAxis: {
                                      min: 0,
                                      max: 100,
                                      visible: false,
                                    },
                                    plotOptions: {
                                      series: {
                                        stacking: "normal",
                                      },
                                    },
                                    legend: {
                                      enabled: false,
                                    },
                                    series: [
                                      {
                                        name: "Età indifferente",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("eta_nonrilev") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_3,
                                      },
                                      {
                                        name: "> o = 30",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("da30oltre") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_2,
                                      },
                                      {
                                        name: "Giovani, meno di 29 anni",
                                        data: [
                                          parseFloat(
                                            (
                                              job.get("fino29anni") * 100
                                            ).toFixed(0),
                                          ),
                                        ],
                                        color: chartColors.chart_1,
                                      },
                                    ],
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
              {jobsList.size !== count && (
                <Button
                  className={cn(sa.W100, sa.MT20)}
                  variant="outlined"
                  color="primary"
                  onClick={() => setPage(page + 1)}
                >
                  Carica altri risultati
                </Button>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

JobsGroupsResults.propTypes = {
  ascendant: object,
  classes: object,
  clearList: func,
  count: number,
  getJobsAjax: func,
  groupedSkills: object,
  jobsList: object,
  jobsLoading: bool,
  jobsParams: object,
  page: number,
  restUri: string,
  setAscendant: func,
  setPage: func,
  t: func,
};

const mapStateToProps = (state) => ({
  ascendant: getAscendant(state),
  jobsList: getJobsList(state),
  jobsParams: getJobsParams(state),
  restUri: getRestUri(state),
  page: getPage(state),
  count: getCount(state),
  groupedSkills: getGroupedSkills(state),
  jobsLoading: getAjaxLoading(state).get("GET_JOBS_1"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearList,
      getJobsAjax,
      setAscendant,
      setPage,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(JobsGroupsResults)));
