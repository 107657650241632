// CONSTANTS
// LIBS
// REACT
import React, { Fragment } from "react";
import { func } from "prop-types";
import { translate } from "react-i18next";
import HashRouter, { Route } from "react-redux-hash-router";
import CoursesSearch from "./scenes/courses-search";
import CoursesUniversities from "./scenes/courses-universities";
import CoursesUniversity from "./scenes/courses-university";
import Header from "components/header";
// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// STYLE
import sg from "css/style.module.scss";
import { MuiThemeProvider } from "@material-ui/core";
import { third } from "styles/mui-themes";
import ChoiceBox from "../home/components/choice-box";

function Courses() {
  return (
    <div
      style={{
        backgroundColor: "#ae1c3a",
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23b61f3e' fill-opacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
        minHeight: "100vh",
      }}
    >
      <Header />
      <MuiThemeProvider theme={third}>
        <div className={sg.Wrapper}>
          <HashRouter
            routerStatePath="routing.coursesRouter"
            loaderComponent={<div>Loading...</div>}
          >
            <Route
              hash="#/percorsi-formativi/ricerca/{searchType}/{regionId}/{jobId}/{coursesText}/{codtipolau}/{index}"
              initAccess={true}
              routeKey="coursesSearch"
            >
              <CoursesSearch />
            </Route>
            <Route
              hash="#/percorsi-formativi/atenei/{regionId}/{codtipolau}/{jobId}/{coursesText}/{index}"
              initAccess={true}
              routeKey="coursesUniversities"
            >
              <CoursesUniversities />
            </Route>
            <Route
              hash="#/percorsi-formativi/ateneo/{_id}/{regionId}/{codtipolau}/{jobId}/{coursesText}"
              initAccess={true}
              routeKey="coursesUniversity"
            >
              <CoursesUniversity />
            </Route>
          </HashRouter>
        </div>
      </MuiThemeProvider>
    </div>
  );
}

Courses.propTypes = {
  t: func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(Courses));
