// CONSTANTS
import * as keys from 'constants/translations/keys'

// REACT
import React, { useState } from 'react'
import { func, object, string } from 'prop-types'
import { Trans, translate } from 'react-i18next'
import { Button, createStyles, Paper, Typography, withStyles } from '@material-ui/core'
import Lottie from 'react-lottie'

// LIBS
import cn from 'classnames'

// STYLE
import s from './style.module.scss'

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.background.paper
  },
  buttonText: {
    color: theme.palette.buttonText
  }
})

function ChoiceBox({
  animation,
  backgroundColor,
  backgroundImage,
  classes,
  href,
  subtitle,
  title
 }) {
  const [isStopped, setIsStopped] = useState(true)

  return (
    <Paper className={s.Paper}>
      <div
        className={cn(s.Column)}
        onMouseEnter={() => setIsStopped(false)}
        onMouseLeave={() => setIsStopped(true)}
        style={{ backgroundColor: backgroundColor, backgroundImage: backgroundImage }}
      >
        <div className={s.AnimationContainer}>
          <div className={s.Animation}>
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              isStopped={isStopped}
              height={'100%'}
              width={'100%'}
            />
          </div>
          <div className={s.TextContainer}>
            <Typography
              className={cn(s.Text)}
              variant='h3'
            >{title}</Typography>
          </div>
        </div>
        <Typography
          className={s.Description}
          variant='h4'
        >{subtitle}</Typography>
        <Button
          className={cn(s.Button, classes.button)}
          href={href}
          variant='contained'
        >
          <Typography
            variant='subtitle1'
            classes={{
              root: classes.buttonText
            }}
          >
            Scegli!
          </Typography>
        </Button>
      </div>
    </Paper>
  )
}

ChoiceBox.propTypes = {
  animation: object,
  backgroundColor: string,
  backgroundImage: string,
  classes: object,
  href: string,
  subtitle: string,
  t: func,
  title: string
}

export default withStyles(styles)(translate()(ChoiceBox))
