// REDUX
import { all } from 'redux-saga/effects'
import requestSaga from 'redux/sagas/ajax/request'
import getCourseSaga from 'redux/sagas/courses/getCourse'

export default function * rootSaga () {
  yield all([
    requestSaga(),
    getCourseSaga()
  ])
}
