// CONSTANTS
import SkillsTooltip from "components/skills-tooltip";

const jobsGroups = require("constants/jobs-groups-labels");
const regions = require("constants/regions-labels");
const skillsLabels = require("constants/skills-labels");
const skills = Object.keys(skillsLabels).map((key) => ({
  value: key,
  label: skillsLabels[key],
}));

// REACT
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { func, object, string } from "prop-types";
import { translate } from "react-i18next";
import ItalyMap from "components/italy-map";
import {
  Button,
  FormControl,
  Link,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Select,
  Typography,
  withStyles,
  Fab,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight, Search as SearchIcon } from "@material-ui/icons";
import PageToolbar from "components/page-toolbar";
import Autocomplete from "components/autocomplete";

// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSkillsParams } from "redux/getters/routing";
import { getGroupedSkillsAjax } from "redux/actions/ajax";
import { getRestUri } from "redux/getters";
import { getGroupedSkills } from "redux/getters/skills";

// STYLE
import s from "./style.module.scss";
import sa from "css/atomic.module.scss";
import { common } from "styles/mui-themes";
import SkillsList from "../../../../components/skills-list";
import { formatDigit } from "../../../../services/formatter";

const styles = (theme) => ({
  buttonRoot: {
    display: "block",
    width: "75%",
    "align-items": "center",
    "border-radius": "25px",
    border: "1px solid grey",
    margin: "10px auto",
    "&:hover": {
      "background-color": theme.palette.primary.main,
      opacity: 0.6,
    },
  },
  buttonRootSelected: {
    "background-color": theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      opacity: 1,
    },
  },
  linkRoot: {
    cursor: "pointer",
  },
  regionText: {
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: '"Tofino-Bold", sans-serif',
    fontSize: 14,
  },
});

function SkillsJobGroups(props) {
  const {
    classes,
    getGroupedSkillsAjax,
    groupedSkills,
    restUri,
    skillsParams,
  } = props;
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    const skills = [];
    const query = new URLSearchParams();
    skillsParams.map((s) => {
      query.append("skillsIds", s);
      skills.push(s);
    });
    getGroupedSkillsAjax(restUri, {
      query,
    });
    setSelectedSkills(skills);
  }, [skillsParams]);

  const onChange = useCallback((value) => {
    if (value.length) {
      setSelectedSkills(value);
      let hash = `${value}`;
      hash = hash.replace(/,/g, "/");
      window.location.href = `#/competenze/aree-professionali/${hash}`;
    }
  }, []);

  return (
    <div className={s.Container}>
      <PageToolbar
        title={"Scopri come una competenza si inserisce nel mercato del lavoro"}
        tooltipText={
          "I risultati mostrati in questa pagina provengono dall’Indagine Campionaria sulle Professioni INAPP. Questa descrive in dettaglio le caratteristiche di tutte le professioni in cui si articola il mercato del lavoro italiano, da quelle operanti nelle imprese private a quelle presenti nell’ambito delle istituzioni e delle strutture pubbliche, fino a quelle autonome. Si tratta dell’unica esperienza europea di replica del modello statunitense O*Net (Occupational Information Network) capace di fornire informazioni al massimo livello di dettaglio circa l’intero spettro di caratteristiche che definiscono i contenuti del lavoro rappresentati a livello di singola professione."
        }
      />
      <Paper className={s.Paper}>
        <div className={s.Left}>
          <Typography
            classes={{
              root: classes.title,
            }}
            variant={"subtitle1"}
          >
            Stai visualizzando:
          </Typography>
          {selectedRegion ? (
            <div className={s.Region}>
              <MuiThemeProvider theme={common}>
                <Link
                  color={"secondary"}
                  classes={{
                    root: classes.linkRoot,
                  }}
                  onClick={() => setSelectedRegion(null)}
                  underline={"none"}
                >
                  <Typography color={"inherit"} variant={"h6"}>
                    {"Tutta Italia >"}
                  </Typography>
                </Link>
              </MuiThemeProvider>
              &nbsp;
              <Typography
                classes={{
                  root: classes.regionText,
                }}
              >
                {regions[selectedRegion]}
              </Typography>
            </div>
          ) : (
            <Typography variant={"h6"}>{"Tutta Italia"}</Typography>
          )}
          <MuiThemeProvider theme={common}>
            <ItalyMap
              className={s.Map}
              selected={selectedRegion}
              onRegionClick={setSelectedRegion}
              disabled
            />
          </MuiThemeProvider>
        </div>
        <div className={s.Right}>
          <Typography variant="subtitle1">
            Cerca una o più competenze
          </Typography>
          <div className={s.Row}>
            <Autocomplete
              className={s.Input}
              suggestions={skills}
              //initialValue={selectedSkills ? selectedSkills.map(skill => ({ value: skill, label: skillsLabels[key] })) : []}
              placeholder={"Tecnologia, espressione orale, matematica, ecc..."}
              onChange={onChange}
              label={"Cerca Competenze"}
              multiple
              limit={3}
            />
          </div>
          {selectedSkills.length == 0 && (
            <Typography variant={"h6"}>
              Le competenze indicano l’insieme di conoscenze, di skills e
              attitudini utili all’esercizio di una professione.
              <br />
              Per conoscenze, si intende quell’insieme di fatti e principi
              necessari per affrontare i problemi e le tematiche del proprio
              lavoro, ad esempio, la conoscenza di “Ingegneria e tecnologia”.
              <br />
              Per skills (professionali, trasversali e relazionali) si intende
              l’insieme di procedure e i processi cognitivi che determinano la
              capacità di eseguire bene i compiti di una professione. Sono
              processi appresi con il tempo e che consentono di trasferire
              efficacemente nel lavoro le conoscenze acquisite, per esempio il
              “Pensiero critico”.
              <br />
              Le attitudini sono tratti peculiari dell’individuo relativamente
              stabili nel tempo e scarsamente modificabili, come l’attitudine a
              “Memorizzare”.
            </Typography>
          )}
          {selectedSkills.length > 0 && (
            <div className={sa.MT20}>
              <SkillsList selectedSkills={selectedSkills} />
            </div>
          )}
          {selectedSkills.length > 0 && (
            <Tooltip
              title={
                <Typography variant="h4" color="inherit">
                  Il pannello mostra le 5 classi professionali per le quali le competenze cercate sono più importanti
                </Typography>
              }
            >
              <Typography className={sa.MT30} variant="subtitle1">
                {
                  "Classi professioniali a cui sono richieste le competenze cercate"
                }
              </Typography>
            </Tooltip>
          )}
          <MuiThemeProvider theme={common}>
            <MenuList>
              {groupedSkills.map((skill) => (
                <Link
                  key={skill.get("jobGroupId")}
                  href={`#/professioni/gruppi/${
                    selectedRegion || "999"
                  }/${skill.get("jobGroupId")}`}
                  underline={"none"}
                  color={"secondary"}
                >
                  <MenuItem className={s.MenuItem} divider>
                    <ListItemText>
                      <Typography color={"secondary"} variant={"h6"}>
                        {jobsGroups[skill.get("jobGroupId")]} (
                        {formatDigit(skill.get("jobGroupId"))})
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          </MuiThemeProvider>
        </div>
      </Paper>
    </div>
  );
}

SkillsJobGroups.propTypes = {
  classes: object,
  getGroupedSkillsAjax: func,
  groupedSkills: object,
  restUri: string,
  skillsParams: object,
  t: func,
};

const mapStateToProps = (state) => ({
  restUri: getRestUri(state),
  skillsParams: getSkillsParams(state),
  groupedSkills: getGroupedSkills(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupedSkillsAjax,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(translate()(SkillsJobGroups)));
