// CONSTANTS
import * as keys from 'constants/translations/keys'

// LIBS
import cn from 'classnames'

// REACT
import React, { useState } from 'react'
import { func } from 'prop-types'
import { translate, Trans } from 'react-i18next'

// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, MuiThemeProvider, Link, Paper, Typography } from '@material-ui/core'
import Header from 'components/header'

// STYLE
import s from './style.module.scss'
import { common } from 'styles/mui-themes'
import sg from 'css/style.module.scss'
import unioncamere from 'assets/images/unioncamere.png'
import inapp from 'assets/images/logo_inapp.png'
import alma from 'assets/images/logo_alma_laurea.png'
import oecd from 'assets/images/oecd_logo_english.png'

function InfoUnioncamere(props) {
  return (
    <MuiThemeProvider theme={common}>
      <div className={s.Container}>
        <Header/>
        <div className={cn(sg.Wrapper, s.Content)}>
          <Grid container>
            <Grid item sm={12} md={9}>
              <Paper className={s.Paper}>
                <img className={s.Logo} alt={'InApp'} src={unioncamere}/>
                <Typography variant='h5' className={s.Title}>
                  Unioncamere
                </Typography>
                <Typography className={s.Text} variant='h6'>
                  L’Unioncamere, ente con personalità giuridica di diritto pubblico, cura e rappresenta gli interessi
                  generali delle camere di commercio e degli altri organismi del sistema camerale italiano; promuove,
                  realizza e gestisce, direttamente o per il tramite di proprie aziende speciali, nonché mediante la
                  partecipazione ad organismi anche associativi, ad enti, a consorzi e a società anche a prevalente
                  capitale privato, servizi e attività di interesse delle camere di commercio e delle categorie
                  economiche. L’Unioncamere esercita, altresì, le funzioni eventualmente delegate dal Ministero dello
                  sviluppo economico. Come indicato tra i diversi scopi statutari (art.2) l’Unioncamere realizza e
                  coordina studi, indagini e ricerche e collabora anche ad attività di studio e ricerca condotte da enti
                  ed organismi nazionali, esteri e internazionali, favorendo e curando la gestione strategica delle
                  informazioni del sistema camerale. Nell’assetto organizzativo di Unioncamere è presente l’area
                  Formazione e politiche attive del lavoro i cui principali contenuti riguardano attività e progetti sulle
                  seguenti tematiche: sistemi informativi e servizi per l’orientamento al lavoro ed alle professioni, la
                  formazione, la certificazione delle competenze, servizi e progetti per il raccordo scuola, università e
                  lavoro, terzo settore, creazione nuove imprese ed integrazione migranti.
                  <br/>
                  <br/>
                  FORMAZIONE E POLITICHE ATTIVE DEL LAVORO
                  <br/>
                  <br/>
                  Valorizzare il capitale umano per sostenere lo sviluppo dei sistemi produttivi, facendo da anello di
                  congiunzione tra formazione e impresa, a partire dalle esigenze di professionalità e competenze
                  degli operatori economici. Questa è la missione che le Camere di commercio, con il sostegno di
                  Unioncamere, svolgono sui temi dell’orientamento formativo e professionale, certificazione delle
                  competenze e dell’incontro domanda-offerta di lavoro/placement, un nodo di una vasta rete di
                  attori istituzionali con i quali sono chiamate a cooperare. Grazie all’evoluzione del quadro legislativo,
                  le Camere di commercio oggi hanno istituzionalmente un ruolo importante anche per la realizzazione
                  e diffusione di servizi e strumenti per i Percorsi delle Competenze Trasversali e
                  l’Orientamento (Alternanza Scuola-Lavoro), quali soggetti da coinvolgere per la progettazione,
                  attuazione e valutazione dei percorsi, rafforzato anche dall’istituzione del Registro Nazionale per
                  l&#39;alternanza scuola-lavoro; Il sistema camerale fa parte dei soggetti che si occupano di favorire i
                  percorsi di orientamento per gli studenti attraverso il coinvolgimento in specifiche convenzioni per la
                  progettazione, realizzazione e valutazione delle iniziative e dei percorsi orientativi delle istituzioni
                  scolastiche e universitarie; concorre all’individuazione e validazione degli apprendimenti non formali
                  e informali, con riferimento al sistema nazionale di certificazione delle competenze; realizza
                  iniziative di supporto all’incontro tra domanda e offerta di lavoro, attraverso servizi informativi,
                  anche a carattere previsionale.
                  <br/>
                  <br/>
                  SISTEMA INFORMATIVO EXCELSIOR
                  <br/>
                  <br/>
                  In quest’ultimo ambito, la principale fonte è costituita dal Sistema informativo Excelsior, che viene
                  utilizzato nella Piattaforma Competenze e Lavoro per fornire informazioni sui fabbisogni
                  professionali delle imprese italiane, disaggregate per regione, livello di istruzione ed età.
                  <br/>
                  <br/>
                  Il &quot;Sistema Informativo per l’occupazione e la formazione&quot; Excelsior realizzato a partire dal 1997 da
                  Unioncamere, in collaborazione con Ministero del Lavoro, ANPAL e Unione Europea, ricostruisce il
                  quadro previsionale dei fabbisogni professionali e formativi espressi dalle imprese, fornendo
                  indicazioni utili per supportare le scelte di programmazione della formazione, dell’orientamento e
                  delle politiche del lavoro. L’indagine Excelsior fa parte delle statistiche ufficiali previste dal
                  Programma Statistico Nazionale (PSN). Per approfondimenti sul Sistema Informativo Excelsior è
                  possibile consultare la pagina:
                  <Link href='https://excelsior.unioncamere.net/'>
                    <Typography
                      variant={'h6'}
                      className={s.LinkText}
                    >
                  https://excelsior.unioncamere.net/
                    </Typography>
                  </Link>
                  <br/>
                  Excelsior mette a disposizione dati, approfondimenti e altri strumenti informativi sulla domanda di
                  professioni e competenze delle imprese italiane e sull’individuazione delle principali aree di
                  mismatch tra domanda e offerta di lavoro e le possibili misure di intervento atte a colmarlo, nonché
                  guide, manuali e altri strumenti informativi di sistema a supporto dell’orientamento scolastico,
                  universitario e lavorativo. L’indagine valorizza in modo integrato le informazioni desunte dagli archivi
                  amministrativi camerali su imprese e occupazione e coinvolge annualmente un campione di oltre
                  500.000 imprese di tutti i settori economici e di tutte le dimensioni. L’elevato numero di interviste e
                  la metodologia adottata per l’elaborazione dei dati consentono di ottenere dati previsionali mensili e
                  annuali sui fabbisogni professionali richiesti dalle imprese per tutte le province italiane.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Paper className={s.RightPaper}>
                <img className={s.RightLogo} onClick={() => window.location.href = '#/info-almalaurea'} alt={'AlmaLaurea'} src={alma} />
                <img className={s.RightLogo} onClick={() => window.location.href = '#/info-inapp'} alt={'InApp'} src={inapp} />
                <img className={s.RightLogo} onClick={() => window.location.href = '#/info-oecd'} alt={'OECD'} src={oecd} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

InfoUnioncamere.propTypes = {
  t: func
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)((translate()(InfoUnioncamere)))
