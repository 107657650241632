import auth from './auth'
import courses from './courses'
import global from './global'
import jobs from './jobs'
import routing from './routing'
import skills from './skills'

export default {
  auth,
  courses,
  global,
  jobs,
  routing,
  skills
}
